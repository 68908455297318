import cache from './cache'
import env from '@/config/env'

// TODO why this options are not valid with ApolloClientOptions?
export const apolloClientOptions = {
  connectToDevTools: env !== 'production',
  // LocalStorage token
  // tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: partnerCompanyLink,
  defaultHttpLink: false,

  // Override default cache
  cache: cache,

  // Override the way the Authorization header is set
  // getAuth: () => {
  //   const oidcStore = useOidcStore()
  //   const token = oidcStore.access_token
  //   return token ? `Bearer ${token}` : ''
  // },
}
