// Taken from:
// https://vuelidate-next.netlify.app/advanced_usage.html#i18n-support

import * as validators from '@vuelidate/validators'
// import { i18n } from "@/i18n"
import i18n from '../plugins/i18n'

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators

// Create your i18n message instance. Used for vue-i18n@9
export const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })
// for vue-i18n@8
// const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) } as any)

// wrap each validator.
export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)
export const numeric = withI18nMessage(validators.numeric)
export const url = withI18nMessage(validators.url)

// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true })
export const requiredUnless = withI18nMessage(validators.requiredUnless, { withArguments: true })

export const containsValidator = (str: string) =>
  validators.helpers.withParams(
    { type: 'contains', value: str },
    (value?: string) => !validators.helpers.req(value) || (!!value && value.includes(str)),
  )

export const contains = withI18nMessage(containsValidator, { withArguments: true })
