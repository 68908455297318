export const formatDateSpan = ({ beginning, end, monthNames }: { beginning: Date; end: Date; monthNames: boolean }) => {
  const beginningDay = ('0' + beginning.getDate()).slice(-2)
  const beginningMonth = beginning.getMonth()
  const beginningMonthName = beginning.toLocaleString('default', {
    month: 'short',
  })
  const beginningMonthLabel = monthNames ? ` ${beginningMonthName} ` : `${String(beginningMonth).padStart(2, '0')}.`
  const beginningYear = beginning.getFullYear()
  const endMonthName = end.toLocaleString('default', { month: 'short' })
  const endDay = ('0' + end.getDate()).slice(-2)
  const endMonth = end.getMonth()
  const endYear = end.getFullYear()
  const endMonthLabel = monthNames ? ` ${endMonthName} ` : `${String(endMonth).padStart(2, '0')}.`

  // If beginning day and end day are same
  if (beginningDay == endDay && beginningMonth == endMonth) {
    return `${beginningDay}.${beginningMonthLabel}${beginningYear}`
  }

  // If different days but same month
  if (beginningDay != endDay && beginningMonth == endMonth) {
    return `${beginningDay}. - ${endDay}.${beginningMonthLabel}${beginningYear}`
  }

  // If different day and different month
  if (beginningDay != endDay && beginningMonth != endMonth) {
    if (beginningYear == endYear) {
      return `${beginningDay}.${beginningMonthLabel} - ${endDay}.${endMonthLabel}${endYear}`
    } else {
      return `${beginningDay}.${beginningMonthLabel}${beginningYear} - ${endDay}.${endMonthLabel}${endYear}`
    }
  }
  return ''
}

export const formatDate = ({ date, monthNames }: { date: Date; monthNames: boolean }) => {
  const day = ('0' + date.getDate()).slice(-2)
  const month = date.getMonth()
  const monthName = date.toLocaleString('default', {
    month: 'short',
  })
  const year = date.getFullYear()
  const monthLabel = monthNames ? ` ${monthName} ` : `${String(month).padStart(2, '0')}.`

  return `${day}.${monthLabel}${year}`
}
