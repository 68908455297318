<template>
  <ContentCard>
    <template #header>
      <ReviewsProductTabs />
    </template>
    <template #subheader>
      <ReviewsProductReviewDetailsSubheader
        v-if="surveyResponse"
        :author-name="surveyResponse.reviewerName || undefined"
        :product="product"
        :rejected-reason="surveyResponse.rejectedReason || undefined"
        :state="surveyResponse.aasmState"
        :reminders-count="surveyResponse.reminders.length"
      />
    </template>
    <div v-if="surveyResponse">
      <ReviewsProductReviewDetails
        :product="product"
        :survey-response="surveyResponse"
        :reminder-disabled="surveyResponse.reminderDisabled"
        @send-reminder="openReminderDialog"
      />
      <SendReminderDialog
        ref="modal"
        :author-name="surveyResponse.reviewerName || undefined"
        :product="product"
        :survey-response-id="surveyResponse.id"
        @closed="refetch"
      />
    </div>
  </ContentCard>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { type ProductById, useReviewsProductSurveyResponseDetailsQuery } from '@/gql/reviews'
import ContentCard from '@/ui/ContentCard.vue'
import ReviewsProductTabs from '../ReviewsProductTabs.vue'
import ReviewsProductReviewDetailsSubheader from './ReviewsProductReviewDetailsSubheader.vue'
import ReviewsProductReviewDetails from './ReviewsProductReviewDetails.vue'
import SendReminderDialog from '@/components/ReviewsManagement/Reviews/SendReminderDialog.vue'

defineProps<{
  product: ProductById
}>()

const route = useRoute()
const modal = ref<InstanceType<typeof SendReminderDialog> | null>(null)

const variables = computed(() => ({ id: route.params.surveyResponseId as string }))
const { result, refetch } = useReviewsProductSurveyResponseDetailsQuery(variables, { clientId: 'reviews' })

const surveyResponse = computed(() => result.value?.manage?.surveyResponse)

function openReminderDialog() {
  modal.value?.open()
}
</script>
