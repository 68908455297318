<template>
  <h1>{{ t('ProspectEvents.title') }}</h1>
  <p class="mt-4 text-body-m">{{ t('ProspectEvents.description') }}</p>
  <ContentCard class="mt-5">
    <template #header>
      <TabBar>
        <NavTabBarItem :key="1" :is-selected="period === 'upcoming'" :to="{ name: route.name, params: { selector: 'current' } }">
          {{ t('Events.upcoming') }}
        </NavTabBarItem>
        <NavTabBarItem :key="2" :is-selected="period === 'past'" :to="{ name: route.name, params: { selector: 'archive' } }">
          {{ t('Events.archive') }}
        </NavTabBarItem>
      </TabBar>
      <ProgressBar v-if="props.prospectListsLoading" color="primary" />
    </template>

    <CircleSpinner v-if="loading" class="my-6 text-purple-600" />
    <div v-else>
      <div v-if="events.length">
        <EventItem
          v-for="event in events"
          :key="event.id"
          :event="event"
          :show-chip="false"
          :show-main-button="false"
          :show-view-button="false"
          :show-dropdown="false"
          class="mb-4"
        >
          <template #customButton>
            <RouterLink :to="targetRoute(event.slug as string)" class="btn-primary-purple-m">
              {{ t('EventHeader.displayLeads') }}
            </RouterLink>
          </template>
        </EventItem>
      </div>
      <p v-else class="py-16 px-48 text-center">
        {{ t(`Event.noEvents.${props.period}`) }}
      </p>
    </div>
  </ContentCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { type EventsQuery, type MeQuery, type ProspectListsQuery, EventPeriodEnum, EventTypeEnum, useEventsQuery } from '@/gql/myomr'
import { ApolloError } from '@apollo/client/core'
import { toPlainId } from '@/helpers/globalIdHelper'
import { CircleSpinner } from '@ramp106/omrjs-core-ui'

const { t } = useI18n()
const route = useRoute()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  selector?: string
  period: EventPeriodEnum
  prospectListsLoading: boolean
  prospectListsResult: ProspectListsQuery
  prospectListsError?: ApolloError
  prospectListsRefetch: () => void
}>()

const listEvents = computed(() => props.prospectListsResult?.prospectLists?.nodes.map((node) => node.event) || [])
const uniqueListEvents = computed(() => listEvents.value.filter((event, index) => listEvents.value.indexOf(event) === index))

const eventsQueryEnabled = computed(() => props.period === EventPeriodEnum.Upcoming)
const { result: eventsResult, loading: eventsLoading } = useEventsQuery(
  () => ({
    period: props.period,
    noParent: true,
    type: EventTypeEnum.Event,
  }),
  { enabled: eventsQueryEnabled },
)

const events = computed(() => {
  if (props.period === EventPeriodEnum.Upcoming) {
    return (eventsResult.value?.events?.nodes as NonNullable<NonNullable<EventsQuery['events']['nodes']>[number]>[]) || []
  } else {
    return uniqueListEvents.value
  }
})

const loading = computed(() => props.prospectListsLoading || eventsLoading.value)

const targetRoute = computed(() => (slug: string) => {
  if (props.period === EventPeriodEnum.Upcoming) {
    return { name: 'prospectsCatalog', params: { slug } }
  } else {
    const listGlobalId = props.prospectListsResult?.prospectLists?.nodes.find((list) => list.event.slug === slug)?.id
    const listId = listGlobalId ? toPlainId(listGlobalId) : undefined
    if (listId != undefined) {
      return { name: 'prospectList', params: { slug, listId } }
    } else {
      return { name: 'prospectLists', params: { slug }, query: { modal: 'newProspectList' } }
    }
  }
})
</script>
