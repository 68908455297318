import { $can, $cannot } from '@/services/roles'

const rolesMixin = {
  methods: {
    $can,
    $cannot,
  },
}

export default rolesMixin
