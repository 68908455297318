<template>
  <ConfirmModal
    persist
    :approve="t('EmailInvitationQuota.deleteInvitationsModal.approve')"
    :cancel="t('EmailInvitationQuota.deleteInvitationsModal.cancel')"
    @close-dialog="handleCloseDialog"
  >
    <template #title>
      <h1 class="text-[#FA0000] text-headline-m flex items-center p-6 pb-0">
        <SvgIcon :path="mdiExclamation" :size="24" class="bg-[#FA0000] mr-2 rounded-full text-white" />{{
          t('EmailInvitationQuota.deleteInvitationsModal.title')
        }}
      </h1>
    </template>
    <template #content>
      <p class="px-6 pt-4">{{ t('EmailInvitationQuota.deleteInvitationsModal.description') }}</p>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
import type { InvitationQuota } from '@/gql/myomr'
import { useDeleteIndividualInviteesMutation } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { mdiExclamation } from '@mdi/js'
import { handleMutationResponse } from '@/helpers/handleMutationResponseHelper'

const { t } = useI18n()

const props = defineProps<{
  invitationQuota: InvitationQuota
  inviteesToDelete: string[]
}>()

const emit = defineEmits(['closeDialog'])

const { mutate } = useDeleteIndividualInviteesMutation()

async function handleCloseDialog(shouldDeleteUsers: boolean) {
  if (!shouldDeleteUsers) return emit('closeDialog')

  await mutate({
    invitationQuotaId: props.invitationQuota.id,
    emails: props.inviteesToDelete,
  }).then((result) => {
    handleMutationResponse(t, result?.data?.deleteIndividualInvitees, t('EmailInvitationQuota.deleteInvitationsModal.success').toString())
  })

  emit('closeDialog')
}
</script>
