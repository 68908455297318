<template>
  <div>
    <div class="pt-2 pb-5">
      <h1>{{ t('Speakers.editSpeaker') }}</h1>

      <BackLink class="mt-4" :to="{ name: 'speakerList' }">{{ t('backToOverview') }}</BackLink>
    </div>

    <SpeakerForm :speaker="props.speaker" :is-uploading="loading" :upload-progress="uploadProgress" @submit-form="save">
      <template #navHeader>
        <TabBar>
          <NavTabBarItem :is-selected="route.name === 'editSpeaker'" :to="{ name: 'editSpeaker' }">
            {{ t('Speaker.editSpeaker') }}
          </NavTabBarItem>
          <NavTabBarItem
            :disabled="!getSpeakerConfig['enableEventAssigning']"
            :is-selected="route.name === 'editSpeakerEvents'"
            :to="{ name: 'editSpeakerEvents' }"
          >
            {{ t('Speaker.editSpeakerEvents') }}
          </NavTabBarItem>
        </TabBar>
        <ProgressBar v-if="loading" color="primary" />
      </template>
    </SpeakerForm>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import type { Speaker, SpeakerAttributes } from '@/gql/myomr'
import { useUpdateSpeakerMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import SpeakerForm from './SpeakerForm.vue'
import BackLink from '@/ui/BackLink.vue'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { useMeStore } from '@/stores'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import getSpeakerConfig from './getSpeakerConfig'

const meStore = useMeStore()

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const props = defineProps<{
  speaker: Speaker
}>()

const uploadProgress = ref(0)

const isUpdatable = computed(() => {
  if (!meStore.currentPartnerCompanyId || props.speaker.editorialFlag) return false

  const currentPartnerCompanyId = meStore.currentPartnerCompanyId.toString()
  const speakerPartnerCompany = props.speaker.partnerCompany

  if (speakerPartnerCompany) {
    return speakerPartnerCompany.id.endsWith(currentPartnerCompanyId)
  } else {
    return false
  }
})

const speakerId = computed(() => {
  return toTypeAndId(props.speaker.id).id || ''
})

onMounted(() => {
  if (!isUpdatable.value) router.push({ name: 'viewSpeaker', params: { speakerId: speakerId.value } })
})

const { mutate: updateSpeaker, loading } = useUpdateSpeakerMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        let loadedProgress = Math.round((progress.loaded / progress.total) * 100)
        uploadProgress.value = loadedProgress
      },
    },
  },
})

async function save(speaker: SpeakerAttributes) {
  uploadProgress.value = 1
  updateSpeaker({
    ...speaker,
    name: `${speaker.firstname} ${speaker.lastname}`,
    speakerId: props.speaker.id,
  }).then((result) => {
    uploadProgress.value = 0
    window.scrollTo(0, 0)
    if (!result?.data?.updateSpeaker?.errors.length) {
      showNotification(t('Speakers.notifications.speakerUpdated'), 'success')
      router.push({ name: 'editSpeakerEvents' })
    } else {
      showNotification(t('Speakers.notifications.serverIssue'), 'error')
    }
  })
}
</script>
