import { helpers } from '@vuelidate/validators'

export const validateImageDimensions =
  (minWidth: number, minHeight: number, maxWidth: number, maxHeight: number) => async (value: File) => {
    if (!helpers.req(value)) return true

    const dimensions = await getImageDimensions(value)
    return (
      dimensions.width >= minWidth &&
      dimensions.width <= maxWidth &&
      dimensions.height >= minHeight &&
      dimensions.height <= maxHeight &&
      dimensions.height === dimensions.width
    )
  }

export const validateImageSquare = async (value: File) => {
  if (!helpers.req(value)) return true

  const dimensions = await getImageDimensions(value)
  return dimensions.height === dimensions.width
}

export const validateImageMinSize = (minWidth: number, minHeight: number) => async (value: File) => {
  if (!helpers.req(value)) return true

  const dimensions = await getImageDimensions(value)
  return dimensions.width >= minWidth && dimensions.height >= minHeight
}

export const validateImageMaxSize = (maxWidth: number, maxHeight: number) => async (value: File) => {
  if (!helpers.req(value)) return true

  const dimensions = await getImageDimensions(value)
  return dimensions.width <= maxWidth && dimensions.height <= maxHeight
}

export const validateFileMaxSize = (megaBytes: number) => async (value: File) => {
  if (!helpers.req(value)) return true

  return value.size / 1024 / 1024 <= megaBytes
}

export const validateFileMimeType = (mimeTypes: string[]) => async (value: File) => {
  if (!helpers.req(value)) return true

  return mimeTypes.includes(value.type)
}

async function getImageDimensions(imageFile: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const img = new Image()
    img.src = window.URL.createObjectURL(imageFile)
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
  })
}

export async function isImageSizeNotTooBig(imageFile: File, maxWidth: number, maxHeight: number): Promise<boolean> {
  return getImageDimensions(imageFile).then(({ width, height }) => width <= maxWidth && height <= maxHeight)
}

export async function isImageSizeNotTooSmall(imageFile: File, minWidth: number, minHeight: number): Promise<boolean> {
  return getImageDimensions(imageFile).then(({ width, height }) => width >= minWidth && height >= minHeight)
}

export async function isImageSquare(imageFile: File) {
  return getImageDimensions(imageFile).then(({ width, height }) => width === height)
}
