export enum ReviewsRouteTabs {
  Details = 'details',
  GlobalFeatures = 'global-features',
  Pricing = 'pricing',
  Documents = 'documents',
  Reviews = 'reviews',
}

export enum RouteName {
  ReviewsProducts = 'reviewsProducts',
  ReviewsProductView = 'reviewsProductView',
  ReviewsProductViewDetails = `reviewsProductView${ReviewsRouteTabs.Details}`,
  ReviewsProductViewDocuments = `reviewsProductView${ReviewsRouteTabs.Documents}`,
  ReviewsProductViewGlobalFeatures = `reviewsProductView${ReviewsRouteTabs.GlobalFeatures}`,
  ReviewsProductViewPricing = `reviewsProductView${ReviewsRouteTabs.Pricing}`,
  ReviewsProductViewReviews = `reviewsProductView${ReviewsRouteTabs.Reviews}`,
  ReviewsProductViewReviewDetails = `reviewsProductView${ReviewsRouteTabs.Reviews}Details`,
  ReviewsProductEdit = 'reviewsProductEdit',
  ReviewsProductEditDetails = `reviewsProductEdit${ReviewsRouteTabs.Details}`,
  ReviewsProductEditDocuments = `reviewsProductEdit${ReviewsRouteTabs.Documents}`,
  ReviewsProductEditGlobalFeatures = `reviewsProductEdit${ReviewsRouteTabs.GlobalFeatures}`,
  ReviewsProductEditPricing = `reviewsProductEdit${ReviewsRouteTabs.Pricing}`,
}
