import { computed } from 'vue'
import type { ProspectList } from './types'

export const isListChangeable = function (list: ProspectList | undefined | null) {
  if (list == null) return false
  if (list.submittedAt || !list.event) return false
  return true
}

export const findActiveList = function (lists: ProspectList[], listId: string | undefined | null) {
  return computed(() => lists.find((item) => item.id == listId))
}
