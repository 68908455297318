<template>
  <div class="text-white">
    <RouterLink v-if="props.to && !props.disabled" :to="props.to">
      <TabBarItem :is-checked="props.isSelected" :disabled="props.disabled">
        <slot></slot>
      </TabBarItem>
    </RouterLink>
    <TabBarItem v-else :is-checked="props.isSelected" :disabled="props.disabled">
      <slot></slot>
    </TabBarItem>
  </div>
</template>
<script lang="ts" setup>
import TabBarItem from './TabBarItem.vue'

export interface Props {
  isSelected: boolean
  to: object
  disabled?: boolean
}

const props = defineProps<Props>()
</script>
