<template>
  <div class="py-6">
    <div v-if="productDocuments.length > 0" class="grid grid-cols-4 gap-6">
      <DocumentCard
        v-for="doc in productDocuments"
        :key="doc.url"
        :content-type="doc.contentType"
        :name="doc.name"
        :size="doc.size"
        @click="downloadFile(doc.url)"
      />
    </div>
    <span v-else>{{ t('Reviews.product.view.documents.missing') }}</span>
  </div>
</template>

<script setup lang="ts">
import { DocumentCard } from '@ramp106/omrjs-reviews-ui'
import type { ProductData } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { ALLOWED_LOCALES, mapTranslationsArrToObj } from '@/helpers/translationsHelper'

const props = defineProps<{
  product: ProductData
}>()

const { t, locale } = useI18n()

const productDocuments = computed(() =>
  props.product.documents.map((doc) => {
    const nameTranslationsMap = mapTranslationsArrToObj(doc.nameTranslations)
    const name = nameTranslationsMap[locale.value] || nameTranslationsMap[ALLOWED_LOCALES[0]]
    return { ...doc, name }
  }),
)

function downloadFile(url: string) {
  window.open(url, '_blank')
}
</script>
