<template>
  <div class="pb-6">
    <div class="my-2 flex items-stretch">
      <div class="flex items-center" :class="{ 'text-grey-500': !isAvailable(value) }">
        <MdiSvg class="mr-2" :path="icon" />
        <span class="text-body-s-bold">{{ name }}</span>
      </div>
      <ProductFeatureStatusIcon class="!cursor-default" :status="value" />
    </div>
    <slot />
  </div>
</template>
<script setup lang="ts">
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { ProductFeatureStatusIcon, type ProductGlobalFeaturesTypes } from '@ramp106/omrjs-reviews-ui'

defineProps<{
  name: string
  icon: string
  value: ProductGlobalFeaturesTypes.FeatureStatus
}>()

function isAvailable(status: ProductGlobalFeaturesTypes.FeatureStatus) {
  return status === 'yes' || status === 'yes_conditional'
}
</script>
