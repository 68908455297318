import { ApolloClient } from '@apollo/client/core'
import localeLink from './localeLink'
import partnerCompanyLink from './partnerCompanyLink'
import myomrHttpLink from './myomrHttpLink'
import myomrErrorLink from './myomrErrorLink'
import retryLink from './retryLink'
import { apolloClientOptions } from './apolloClientOptions'

export const myomrApolloClient = new ApolloClient({
  ...apolloClientOptions,
  link: localeLink.concat(partnerCompanyLink).concat(retryLink).concat(myomrErrorLink).concat(myomrHttpLink),
  devtools: {
    enabled: process.env.NODE_ENV === 'development',
  },
})
