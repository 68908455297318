<template>
  <div>
    <RouterView
      v-if="result?.booths && $can('viewBooth', { for: 'User' })"
      :booths="result.booths.nodes"
      :page-info="result.booths.pageInfo"
      :booths-loading="loading"
      @fetch-more="loadMore"
    />
    <AlertBox v-else type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { EventPeriodEnum, useBoothsQuery } from '@/gql/myomr'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $can } from '@/services/roles'

useRoleRedirect('viewBooth')

const route = useRoute()
const { t } = useI18n()

const eventPeriod = computed<EventPeriodEnum>(() => (route.name == 'boothsArchive' ? EventPeriodEnum.Past : EventPeriodEnum.Upcoming))

const { result, loading, fetchMore } = useBoothsQuery(() => ({ eventPeriod: eventPeriod.value }))

function loadMore() {
  fetchMore({
    variables: {
      cursor: result.value?.booths?.pageInfo.endCursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        return previousResult
      }

      return {
        booths: {
          __typename: previousResult.booths.__typename,
          nodes: [...previousResult.booths.nodes, ...fetchMoreResult.booths.nodes],
          pageInfo: fetchMoreResult.booths.pageInfo,
        },
      }
    },
  })
}
</script>
