<template>
  <div>
    <div v-if="!hasGlobalFeatures" class="flex text-grey-800 mt-6">
      <MdiSvg class="mr-1" :path="mdiInformationOutline" />
      <div class="text-body-m">{{ t('Reviews.product.view.globalFeaturesHint') }}</div>
    </div>
    <ProductGlobalFeaturesProvider
      class="grid grid-cols-3 gap-6 my-6"
      :product="{
        cloud: product.cloud,
        anytimeSupport: product.anytimeSupport,
        onPremise: product.onPremise,
        germanSupport: product.germanSupport,
        germanLocation: product.germanLocation,
        euLocation: product.euLocation,
        euStandardContract: product.euStandardContract,
        euCompanyAffiliated: product.euCompanyAffiliated,
        freePlanAvailable: product.freePlanAvailable,
        freeTrial: product.freeTrial,
      }"
    >
      <template #default="{ dataPrivacy, setupAndSupport, versions }">
        <ProductFeaturesGroup
          class="pb-6"
          :items="setupAndSupport.items"
          :title="setupAndSupport.title"
          title-classes="text-headline-xs pb-2 border-b border-b-solid border-grey-300"
        />
        <ProductFeaturesGroup
          class="pb-6"
          :items="dataPrivacy.items"
          :title="dataPrivacy.title"
          title-classes="text-headline-xs pb-2 border-b border-b-solid border-grey-300"
        />
        <ProductFeaturesGroup
          :items="versions.items"
          :title="versions.title"
          title-classes="text-headline-xs pb-2 border-b border-b-solid border-grey-300"
        />
      </template>
    </ProductGlobalFeaturesProvider>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { MdiSvg } from '@ramp106/omrjs-core-ui'
import { ProductGlobalFeaturesProvider, ProductFeaturesGroup } from '@ramp106/omrjs-reviews-ui'
import { mdiInformationOutline } from '@mdi/js'
import type { ProductById } from '@/gql/reviews'

const props = defineProps<{
  product: ProductById
}>()

const { t } = useI18n()

const hasGlobalFeatures = computed(() => {
  return (
    props.product.cloud !== null ||
    props.product.onPremise !== null ||
    props.product.anytimeSupport !== null ||
    props.product.germanSupport !== null ||
    props.product.germanLocation !== null ||
    props.product.euLocation !== null ||
    props.product.euCompanyAffiliated !== null ||
    props.product.euStandardContract !== null
  )
})
</script>
