import type { MeQuery } from '@/gql/myomr'
import { useMeStore } from '@/stores'

type Pendolytics = {
  initialize: (options: {
    visitor: {
      id: string
      email: string
      role: string
    }
    account: {
      id: string
    }
  }) => void
}
interface PendoWindow extends Window {
  pendo: Pendolytics
}
declare let window: PendoWindow

export default function initializePendo(data: MeQuery['me'] | null) {
  if (!data) return

  const visitor = {
    id: data.id,
    email: data.email,
    role: data.roles.map((role) => role.name).join(),
  }

  const meStore = useMeStore()
  const account = { id: meStore.currentPartnerCompanyGlobalId as string }
  window.pendo.initialize({ visitor, account })
}
