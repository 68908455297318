<template>
  <div>
    <AlertBox v-if="!canManageReviews" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <RouterView
      v-if="canManageReviews"
      :products="result?.manage?.products || []"
      :me="props.me"
      :loading="loading"
      @reload-products="refetch"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { type MeQuery } from '@/gql/myomr'
import { $can } from '@/services/roles'
import { useRoleRedirect } from '@/services/roleRedirect'
import { useReviewsProductsQuery } from '@/gql/reviews'

useRoleRedirect('manageReviews')

const { t } = useI18n()

const canManageReviews = computed(() => {
  return $can('manageReviews', { for: 'User' })
})

const props = defineProps<{
  me: MeQuery['me']
}>()

const { loading, refetch, result } = useReviewsProductsQuery({ clientId: 'reviews' })
</script>
