<template>
  <div class="my-6 flex flex-row justify-between items-center min-h-10">
    <h2 class="text-headline-m">{{ t('Reviews.product.view.documentsTitle', { productName: product.title }) }}</h2>
    <RouterLink
      class="btn-primary-purple-m btn-disabled text-white"
      :to="{ name: RouteName.ReviewsProductEditDocuments, params: { productId: product.id } }"
    >
      {{ t('Reviews.product.action.edit') }}
    </RouterLink>
  </div>
</template>
<script setup lang="ts">
import type { ProductData } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import { RouteName } from '@/router/types'

defineProps<{
  product: Pick<ProductData, 'id' | 'title'>
}>()

const { t } = useI18n()
</script>
