<template>
  <TooltipWrapper>
    <template #trigger>
      <ReviewRemainderStatusIcon />
    </template>
    <template #content>
      <div class="max-w-xs">{{ t('Reviews.product.review.reminderStatusHint', { count: remindersCount }) }}</div>
    </template>
  </TooltipWrapper>
</template>
<script setup lang="ts">
import { TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { useI18n } from 'vue-i18n'
import ReviewRemainderStatusIcon from './ReviewRemainderStatusIcon.vue'

defineProps<{
  remindersCount: number
}>()
const { t } = useI18n()
</script>
