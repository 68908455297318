<template>
  <div>
    <AlertBox v-if="!canAccessPage" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <template v-else>
      <h1 class="pb-7">{{ t('Events.headline') }}</h1>
      <ContentCard class="mt-5">
        <template #header>
          <TabBar>
            <NavTabBarItem :key="1" :is-selected="period === 'upcoming'" :to="{ name: route.name, params: { selector: '' } }">
              {{ t('Events.upcoming') }}
            </NavTabBarItem>
            <NavTabBarItem :key="2" :is-selected="period === 'past'" :to="{ name: route.name, params: { selector: 'archive' } }">
              {{ t('Events.archive') }}
            </NavTabBarItem>
          </TabBar>
          <ProgressBar v-if="loading" color="primary" />
        </template>

        <div v-if="!loading">
          <EventsContainer v-if="events?.nodes?.length" :events="events" :loading="loading" @fetch-more="fetchMoreEvents" />
          <p v-else class="py-16 px-48 text-center">
            {{ t(`Event.noEvents.${period}`) }}
          </p>
        </div>
      </ContentCard>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { EventsContainer } from '@/components'
import { useEventsQuery, type MeQuery, EventPeriodEnum } from '@/gql/myomr'
// Components
import { TabBar, NavTabBarItem } from '@/ui/Tabs'
import { useI18n } from 'vue-i18n'
import { $can } from '@/services/roles'
import { useRoleRedirect } from '@/services/roleRedirect'

const { t } = useI18n()

const route = useRoute()

useRoleRedirect(['viewEvents', 'viewTickets'])

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  selector?: string
}>()

const canAccessPage = computed(() => {
  if (route.name === 'tickets') return $can('viewTickets', { for: 'User' })
  if (route.name === 'events') return $can('viewEvents', { for: 'User' }) && !!props.me?.partnerCompany?.hasChildEvents
  return false
})

const period = computed(() => (props.selector === 'archive' ? EventPeriodEnum.Past : EventPeriodEnum.Upcoming))
const { result, fetchMore, loading } = useEventsQuery(() => {
  return { period: period.value, noParent: route.name === 'tickets' }
})

const events = computed(() => result.value?.events)

function fetchMoreEvents() {
  const cursor = events.value?.pageInfo.endCursor || ''
  fetchMore({
    variables: { cursor },
    updateQuery: (previousQueryResult, { fetchMoreResult }) => {
      const previousNodes = previousQueryResult.events.nodes || []
      const newNodes = fetchMoreResult?.events.nodes || []
      return {
        events: {
          __typename: previousQueryResult.events.__typename,
          nodes: [...previousNodes, ...newNodes],
          pageInfo: fetchMoreResult?.events.pageInfo || previousQueryResult.events.pageInfo,
        },
      }
    },
  })
}
</script>
