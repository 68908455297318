import { ApplicationPolicy } from '@/policies/ApplicationPolicy'

export abstract class UserPolicy extends ApplicationPolicy {
  public static manageUsers() {
    return this.hasUserManagementAccess()
  }

  public static viewSpeakers() {
    return this.hasSpeakersAccess()
  }

  public static viewBooth() {
    return this.hasBoothsAccess()
  }

  public static viewEvents() {
    return this.hasEventsAccess()
  }

  public static viewTickets() {
    return this.hasTicketsAccess()
  }

  public static viewCompany() {
    return this.hasCompanyProfileAccess()
  }

  public static viewDashboard() {
    return this.hasDashboardAccess()
  }

  public static viewProspectCatalog() {
    return this.hasLeadGenerationRole()
  }

  public static viewLeads() {
    return this.hasMyLeadsAccess()
  }
}

export enum UserAbilities {
  manageUsers = 'manageUsers',
}
