import { ApolloClient } from '@apollo/client/core'
import hygraphHttpLink from './hygraphHttpLink'
import genericErrorLink from './genericErrorLink'
import { apolloClientOptions } from './apolloClientOptions'

export const hygraphApolloClient = new ApolloClient({
  ...apolloClientOptions,
  link: genericErrorLink.concat(hygraphHttpLink),
  devtools: {
    enabled: process.env.NODE_ENV === 'development',
  },
})
