<template>
  <SideBarSubMenu :label="t('SideBar.reviews.root')" :to="{ name: 'reviewsProducts' }">
    <SideBarSubMenuItem :to="{ name: 'reviewsProducts' }" data-testid="product-reviews-overview">
      {{ t('SideBar.reviews.toolOverview') }}
    </SideBarSubMenuItem>
    <SideBarSubMenuItem
      v-for="productRoute in productRoutes"
      :key="productRoute.label"
      :to="{ name: productRoute.routeName, params: productRoute.routeParams }"
      :data-testid="'product-reviews-' + productRoute.routeParams.productId"
    >
      {{ productRoute.label }}
    </SideBarSubMenuItem>
    <SideBarSubMenuItem :to="buyerIntentDataHref">
      <span class="pr-1">{{ t('SideBar.reviews.buyerIntentData') }}</span>
      <SvgIcon :path="mdiArrowTopRight" :size="16" />
    </SideBarSubMenuItem>
  </SideBarSubMenu>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFetchSalesViewerTokenMutation, useReviewsProductsQuery } from '@/gql/reviews'
import { RouteName } from '@/router/types'
import { mdiArrowTopRight } from '@mdi/js'

const { t } = useI18n()

const buyerIntentDataHref = computed(() =>
  salesViewerToken.value ? `https://omrviewer.com/?loginToken=${salesViewerToken.value}` : 'https://omrviewer.com/login',
)

const { result } = useReviewsProductsQuery({ clientId: 'reviews' })
const { mutate: fetchSalesViewerToken, onDone } = useFetchSalesViewerTokenMutation({ clientId: 'reviews' })

let salesViewerToken = ref<string | null>(null)

const productRoutes = computed(() => {
  if (result.value?.manage?.products) {
    return result.value.manage.products.map((product) => ({
      label: product.title,
      routeName: RouteName.ReviewsProductView,
      routeParams: { productId: product.id },
    }))
  }

  return []
})

watch(
  () => result.value,
  (newValue) => {
    const products = newValue?.manage?.products?.filter((product) => product.dealPlanType) || []
    if (products?.length) {
      fetchSalesViewerToken({ id: products[0].id })
    }
  },
)

onDone(({ data }) => {
  salesViewerToken.value = data?.manage?.fetchSalesViewerToken?.token || null
})
</script>
