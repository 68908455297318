<template>
  <div class="py-6">
    <ReviewsTable :loading="loading" :product-id="product.id" :survey-responses="result?.manage?.surveyResponses?.results || []" />
    <PaginationControl
      :page="pagination.page"
      :per-page="pagination.perPage"
      :total="pagination.total"
      show-range
      show-total
      show-pagination="always"
      @update:page="updatePage"
      @update:per-page="updatePerPage"
    />
  </div>
</template>

<script setup lang="ts">
import { type ProductData, useReviewsProductSurveyResponsesQuery } from '@/gql/reviews'
import PaginationControl from '../../PaginationControl.vue'
import ReviewsTable from './ReviewsTable.vue'
import { computed } from 'vue'
import { useRouterQueryPagination } from '@/composables/useRouterQueryPagination'

const props = defineProps<{
  product: ProductData
}>()

const { page, perPage, updatePage, updatePerPage } = useRouterQueryPagination()

const variables = computed(() => ({ slug: props.product.slug, page: page.value, perPage: perPage.value }))

const { loading, result } = useReviewsProductSurveyResponsesQuery(variables, { clientId: 'reviews' })

const pagination = computed(() => ({
  page: result.value?.manage?.surveyResponses?.pagination.currentPage ?? page.value,
  perPage: result.value?.manage?.surveyResponses?.pagination.perPage ?? perPage.value,
  total: result.value?.manage?.surveyResponses?.pagination.totalCount ?? result.value?.manage?.surveyResponses?.results.length ?? 0,
}))
</script>
