export const availableLocales = ['en', 'de'] as const
type AvailableLocale = (typeof availableLocales)[number]
export const defaultLocale = availableLocales[0]

// Taken from: https://fettblog.eu/typescript-array-includes/
function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T)
}

export const getValidLocale = (locale: string | null | undefined): AvailableLocale => {
  return includes(availableLocales, locale) ? (locale as AvailableLocale) : defaultLocale
}

export const getBrowserLocale = () => {
  if (navigator) {
    const locale = navigator.language.split('-')[0]
    return getValidLocale(locale)
  } else {
    return defaultLocale
  }
}

export const getLocalStorageLocale = () => {
  if (window && window.localStorage) {
    return getValidLocale(localStorage.getItem('locale'))
  }
}

export const getLocale = (() => getLocalStorageLocale() || getBrowserLocale()) as () => AvailableLocale
