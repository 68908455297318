<template>
  <label
    class="grow-0 text-xs font-bold border-0 px-8 py-[18px] uppercase flex items-stretch"
    :class="{
      'border-b-4 border-purple-600 hover:border-purple-700': isChecked && !props.disabled,
      'border-b-4 border-grey-200': isChecked && props.disabled,
      'hover:bg-grey-100 hover:border-b-4 hover:border-grey-100': !isChecked && !disabled,
      'text-grey-500 ': props.disabled,
      'text-black cursor-pointer': !props.disabled,
    }"
  >
    <input
      :disabled="props.disabled"
      class="hidden"
      type="radio"
      :checked="isChecked"
      :value="props.selectedValue"
      @change="handleRadioChange"
    />

    <slot></slot>
  </label>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

export interface Props {
  isChecked?: boolean
  modelValue?: string
  selectedValue?: string
  disabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isChecked: false,
  modelValue: '',
  selectedValue: undefined,
  disabled: false,
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const isChecked = computed(() => props.isChecked || props.modelValue === props.selectedValue)

function handleRadioChange(event: Event) {
  emit('update:modelValue', (event.target as HTMLInputElement).value)
}
</script>
