<template>
  <div class="py-6">
    <LabelValue :label="t('Reviews.product.view.pricingOptions.label').toString()">
      <div class="text-body-m-bold pb-2">{{ t('Reviews.product.view.versions.label') }}</div>
      <ChipBadge v-if="product.freeDemo" class="mr-4" rounded :prepend-icon="iconDot" color="success">
        {{ t('Reviews.product.view.versions.freeDemo') }}
      </ChipBadge>
      <ChipBadge v-else class="mr-4" rounded :prepend-icon="iconDotOutline">
        {{ t('Reviews.product.view.versions.freeDemo') }}
      </ChipBadge>
      <ChipBadge v-if="product.freeTrial" rounded :prepend-icon="iconDot" color="success">
        {{ t('Reviews.product.view.versions.freeTrial') }} ({{ trialPeriod }})
      </ChipBadge>
      <ChipBadge v-else rounded :prepend-icon="iconDotOutline">
        {{ t('Reviews.product.view.versions.freeTrial') }}
      </ChipBadge>

      <div class="text-body-m-bold mt-4 pb-1">
        {{ t('Reviews.product.view.disclaimer.label') }}
      </div>
      <BtnLink v-if="product.showDisclaimer && product.disclaimerUrl" class="text-body-s-bold underline" :to="product.disclaimerUrl">
        {{ product.disclaimerText }}
      </BtnLink>
      <div v-else>{{ t('Reviews.product.view.disclaimer.missing') }}</div>
    </LabelValue>

    <LabelValue v-if="product.noPricingPlans" :label="t('Reviews.product.view.noPricing.label').toString()">
      <div class="text-body-m-bold pb-1">{{ t('Reviews.product.view.disclaimer.label') }}</div>
      <div>{{ product.noPricingPlansHint }}</div>
    </LabelValue>

    <LabelValue v-else :label="t('Reviews.product.view.pricingPlans.label').toString()">
      <PricingPlanList v-if="product.pricingPlans.length" :pricing-plans="product.pricingPlans" />
      <div v-else class="flex flex-col items-center">
        <NoPricingIcon class="mb-4" />
        <div class="text-headline-xs text-grey-800 mb-1">{{ t('Reviews.product.view.pricingPlans.noPricingPlans') }}</div>
        <div class="text-label-m text-grey-800 mb-4">
          {{ t('Reviews.product.view.pricingPlans.noPricingPlansInfo') }}
        </div>
        <RouterLink
          class="btn-secondary-black-m text-black"
          :to="{ name: RouteName.ReviewsProductEditPricing, params: { productId: product.id } }"
        >
          {{ t('Reviews.product.view.pricingPlans.addPricing') }}
        </RouterLink>
      </div>
    </LabelValue>
  </div>
</template>
<script setup lang="ts">
import { BtnLink, ChipBadge, iconDot, iconDotOutline } from '@ramp106/omrjs-core-ui'

import type { ProductById } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import LabelValue from '@/ui/LabelValue.vue'
import PricingPlanList from './PricingPlanList.vue'
import NoPricingIcon from './NoPricingIcon.vue'
import { RouteName } from '@/router/types'
import { computed } from 'vue'

const props = defineProps<{
  product: ProductById
}>()

const { t } = useI18n()

const trialPeriod = computed(() => {
  const timeUnit = t(`Reviews.product.timeUnit.${props.product.trialUnit}`, props.product.trialPeriod ?? 2)
  return `${props.product.trialPeriod} ${timeUnit}`
})
</script>
