<template>
  <div class="min-h-[50px] border rounded border-grey-700 p-2 flex items-center">
    <div class="flex flex-wrap items-center gap-2">
      <SvgIcon :path="mdiMagnify" :size="24" class="grow-0 text-grey-700" />
      <span
        v-for="name in searchTerms"
        :key="name"
        class="grow-0 rounded-full bg-grey-700 text-white text-label-s py-1 pl-2 pr-1 flex items-center gap-1"
      >
        {{ name }}
        <SvgIcon :path="mdiCloseCircle" :size="24" class="text-grey-300 cursor-pointer" @click.stop="removeTerm(name)" />
      </span>
      <input
        v-model="searchTerm"
        type="text"
        class="grow outline-none"
        :placeholder="searchTerms.length ? undefined : placeholder"
        data-test-id="invitee-search-field"
        @keyup="keyUp"
        @keydown.backspace="keyDownPressed"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { mdiMagnify, mdiCloseCircle } from '@mdi/js'

const props = defineProps<{
  modelValue?: string[]
  placeholder: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

function keyUp(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    addTerm()
  }
  if (event.key === 'Backspace') {
    keyDownReleased()
  }
}

const deletingTermLetters = ref(false)
const searchTerms = ref<string[]>(props.modelValue || [])
const searchTerm = ref('')

const addTerm = () => {
  const term = searchTerm.value.trim()

  if (!term) return

  const names = searchTerms.value.slice()
  names.push(term)

  emit('update:modelValue', [...new Set(names)])

  searchTerm.value = ''
}

watch(
  () => props.modelValue,
  (newValue) => {
    searchTerms.value = newValue || []
  },
)

const removeTerm = (name: string) => {
  const terms = searchTerms.value.filter((existingName) => existingName !== name)
  emit('update:modelValue', terms)
}

const keyDownPressed = () => {
  // Check length of string here, because the last letter (if present) is not
  // deleted yet.
  if (searchTerm.value.length) deletingTermLetters.value = true
}

const keyDownReleased = () => {
  if (deletingTermLetters.value) {
    deletingTermLetters.value = false
    return
  }

  deletingTermLetters.value = false

  const lastTerm = searchTerms.value[searchTerms.value.length - 1]

  if (lastTerm) removeTerm(lastTerm)
}
</script>
