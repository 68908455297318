<template>
  <div>
    <div v-if="me && result" class="dashboard">
      <AlertBox v-if="!isValidUser && pageLoaded" type="warning" class="mt-5">
        {{ t('PartnerCompany.noPartnerCompany') }}
      </AlertBox>
      <template v-else>
        <h1>Dashboard</h1>

        <!-- DueDates section -->
        <DueDates />

        <!-- News section -->
        <NewsList v-if="result.news?.nodes?.length" :news="result.news.nodes" />

        <!-- Forced news popup -->
        <NewsModal
          v-if="showForcedNews && forcedNews.length"
          :news-item="forcedNews[currentForcedNewsItem]"
          @closed-modal="displayNextNewsItem"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick } from 'vue'
import { computed, ref, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { DueDates, NewsList } from '@/components'
import { NewsModal } from '@/components/Dashboard'
import { useNewsQuery, type MeQuery } from '@/gql/myomr'
import { $can } from '@/services/roles'
import { useRoleRedirect } from '@/services/roleRedirect'

const { t } = useI18n()

useRoleRedirect('viewDashboard')

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
}>()

const pageLoaded = ref(false)

const { result, refetch } = useNewsQuery({ enabled: !!props.me })

const isValidUser = computed(() => $can('viewDashboard', { for: 'User' }))

const showForcedNews = ref(false)
const currentForcedNewsItem = ref(0)

const forcedNews = computed(() => result.value?.news.nodes.filter((newsItem) => newsItem.force) || [])

onMounted(async () => {
  if (!window.localStorage.getItem('showForcedNews') || window.localStorage.getItem('showForcedNews') !== 'false') {
    showForcedNews.value = true
  }

  await nextTick()

  pageLoaded.value = true
})

function displayNextNewsItem() {
  if (currentForcedNewsItem.value + 1 >= forcedNews.value.length) {
    window.localStorage.setItem('showForcedNews', 'false')
    showForcedNews.value = false
    return
  }
  currentForcedNewsItem.value += 1
}

watch(
  () => props.me.newsTags,
  () => refetch(),
  { deep: true },
)
</script>
