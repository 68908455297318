<template>
  <RouterView
    v-if="!loading && result?.ticket"
    :current-active-tab="currentActiveTab"
    :invitation-quota="props.invitationQuota"
    :is-limit-reached="isLimitReached"
    :loading="loading"
    :ticket="result.ticket"
  />
</template>

<script lang="ts" setup>
import { useTicketQuery, type InvitationQuotaQuery } from '@/gql/myomr'
import { useRoute } from 'vue-router'
import { getCurrentActiveTab } from './activeTab'

const route = useRoute()
const currentActiveTab = getCurrentActiveTab(route)

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  isLimitReached: boolean
}>()

const { result, loading } = useTicketQuery({ identifier: route.params.ticketId as string }, { fetchPolicy: 'no-cache' })
</script>
