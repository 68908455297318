import type { ReviewsProductScreenshotEditData } from '../types'

export const splitScreenshotsForMutations = (screenshots: ReviewsProductScreenshotEditData[]) => {
  const screenshotsToCreate = screenshots
    .filter((screenshot) => screenshot.id === null && !screenshot.deleted)
    .map((screenshot) => ({
      position: screenshot.position,
      file: screenshot.file,
    }))

  const screenshotsToUpdate = screenshots
    .filter((screenshot) => screenshot.id !== null && screenshot.updated && !screenshot.deleted)
    .map((screenshot) => ({
      id: screenshot.id!,
      position: screenshot.position,
    }))

  const screenshotsToDelete = screenshots
    .filter((screenshot) => screenshot.id !== null && screenshot.deleted)
    .map((screenshot) => screenshot.id!)

  return {
    screenshotsToCreate,
    screenshotsToUpdate,
    screenshotsToDelete,
  }
}
