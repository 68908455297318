<template>
  <div>
    <div>
      <FileUpload
        v-model="partnerCompanyFormData.logo"
        :icon="mdiPaperclip"
        :label="t('PartnerCompany.logo')"
        :hint="t('PartnerCompany.logoHint')"
        :accept="logoAcceptedFormats"
      />
      <img v-if="partnerCompany.logoUrl" class="w-[400px]" :src="partnerCompany.logoUrl" />
      <FileUpload
        v-model="partnerCompanyFormData.webLogo"
        :icon="mdiPaperclip"
        :label="t('PartnerCompany.webLogo') + '*'"
        :hint="
          v$.webLogo.$error ? v$.webLogo.$errors.map((error) => t(error.$message.toString())).join(' ') : t('PartnerCompany.webLogoHint')
        "
        :accept="webLogoAcceptedFormats"
        :error="v$.webLogo.$error"
        class="mt-4 mb-2"
        @update:model-value="v$.webLogo.$validate"
      />
      <img v-if="partnerCompany.webLogoUrl" class="w-[400px]" :src="partnerCompany.webLogoUrl" />
    </div>
    <div>
      <TextInput
        id="displayName"
        v-model="partnerCompanyFormData.displayName"
        :label="t('PartnerCompany.name') + '*'"
        :hint="t('PartnerCompany.nameHint')"
        class="my-4"
        :error="getValidationErrors(v$.displayName)"
      />
      <TextInput
        id="website"
        v-model="partnerCompanyFormData.website"
        :label="t('PartnerCompany.website')"
        :hint="t('PartnerCompany.linkHint')"
        class="my-4"
        :error="getValidationErrors(v$.website)"
        :append-icon="mdiWeb"
      />
      <TextInput
        id="descriptionDe"
        v-model="partnerCompanyFormData.descriptionDe"
        type="textarea"
        :label="t('PartnerCompany.descriptionDe') + '*'"
        :hint="t('PartnerCompany.descriptionDeHint')"
        :max-length="750"
        :error="getValidationErrors(v$.descriptionDe)"
      />
      <TextInput
        id="descriptionEn"
        v-model="partnerCompanyFormData.descriptionEn"
        type="textarea"
        :label="t('PartnerCompany.descriptionEn') + '*'"
        :hint="t('PartnerCompany.descriptionEnHint')"
        :max-length="750"
        class="mt-8 mb-5"
        :error="getValidationErrors(v$.descriptionEn)"
      />
    </div>

    <div v-if="partnerCompany.hasFoodBooths" class="mt-8 mb-8">
      <h4>{{ t('PartnerCompany.foodTypes') }}</h4>
      <div v-for="booth in booths" :key="booth.id">
        <h5 class="mt-4">{{ booth.typeDisplayName }} {{ booth.boothNumber }}</h5>
        <div
          v-for="item of selectableFoodOptions"
          :key="item"
          class="chip cursor-pointer mr-2 mt-2 inline-flex align-middle"
          :class="hasFoodItem(booth.id, item) ? 'bg-black text-white' : 'bg-white text-black outline'"
          @click="toggleFoodSelection(booth.id, item)"
        >
          <SvgIcon :path="getFoodTypeIcon(item)" class="mr-1" />
          {{ item }}
        </div>
      </div>
      <p class="opacity-60 mt-3 text-label-m">
        {{ t('PartnerCompany.foodTypesHint') }}
      </p>
    </div>

    <div class="form-section">
      <h4>{{ t('PartnerCompany.interests') }}</h4>
      <AlertBox v-if="showSelectableInterestLimitAlert" type="warning" class="mt-2">
        <template #header>
          {{ t('PartnerCompany.interestsLimitWarning') }}
        </template>
      </AlertBox>
      <div class="mt-2 chips-list">
        <SelectableInterest
          v-for="interest in interests"
          :key="interest.id"
          :interest="interest"
          :selected="selected(interest)"
          @toggle-selection="toggleSelection"
        />
      </div>
    </div>

    <div class="mt-6 form-section">
      <h4 class="mt-8 mb-4">{{ t('PartnerCompany.socialMediaLinks') }}</h4>
      <div v-for="{ name, iconPath } in socialMediaLinks" :key="name" class="flex items-center">
        <TextInput
          :key="name"
          v-model="partnerCompanyFormData[name]"
          type="url"
          :label="t(`PartnerCompany.${String(name)}`)"
          :hint="t('PartnerCompany.linkHint')"
          :error="getValidationErrors(v$[name])"
          :append-icon="iconPath"
        />
      </div>
    </div>

    <div class="mt-8 mb-6 text-right">
      <RouterLink :to="{ name: 'partnerCompany' }" class="btn-text-purple-m mr-4">
        {{ t('cancel') }}
      </RouterLink>

      <button
        class="btn-primary-purple-m"
        :disabled="v$.$error"
        data-test-id="partner_company_save_button"
        @click.prevent="updatePartnerCompany"
      >
        <span v-if="!saving && uploadProgress == 0">{{ t('saveButtonLabel') }}</span>
        <span v-if="saving && uploadProgress > 0 && uploadProgress < 100">{{ uploadProgress }}% {{ t('EventForm.uploaded') }}</span>
        <span v-if="saving && uploadProgress == 100">{{ t('EventForm.processing') }}</span>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, reactive, onMounted } from 'vue'

// Components
import { SelectableInterest } from '@/components'

// GQL
import {
  useInterestsQuery,
  useBoothsQuery,
  useUpdatePartnerCompanyMutation,
  useUpdateBoothMutation,
  BoothFoodTypeEnum,
  EventPeriodEnum,
  BoothTypeEnum,
} from '@/gql/myomr'

// Types
import type { PartnerCompany, UpdatePartnerCompanyMutationVariables, InterestsQuery } from '@/gql/myomr'
type Interest = InterestsQuery['interests']['nodes'][number]

// Helpers
import { showNotification } from '@/helpers/notificationHelper'

// i18n
import { useI18n } from 'vue-i18n'

// Router
import { useRouter } from 'vue-router'

// Icons
import { mdiPaperclip, mdiWeb, mdiTwitter, mdiYoutube, mdiFacebook, mdiInstagram, mdiLinkedin } from '@mdi/js'
import getFoodTypeIcon from '@/helpers/getFoodTypeIcon'

// Validators
import { requiredUnless, required, url, maxLength } from '@/services/validators'
import { imageMinSizeRule, imageMaxSizeRule } from '@/helpers/validationHelper'
import useVuelidate from '@vuelidate/core'
import { TextInput } from '@ramp106/omrjs-core-ui'
import { getValidationErrors } from '@/helpers/validationHelper'

const MIN_WEB_LOGO_HEIGHT = 30
const MIN_WEB_LOGO_WIDTH = 200
const MAX_WEB_LOGO_HEIGHT = 1200
const MAX_WEB_LOGO_WIDTH = 1200

const MAX_INTERESTS = 4
const NOTIFICATION_MILLISECONDS = 5000

const MAX_DESCRIPTION_LENGTH = 750

const router = useRouter()

const props = defineProps<{
  partnerCompany: PartnerCompany
}>()

const { result } = useInterestsQuery()
const { mutate } = useUpdatePartnerCompanyMutation()

// Booths:
const { result: boothsResult, onResult } = useBoothsQuery(
  { eventPeriod: EventPeriodEnum.Upcoming, type: BoothTypeEnum.GastroBooth },
  () => ({ enabled: props.partnerCompany.hasFoodBooths }),
)
const booths = computed(() => boothsResult.value?.booths.nodes)
onResult(() => {
  booths.value?.forEach((booth) => {
    const foodTypes = []
    if (booth.foodTypes != null) foodTypes.push(...booth.foodTypes)
    boothsFormData.value.push({ boothId: booth.id, foodTypes: foodTypes })
  })
})
const boothsFormData = ref<{ boothId: string; foodTypes: BoothFoodTypeEnum[] }[]>([])
const boothFormData = (boothId: string) => boothsFormData.value?.find((data) => data.boothId === boothId)
function hasFoodItem(boothId: string, foodType: BoothFoodTypeEnum) {
  return boothFormData(boothId)?.foodTypes.includes(foodType)
}
function toggleFoodSelection(boothId: string, foodType: BoothFoodTypeEnum) {
  const data = boothFormData(boothId)
  if (!data) return

  if (!hasFoodItem(boothId, foodType)) {
    data.foodTypes.push(foodType)
  } else {
    const index = data.foodTypes.indexOf(foodType)
    data.foodTypes.splice(index, 1)
  }
}
const { mutate: updateBooth } = useUpdateBoothMutation()

const interests = computed(() => result.value?.interests.nodes)

const showSelectableInterestLimitAlert = ref(false)
const saving = ref(false)

const selectableFoodOptions = Object.values(BoothFoodTypeEnum)

const partnerCompanyFormData = reactive({
  descriptionDe: '',
  descriptionEn: '',
  displayName: '',
  facebook: '',
  instagram: '',
  interests: [],
  linkedinUrl: '',
  logo: undefined,
  name: '',
  partnerCompanyId: props.partnerCompany.id,
  twitter: '',
  webLogo: undefined,
  website: '',
  youtube: '',
})

const companyHasWebLogo = computed<boolean>(() => props.partnerCompany.webLogoUrl != null)

const rules = {
  descriptionDe: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  descriptionEn: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  displayName: { required },
  facebook: { url },
  instagram: { url },
  linkedinUrl: { url },
  twitter: { url },
  webLogo: {
    requiredIfWebLogoUrlNotPresent: requiredUnless(companyHasWebLogo),
    imageMinDimension: imageMinSizeRule(MIN_WEB_LOGO_WIDTH, MIN_WEB_LOGO_HEIGHT),
    imageMaxDimension: imageMaxSizeRule(MAX_WEB_LOGO_WIDTH, MAX_WEB_LOGO_HEIGHT),
  },
  website: { url },
  youtube: { url },
}

const v$ = useVuelidate(rules, partnerCompanyFormData)

type FormDataAttributesKey = keyof Omit<typeof partnerCompanyFormData, 'interests' | 'partnerCompanyId' | 'logo' | 'webLogo'>
onMounted(() => {
  let { id, interests, ...formData } = props.partnerCompany
  const formDataAttributes = (({
    descriptionDe,
    descriptionEn,
    displayName,
    facebook,
    instagram,
    linkedinUrl,
    name,
    twitter,
    website,
    youtube,
  }) => ({ descriptionDe, descriptionEn, displayName, facebook, instagram, linkedinUrl, name, twitter, website, youtube }))(formData)
  Object.keys(formDataAttributes).forEach(
    (key) => formDataAttributes[key as FormDataAttributesKey] == null && delete formDataAttributes[key as FormDataAttributesKey],
  )
  Object.assign(partnerCompanyFormData, formDataAttributes)
})

const logoAcceptedFormats = 'application/pdf,application/postscript,image/svg+xml'
const webLogoAcceptedFormats = 'image/png,image/jpg,image/jpeg'

const socialMediaLinks = [
  { name: 'twitter', iconPath: mdiTwitter },
  { name: 'facebook', iconPath: mdiFacebook },
  { name: 'youtube', iconPath: mdiYoutube },
  { name: 'instagram', iconPath: mdiInstagram },
  { name: 'linkedinUrl', iconPath: mdiLinkedin },
] as const

const selectedInterests = ref<string[]>(props.partnerCompany.interests?.map((interest) => interest.id) || [])

let uploadProgress = ref<number>(0)

const i18n = useI18n()
const t = i18n.t

function selected(interest: Interest) {
  return selectedInterests.value.includes(interest.id)
}

function toggleSelection({ interest, selected }: { interest: Interest; selected: boolean }) {
  if (selected) {
    if (selectedInterests.value.length < MAX_INTERESTS) {
      selectedInterests.value.push(interest.id)
    } else {
      showSelectableInterestLimitAlert.value = true
      setTimeout(() => (showSelectableInterestLimitAlert.value = false), NOTIFICATION_MILLISECONDS)
    }
  } else {
    // remove
    let index = selectedInterests.value.indexOf(interest.id)
    if (index > -1) {
      selectedInterests.value.splice(index, 1)
    }
  }
}

const emit = defineEmits(['refetchPartnerCompany'])

const areSetsEqual = (a: Set<string>, b: Set<string>) => a.size === b.size && [...a].every((value) => b.has(value))

async function updatePartnerCompany() {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) {
    showNotification(
      i18n
        .t('BoothExtras.validationError')
        .toString()
        .replace(/<\/?[^>]+>/gi, ' '),
      'error',
    )

    return
  }

  saving.value = true
  const mutationData: UpdatePartnerCompanyMutationVariables = {
    ...partnerCompanyFormData,
    interests: selectedInterests.value,
    partnerCompanyId: props.partnerCompany.id,
  }

  try {
    boothsFormData.value.forEach((data) => {
      const booth = booths.value?.find((booth) => booth.id === data.boothId)
      const currentFoodTypes = booth?.foodTypes || []
      if (!areSetsEqual(new Set(data.foodTypes), new Set(currentFoodTypes))) updateBooth(data)
    })
    await mutate(mutationData, {
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (progress: ProgressEvent) => {
            const loadedProgress = Math.round((progress.loaded / progress.total) * 100)
            uploadProgress.value = loadedProgress
          },
        },
      },
    })

    router.push({ name: 'partnerCompany' })
    window.scrollTo(0, 0)
    showNotification(i18n.t('PartnerCompany.partnerCompanyUpdated'), 'success')
  } catch (_e) {
    showNotification(i18n.t('EventForm.failedUpdateNotification'), 'error')
  }

  saving.value = false
  uploadProgress.value = 0
  emit('refetchPartnerCompany')
}
</script>
