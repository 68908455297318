<template>
  <div>
    <h1 class="pb-7">{{ t('Booths.headline') }}</h1>

    <ContentCard>
      <template #header>
        <TabBar>
          <NavTabBarItem :is-selected="period === 'upcoming'" :to="{ name: 'booths' }">
            {{ t('Booths.upcoming') }}
          </NavTabBarItem>
          <NavTabBarItem :is-selected="period === 'past'" :to="{ name: 'boothsArchive' }">
            {{ t('Booths.archive') }}
          </NavTabBarItem>
        </TabBar>
      </template>

      <ProgressBar v-if="boothsLoading" />

      <div>
        <BoothPanel v-for="booth in props.booths" :key="booth.id" :booth="booth" :booths-loading="props.boothsLoading" />
      </div>

      <div class="text-center my-6">
        <button v-if="props.pageInfo.hasNextPage" class="btn-secondary-black-m" :disabled="props.boothsLoading" @click="emit('fetchMore')">
          {{ t('fetchMore') }}
        </button>
      </div>
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { BoothsQuery, PageInfo } from '@/gql/myomr'
import BoothPanel from './BoothPanel.vue'
import { TabBar, NavTabBarItem } from '@/ui/Tabs'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const route = useRoute()

const props = defineProps<{
  booths: BoothsQuery['booths']['nodes']
  boothsLoading: boolean
  pageInfo: PageInfo
}>()

const emit = defineEmits<{
  (e: 'fetchMore'): void
}>()

const period = computed(() => (route.name == 'boothsArchive' ? 'past' : 'upcoming'))
</script>
