<template>
  <div class="boothHeaderMeta">
    <div v-if="props.typeDisplayName">
      {{ props.typeDisplayName }}
    </div>
    <div v-if="props.boothNumber">
      {{ props.boothNumber }}
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  boothNumber?: string | null
  typeDisplayName?: string | null
}>()
</script>

<style lang="scss" scoped>
.boothHeaderMeta {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-size: 12px;
  font-style: oblique;
  text-transform: uppercase;

  div {
    &:before {
      position: relative;
      content: '';
      float: left;
      margin-right: 5px;
      background-repeat: no-repeat;
      background-position: left top;
    }

    & + div {
      margin-left: 25px;
    }
  }
}
</style>
