import urlRegex from 'url-regex'

import i18n from '@/plugins/i18n'

type TranslateFunction = typeof i18n.global.t

import {
  validateImageSquare,
  validateImageMinSize,
  validateImageMaxSize,
  validateFileMaxSize,
  validateFileMimeType,
} from '@/helpers/imageSizeHelper'
import { helpers } from '@vuelidate/validators'
import type { BaseValidation } from '@vuelidate/core'
import { ALLOWED_LOCALES } from '@/helpers/translationsHelper'

export const getValidationErrors = (validation: BaseValidation) =>
  validation.$error ? validation.$errors.map((error) => error.$message).join(' ') : undefined

export const buildTranslationFieldsRules = <T>(rule: T, locales: string[] = ALLOWED_LOCALES): Record<string, T> => {
  return locales.reduce(
    (acc, locale) => {
      acc[locale] = rule
      return acc
    },
    {} as Record<string, T>,
  )
}

export const urlRule = (t: TranslateFunction) =>
  helpers.withMessage(t('validations.url').toString(), (value: string) => {
    if (!helpers.req(value)) return true
    return value.startsWith('http') && urlRegex({ exact: true }).test(value)
  })

export const imageSquareRule = helpers.withMessage('validations.imageNotSquare', helpers.withAsync(validateImageSquare))

// @deprecated use imageMinDimensionRule instead
export const imageMinSizeRule = (minWidth: number, minHeight: number) =>
  helpers.withMessage('validations.sizeTooSmall', helpers.withAsync(validateImageMinSize(minWidth, minHeight)))

// @deprecated use imageMaxDimensionRule instead
export const imageMaxSizeRule = (maxWidth: number, maxHeight: number) =>
  helpers.withMessage('validations.sizeTooBig', helpers.withAsync(validateImageMaxSize(maxWidth, maxHeight)))

export const imageMinDimensionRule = (t: TranslateFunction, params: { minWidth: number; minHeight: number }) =>
  helpers.withMessage(
    t('validations.imageMinDimension', params).toString(),
    helpers.withAsync(validateImageMinSize(params.minWidth, params.minHeight)),
  )
export const imageMaxDimensionRule = (t: TranslateFunction, params: { maxWidth: number; maxHeight: number }) =>
  helpers.withMessage(
    t('validations.imageMaxDimension', params).toString(),
    helpers.withAsync(validateImageMaxSize(params.maxWidth, params.maxHeight)),
  )

export const fileMaxSizeRule = (t: TranslateFunction, params: { megaBytes: number }) =>
  helpers.withMessage(t('validations.fileSizeTooBig', params).toString(), helpers.withAsync(validateFileMaxSize(params.megaBytes)))

export const fileMimeTypeRule = (t: TranslateFunction, params: { mimeTypes: string[] }) =>
  helpers.withMessage(t('validations.fileMimeType', params).toString(), helpers.withAsync(validateFileMimeType(params.mimeTypes)))
