<template>
  <div>
    <TextInput v-model="formData.firstname" class="mb-5" :label="t('ContactEditForm.firstName')" />
    <TextInput v-model="formData.name" class="mb-5" :label="t('ContactEditForm.name')" />
    <TextInput v-model="formData.title" class="mb-5" :label="t('ContactEditForm.position')" />
    <TextInput v-model="formData.email" class="mb-5" :label="t('ContactEditForm.email')" :error="getValidationErrors(v$.email)" />
    <TextInput v-model="formData.phone" class="mb-5" :label="t('ContactEditForm.phone')" :error="getValidationErrors(v$.phone)" />

    <div class="mt-8 text-right">
      <button class="btn-ghost-purple-m mr-4" @click="emit('cancel')">
        {{ t('cancel') }}
      </button>

      <button class="btn-primary-purple-m" :loading="saving" @click.prevent="submit">
        {{ t('saveButtonLabel') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import type { Contact, UpdateContactMutationVariables, CreateContactMutationVariables } from '@/gql/myomr'
import { ContactTypeEnum } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import phoneRegex from 'phone-regex'
import { email } from '@/services/validators'
import { TextInput } from '@ramp106/omrjs-core-ui'
import { getValidationErrors } from '@/helpers/validationHelper'

const i18n = useI18n()
const t = i18n.t

const props = defineProps<{
  contact?: Contact
  saving: boolean
  partnerCompanyId: string
}>()

const phoneRule = (text: string): boolean => {
  if (!text.length) return true
  return phoneRegex({ exact: true }).test(text)
}

const rules = {
  phone: { phoneRule },
  email: { email },
}

const formData = reactive({
  firstname: '',
  name: '',
  title: '',
  email: '',
  phone: '',
})

const v$ = useVuelidate(rules, formData)

onMounted(() => {
  if (!props.contact) return
  const formDataAttributes = (({ firstname, name, title, email, phone }) => ({ firstname, name, title, email, phone }))(props.contact)
  Object.assign(formData, formDataAttributes)
})

const emit = defineEmits<{
  (e: 'save', value: UpdateContactMutationVariables): void
  (e: 'create', value: CreateContactMutationVariables): void
  (e: 'cancel'): void
}>()

async function submit() {
  const isFormValid = await v$.value.$validate()
  if (isFormValid && props?.contact?.id) emit('save', { ...formData, id: props.contact.id })
  if (isFormValid && !props?.contact?.id)
    emit('create', { ...formData, partnerCompanyId: props.partnerCompanyId, type: ContactTypeEnum.SalesContact })
}
</script>
