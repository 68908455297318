import { useI18n } from 'vue-i18n'

export default function useTableOptions() {
  const { t } = useI18n()

  const dataFilterOptions = [
    { text: t('EmailInvitationQuota.listTags.all'), value: '' },
    { text: t('EmailInvitationQuota.listTags.draft'), value: 'draft' },
    { text: t('EmailInvitationQuota.listTags.sent'), value: 'sent' },
    {
      text: t('EmailInvitationQuota.listTags.opened'),
      value: 'opened',
    },
    {
      text: t('EmailInvitationQuota.listTags.purchased'),
      value: 'purchased',
    },
    {
      text: t('EmailInvitationQuota.listTags.accepted'),
      value: 'accepted',
    },
    {
      text: t('EmailInvitationQuota.listTags.declined'),
      value: 'declined',
    },
    {
      text: t('EmailInvitationQuota.listTags.bounced'),
      value: 'bounced',
    },
    {
      text: t('EmailInvitationQuota.listTags.activated'),
      value: 'activated',
    },
  ]

  const headers = [
    { text: t('email'), value: 'email', sortable: false },
    { text: t('title'), value: 'title', sortable: false },
    { text: t('firstName'), value: 'firstName', sortable: false },
    { text: t('lastName'), value: 'lastName', sortable: false },
    { text: t('company'), value: 'company', sortable: false },
    { text: t('gender'), value: 'gender', sortable: false },
    { text: t('status'), value: 'status', sortable: false },
    { text: t('createdAt'), value: 'createdAt', sortable: false },
    { text: t('EmailInvitationQuota.listTags.activated'), value: 'activated', sortable: false },
  ]

  return { dataFilterOptions, headers }
}
