<template>
  <div class="mt-10">
    <h2 class="font-semibold text-3xl">My Leads</h2>
    <ContentCard class="mt-7">
      <div class="flex">
        <SecondarySidebar v-if="hasVisibleSidebarItems" :sidebar-items="visibleSidebarItems" />
        <RouterView
          v-if="hasVisibleSidebarItems"
          :slug="route.params.slug"
          :loading="loading"
          :has-root-event="hasRootEvent"
          :event-types="currentEventTypes"
        />
        <MyleadsPlaceholder v-else :loading="loading" />
      </div>
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, nextTick, watch } from 'vue'
import { SecondarySidebar } from '@/components/SecondarySidebar'
import { useMeStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'
import {
  useLeadCountsByEventQuery,
  EventTypeEnum,
  type LeadCountsByEventQuery,
  usePartnerCompanyLeadCountQuery,
  type PartnerCompanyLeadCountQuery,
} from '@/gql/myomr'
import type { ApolloQueryResult } from '@apollo/client/core'
import { useRoleRedirect } from '@/services/roleRedirect'

useRoleRedirect('viewLeads')

const meStore = useMeStore()

const route = useRoute()
const router = useRouter()
const omrEventTypes = [EventTypeEnum.Masterclass, EventTypeEnum.SideEvent, EventTypeEnum.GuidedTour]
const dmcEventTypes = [EventTypeEnum.DigitalMasterclass]

const availableSidebarItems = {
  omr24: { label: 'OMR24', route: { name: 'eventLeads', params: { slug: 'omr24' } } },
  omr23: { label: 'OMR23', route: { name: 'eventLeads', params: { slug: 'omr23' } } },
  omr22: { label: 'OMR22', route: { name: 'eventLeads', params: { slug: 'omr22' } } },
  dmc: { label: 'Digital Masterclasses', route: { name: 'eventLeads', params: { slug: 'dmc' } } },
  reviews: { label: 'OMR Reviews', route: { name: 'eventLeads', params: { slug: 'reviews' } } },
}
type AvailableSidebarItemKey = keyof typeof availableSidebarItems
const sidebarItemVisibility = reactive<{ [key in keyof typeof availableSidebarItems]: boolean }>({
  omr24: false,
  omr23: false,
  omr22: false,
  dmc: false,
  reviews: false,
})
const eventKeys = Object.keys(availableSidebarItems).filter((key) => /omr\d{2}/.test(key))

const visibleSidebarItemKeys = computed(() => {
  return Object.keys(availableSidebarItems).filter((key) => sidebarItemVisibility[key as AvailableSidebarItemKey])
})
const visibleSidebarItems = computed(() => {
  return visibleSidebarItemKeys.value.map((key) => availableSidebarItems[key as AvailableSidebarItemKey])
})

// OMR main event related queries
const eventQueries = eventKeys.map((key) => {
  const eventKey = key as AvailableSidebarItemKey
  const eventQuery = useLeadCountsByEventQuery(() => ({ rootEvent: eventKey, type: omrEventTypes }))
  eventQuery.onResult((queryResult) => afterEventsQueryHook(eventKey, queryResult))
  return eventQuery
})
const eventQueriesLoading = computed(() => eventQueries.some((query) => query.loading.value))

function afterEventsQueryHook(sidebarItemKey: AvailableSidebarItemKey, queryResult: ApolloQueryResult<LeadCountsByEventQuery>) {
  if (
    !queryResult.loading &&
    queryResult.data?.events?.nodes?.length &&
    queryResult.data?.events?.nodes?.reduce((acc, event) => acc + (event?.leadCount || 0), 0)
  ) {
    sidebarItemVisibility[sidebarItemKey] = true
  }
}

// Partner company related queries, by main event
const partnerCompanyQueries = eventKeys.map((key) => {
  const eventKey = key as AvailableSidebarItemKey
  const partnerCompanyQuery = usePartnerCompanyLeadCountQuery(() => ({
    mainEventId: key,
    partnerCompanyId: meStore.currentPartnerCompanyGlobalId as string,
  }))
  partnerCompanyQuery.onResult((queryResult) => afterPartnerCompanyQueryHook(eventKey, queryResult))
  return partnerCompanyQuery
})
const partnerCompaniesLoading = computed(() => partnerCompanyQueries.some((query) => query.loading.value))

function afterPartnerCompanyQueryHook(
  sidebarItemKey: AvailableSidebarItemKey,
  queryResult: ApolloQueryResult<PartnerCompanyLeadCountQuery>,
) {
  if (!queryResult.loading && queryResult.data?.leads?.totalCount) {
    sidebarItemVisibility[sidebarItemKey] = true
  }
}

// DMC related queries
const { onResult: onDmcResult, loading: dmcLoading } = useLeadCountsByEventQuery(() => ({ type: [EventTypeEnum.DigitalMasterclass] }))
onDmcResult((queryResult) => afterEventsQueryHook('dmc', queryResult))

const loading = computed(() => eventQueriesLoading.value || dmcLoading.value || partnerCompaniesLoading.value)

const hasVisibleSidebarItems = computed(() => visibleSidebarItemKeys.value.length > 0)

watch([hasVisibleSidebarItems], async () => {
  await nextTick()
  if (route.params.slug !== undefined && visibleSidebarItemKeys.value.includes(route.params.slug as string)) return
  redirectToFirstVisibleSidebarItem()
})

async function redirectToFirstVisibleSidebarItem() {
  if (!visibleSidebarItemKeys.value[0]) return
  router.push({ name: 'eventLeads', params: { slug: visibleSidebarItemKeys.value[0] } })
}

const eventConfig = {
  omr24: { hasRootEvent: true, eventTypes: omrEventTypes },
  omr23: { hasRootEvent: true, eventTypes: omrEventTypes },
  omr22: { hasRootEvent: true, eventTypes: omrEventTypes },
  dmc: { hasRootEvent: false, eventTypes: dmcEventTypes },
  reviews: { hasRootEvent: false, eventTypes: [] },
}

const hasRootEvent = computed(() => eventConfig[route.params?.slug as AvailableSidebarItemKey]?.hasRootEvent || false)
const currentEventTypes = computed(() => eventConfig[route.params?.slug as AvailableSidebarItemKey]?.eventTypes || [])
</script>
