import { ApplicationPolicy } from '@/policies/ApplicationPolicy'

export abstract class PartnerCompanyPolicy extends ApplicationPolicy {
  public static edit() {
    return this.hasCompanyProfileAccess()
  }
}

export enum PartnerCompanyAbilities {
  edit = 'edit',
}
