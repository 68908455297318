import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

export type FilterStatusOption = '' | 'valid' | 'swapped' | 'invalid'

export default function getFilterOptions() {
  const { t } = useI18n()

  return computed(() => [
    { text: t('EmailInvitationQuota.listTags.all'), value: '' },
    { text: t('Ticket.state.valid'), value: 'valid' },
    { text: t('Ticket.state.swapped'), value: 'swapped' },
    { text: t('Ticket.state.invalid'), value: 'invalid' },
  ])
}
