<template>
  <div>
    <h1 v-if="hasCompany" class="pb-7">
      {{ t('PartnerCompany.headlineTop') }}
    </h1>

    <ContentCard v-if="hasCompany">
      <template #header>
        <div class="my-6 flex flex-row justify-between">
          <h2>{{ t('PartnerCompany.headline') }}</h2>
          <RouterLink v-if="canEditPartnerCompany" class="btn-primary-purple-m" :to="{ name: 'partnerCompanyEdit' }">
            {{ t('editButtonLabel') }}
          </RouterLink>
        </div>
      </template>

      <RouterView v-if="hasCompany && !meLoading" :partner-company="me.partnerCompany" />
    </ContentCard>

    <ContentCard v-if="hasCompany" class="mt-8">
      <template #header>
        <div class="flex mt-6 mb-4 flex-row items-center justify-between">
          <div>
            <h2>{{ t('PartnerCompany.contactForAppointments') }}</h2>
            <p>{{ t('PartnerCompany.contactForAppointmentsHelpText') }}</p>
          </div>
          <div v-if="!isEditingContact" class="btn-primary-purple-m" @click="toggleEditingContact">
            {{ t('editButtonLabel') }}
          </div>
        </div>
      </template>
      <ContactDetails v-if="!isEditingContact" :contact="contactForAppointments" />

      <ContactEditForm
        v-else
        class="mt-4 mb-5"
        :contact="contactForAppointments"
        :saving="updatingContact || creatingContact"
        :partner-company-id="partnerCompanyId"
        @cancel="toggleEditingContact"
        @save="saveContact"
        @create="createContact"
      />
    </ContentCard>

    <AlertBox v-if="!hasCompany && !meLoading" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import type { Contact, UpdateContactMutationVariables, CreateContactMutationVariables, MeQuery } from '@/gql/myomr'
import { useUpdateContactMutation, useCreateContactMutation } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { $can } from '@/services/roles'
import { useRoleRedirect } from '@/services/roleRedirect'

useRoleRedirect('viewCompany')

const { t } = useI18n()
const route = useRoute()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  meLoading?: boolean
}>()

const newContact = ref<null | Contact>(null)

const partnerCompanyId = computed(() => {
  return props.me?.partnerCompany?.id || null
})

const isEditingContact = ref(false)

function toggleEditingContact() {
  isEditingContact.value = !isEditingContact.value
}

const { mutate: updateContact, loading: updatingContact, onDone: onContactUpdated } = useUpdateContactMutation()
const { mutate: mutateNewContact, loading: creatingContact, onDone: onContactCreated } = useCreateContactMutation()
onContactUpdated(() => (isEditingContact.value = false))
onContactCreated((result) => {
  if (result?.data?.createContact?.contact) newContact.value = result?.data?.createContact?.contact as Contact
  isEditingContact.value = false
})

function saveContact(contact: UpdateContactMutationVariables) {
  updateContact(contact)
}

function createContact(contact: CreateContactMutationVariables) {
  mutateNewContact(contact)
}

const hasCompany = computed(() => props.me?.partnerCompany)

const contactForAppointments = computed(() => {
  if (!props.me.partnerCompany) return
  if (newContact.value) return newContact.value
  return props.me.partnerCompany.contacts.find((contact) => contact.type === 'SalesContact')
})

const canEditPartnerCompany = computed(
  () => hasCompany.value && route.name !== 'partnerCompanyEdit' && $can('edit', { for: props.me.partnerCompany }),
)
</script>
