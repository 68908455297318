<template>
  <div v-if="props.contact" class="mb-6">
    <div v-if="props.contact.firstname" class="mt-6">
      <h3>{{ t('Contact.firstName') }}</h3>
      <p>
        {{ props.contact.firstname }}
      </p>
    </div>

    <div v-if="props.contact.name" class="mt-6">
      <h3>{{ t('Contact.lastName') }}</h3>
      <p>
        {{ props.contact.name }}
      </p>
    </div>

    <div v-if="props.contact.title" class="mt-6">
      <h3>{{ t('Contact.position') }}</h3>
      <p>
        {{ props.contact.title }}
      </p>
    </div>

    <div v-if="props.contact.email" class="mt-6">
      <h3>{{ t('Contact.email') }}</h3>
      <p>
        {{ props.contact.email }}
      </p>
    </div>

    <div v-if="props.contact.phone" class="mt-6">
      <h3>{{ t('Contact.phone') }}</h3>
      <p>
        {{ props.contact.phone }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Contact } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  contact: Contact
}>()
</script>
