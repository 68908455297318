export const getLocalizedAttribute = ({
  de,
  en,
  fallback,
  locale,
}: {
  de?: string | null
  en?: string | null
  fallback: string
  locale: string
}) => {
  let attribute
  if (locale === 'de') {
    attribute = de
  } else {
    attribute = en
  }

  if (!attribute || !attribute.length) {
    return fallback
  } else {
    return attribute
  }
}
