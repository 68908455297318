<template>
  <ConfirmModal
    persist
    :approve="t('EmailInvitationQuota.incompleteTemplateModal.approve', numberOfSelectedInvitees)"
    :cancel="t('EmailInvitationQuota.incompleteTemplateModal.cancel', numberOfSelectedInvitees)"
    @close-dialog="handleCloseDialog"
  >
    <template #title>
      <h1 class="text-[#FA0000] text-headline-m flex items-center p-6 pb-0">
        <SvgIcon :path="mdiExclamation" :size="24" class="bg-[#FA0000] mr-2 rounded-full text-white" />{{
          t('EmailInvitationQuota.incompleteTemplateModal.title', numberOfSelectedInvitees)
        }}
      </h1>
    </template>
    <template #content>
      <p class="px-6 pt-4">{{ t('EmailInvitationQuota.incompleteTemplateModal.description', numberOfSelectedInvitees) }}</p>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { mdiExclamation } from '@mdi/js'

const { t } = useI18n()

defineProps<{
  numberOfSelectedInvitees: number
}>()

const emit = defineEmits(['closeDialog', 'openEditModal'])

async function handleCloseDialog(shouldDeleteUsers: boolean) {
  if (!shouldDeleteUsers) return emit('closeDialog')

  emit('openEditModal')
}
</script>
