export function getStatusColor(state: string, forcedAllocation: boolean | null | undefined) {
  switch (state) {
    case 'requested':
      return 'chip-grey'
    case 'accepted':
      return forcedAllocation ? 'chip bg-tailwind-300' : 'chip-tailwind'
    case 'denied':
      return forcedAllocation ? 'chip-grey' : 'chip bg-amaranth-500'
    default:
      return 'chip-grey'
  }
}

export function getStatusLabel(state: string, forcedAllocation: boolean | null | undefined) {
  if (state == 'accepted' && forcedAllocation) return 'attendee.acceptedSuccessor'
  if (state == 'denied' && forcedAllocation) return 'attendee.requested'
  return `attendee.${state}`
}
