import { useUiStore } from '@/stores'
import type VueI18n from 'vue-i18n'
import type { UiNotificationType } from '@/stores/ui'
type TranslateResult = VueI18n.TranslateResult

export const showNotification = (message: string | TranslateResult, type: UiNotificationType, timeout = 5000) => {
  const uiStore = useUiStore()
  const notification = uiStore.addNotification({
    type: type,
    message: message,
  })
  window.setTimeout(() => uiStore.removeNotification(notification), timeout)
  return notification
}
