<template>
  <div class="flex justify-between items-start py-6">
    <div>
      <h4 class="mb-3 text-headline-xs">{{ props.quota.title || props.quota.ticketCategory?.titleEn }}</h4>
      <MarkdownContent :unstyled="true" class="text-body-s enable-lists" :md="getLocalizedDescription" />
      <ul v-if="isInternalQuotaType && quota.fields.length" class="pt-1">
        <li v-for="(field, index) in quota.fields" :key="index" class="ml-6 list-disc">
          <p class="text-body-s-medium mb-2">{{ field.name }}</p>
          <MarkdownContent :unstyled="true" class="text-body-s enable-lists ps-4" :md="getLocalizedFieldDescription(field)" />
        </li>
      </ul>
    </div>

    <div class="text-right flex-shrink-0">
      <RouterLink class="btn-primary-purple-m" :to="{ name: 'invitationQuota', params: { invitationQuotaId: quotaId } }">
        {{ t('InvitationQuota.showList') }}
      </RouterLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toPlainId } from '@/helpers/globalIdHelper'
import type { EventQuery, Field } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { MarkdownContent } from '@ramp106/omrjs-core-ui'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'
import { internalQuotaTypes } from '@/components/InternalQuota/quotaConfig'

const { t, locale } = useI18n()

const props = defineProps<{
  quota: NonNullable<EventQuery['eventById']['invitationQuotas']>[number]
}>()

const getLocalizedDescription = computed(() =>
  getLocalizedAttribute({
    de: props.quota.descriptionDe,
    en: props.quota.descriptionEn,
    fallback: '',
    locale: locale.value,
  }),
)

const isInternalQuotaType = computed(() => internalQuotaTypes.includes(props.quota.kind))

const getLocalizedFieldDescription = (field: Field) =>
  getLocalizedAttribute({
    de: field.descriptionDe,
    en: field.descriptionEn,
    fallback: '',
    locale: locale.value,
  })

const quotaId = computed(() => toPlainId(props.quota.id))
</script>

<style>
.enable-lists ul {
  list-style: disc !important;
}
</style>
