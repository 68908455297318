import type { TabEnum } from './activeTab'
import { TicketAasmStateEnum, TicketSourceEnum } from '@/gql/myomr'
import { type FilterStatusOption } from './filters'

export const aasmStateByTab: { [key in TabEnum]: TicketAasmStateEnum[] } = {
  activated: [TicketAasmStateEnum.Completed, TicketAasmStateEnum.Unlocked],
  deactivated: Object.values(TicketAasmStateEnum).filter((state) => state !== TicketAasmStateEnum.Locked),
  requests: [TicketAasmStateEnum.Locked],
}
export const sourceByTab: { [key in TabEnum]: TicketSourceEnum[] } = {
  activated: [],
  deactivated: [],
  requests: [TicketSourceEnum.RequestForm],
}

export const activatedStateByTab: { [key in TabEnum]: boolean } = {
  activated: true,
  deactivated: false,
  requests: false,
}

export function getAasmStateByTabAndFilterStatus(tab: TabEnum, filterStatus: FilterStatusOption) {
  if (filterStatus === 'valid') {
    return [TicketAasmStateEnum.Completed]
  } else if (filterStatus === 'invalid') {
    return [TicketAasmStateEnum.Unlocked]
  } else {
    return aasmStateByTab[tab]
  }
}

export const swappedStateByFilterStatus: { [key in FilterStatusOption]: boolean | undefined } = {
  '': undefined,
  valid: undefined,
  invalid: false,
  swapped: true,
}
