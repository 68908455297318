<template>
  <div>
    <slot name="header" :toggle-panel="togglePanel" :is-panel-open="isPanelOpen"> </slot>
    <slot v-if="isPanelOpen" name="content"> </slot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const isPanelOpen = ref(false)

function togglePanel() {
  isPanelOpen.value = !isPanelOpen.value
}
</script>
