<template>
  <div>
    <ConfirmModal
      :title="t('UserManagement.CreateUser.attachPersonalMessageText')"
      :show-floating-close-button="false"
      :approve="t('UserManagement.CreateUser.CTAs.create')"
      @close-dialog="handleCloseDialog"
    >
      <template #content>
        <TextInput
          id="message"
          v-model="personalMessage"
          type="textarea"
          class="px-6"
          :max-length="750"
          :label="t('UserManagement.CreateUser.attachPersonalMessageLabel')"
        />
      </template>
    </ConfirmModal>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import type { CreateUserMutationVariables, RoleNameEnum } from '@/gql/myomr'
import { TextInput } from '@ramp106/omrjs-core-ui'

const props = defineProps<{
  email: string
  roleNames: RoleNameEnum[]
}>()

const personalMessage = ref('')

const emit = defineEmits<{
  (e: 'closeModal'): void
  (e: 'sendInvitation', value: CreateUserMutationVariables): void
}>()

function handleCloseDialog(confirmed: boolean) {
  if (!confirmed) return emit('closeModal')
  return emit('sendInvitation', { email: props.email, roleNames: props.roleNames, personalMessage: personalMessage.value })
}
</script>
