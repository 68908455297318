<template>
  <div>
    <AlertBox v-if="$cannot('viewTickets', { for: 'User' })" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <template v-else>
      <h1>{{ t('EventInvitations.headline') }}</h1>
      <InvitesContainer v-if="!loading" :event="result?.eventById" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { toGlobalId } from '@/helpers/globalIdHelper'
import { useI18n } from 'vue-i18n'
import { useEventQuery } from '@/gql/myomr'
import { useRoleRedirect } from '@/services/roleRedirect'
import { $cannot } from '@/services/roles'

useRoleRedirect('viewEvents')

const { t } = useI18n()

const props = defineProps<{
  eventId: string
}>()

const { loading, result } = useEventQuery({ id: toGlobalId('Event', props.eventId) }, { fetchPolicy: 'no-cache' })
</script>
