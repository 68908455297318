<template>
  <ConfirmModal persist wrapper-class="max-w-2xl" @close-dialog="handleCloseDialog">
    <template #title>
      <h1 class="text-headline-m flex items-center p-6 pb-0">
        {{ t('InvitationQuota.CrewQuota.denyTicketsModal.title', props.ticketIds.length) }}
      </h1>
    </template>
    <template #content>
      <p class="px-6 pt-4">
        {{ t('InvitationQuota.CrewQuota.denyTicketsModal.description', props.ticketIds.length) }}
      </p>
    </template>
    <template #actions>
      <div class="flex justify-end mt-6 px-6 pb-6">
        <button class="btn-text-black-s font-bold uppercase" @click="handleCloseDialog(false)">
          {{ t('cancel') }}
        </button>
        <button class="btn-primary-error-m ml-4" data-test-id="accept-button" @click="handleCloseDialog(true)">
          {{ t('InvitationQuota.CrewQuota.denyTicketsModal.mainCta') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import handleTicketMutationErrors from './handleTicketMutationErrors'
import { useDenyTicketRequestMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { captureException } from '@sentry/vue'
import { injectStrict } from '@/helpers/injectStrict'
import { reloadTicketsKey } from '@/symbols/reloadTicketsKey'

const reloadTickets = injectStrict(reloadTicketsKey)

const { t } = useI18n()
const { mutate } = useDenyTicketRequestMutation()

const props = defineProps<{
  ticketIds: string[]
}>()

const emit = defineEmits<{
  (e: 'closeDialog', value: boolean): void
}>()

async function handleCloseDialog(persist: boolean) {
  if (!persist) return emit('closeDialog', false)

  try {
    const responses = await Promise.allSettled(props.ticketIds.map((ticketId) => mutate({ ticketId })))
    const errorsMap = responses.map((response) => {
      if (response.status === 'fulfilled' && response.value?.data?.denyTicketRequest?.errors) {
        return response.value.data.denyTicketRequest.errors
      }
    })
    handleTicketMutationErrors(
      t,
      errorsMap,
      'InvitationQuota.CrewQuota.errorTicketDenyNotification',
      'InvitationQuota.CrewQuota.successTicketDenyNotification',
    )
  } catch (e) {
    captureException(e)
    showNotification(t('InvitationQuota.CrewQuota.unsuccessfulUpdateNotification'), 'error', 3000)
  }
  reloadTickets()
  emit('closeDialog', true)
}
</script>
