<template>
  <div class="flex flex-row gap-9 mt-8">
    <div class="grow-0">
      <span class="block text-body-l">{{ quotaAvailableText }}</span>
      <span class="block text-body-s">{{ t('InvitationQuota.CrewQuota.available') }}</span>
    </div>

    <div class="grow-0 relative">
      <div class="h-[45px] w-[1px] bg-grey-300 absolute -left-2 top-1"></div>
      <span class="block text-body-l">{{ counts.activated }}</span>
      <span class="block text-body-s">{{ t('InvitationQuota.CrewQuota.activated') }}</span>
    </div>

    <div class="grow-0 relative">
      <div class="h-[45px] w-[1px] bg-grey-300 absolute -left-2 top-1"></div>
      <span class="block text-body-l">{{ counts.requested }}</span>
      <span class="block text-body-s">{{ t('InvitationQuota.CrewQuota.requested') }}</span>
    </div>

    <div class="grow-0 relative">
      <div class="h-[45px] w-[1px] bg-grey-300 absolute -left-2 top-1"></div>
      <span class="block text-body-l">{{ counts.deactivated }}</span>
      <span class="block text-body-s">{{ t('InvitationQuota.CrewQuota.deactivated') }}</span>
    </div>

    <div class="grow-0 relative">
      <div class="h-[45px] w-[1px] bg-grey-300 absolute -left-2 top-1"></div>
      <span class="block text-body-l">{{ counts.swapped }}</span>
      <span class="block text-body-s">{{ t('InvitationQuota.CrewQuota.swapped') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { InvitationQuotaQuery } from '@/gql/myomr'
import type { TicketCounts } from './ticketCounts'

const { t } = useI18n()

const props = defineProps<{
  counts: TicketCounts['countData']
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const quotaAvailableText = computed(() => {
  return t('EventInvitations.quotaOf', {
    availableCount: Math.max(props.invitationQuota.maxUsable - (props.counts.activated || 0), 0),
    maxUsable: props.invitationQuota.maxUsable,
  })
})
</script>
