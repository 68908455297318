<template>
  <RouterView v-if="me" :me="me" />
</template>

<script lang="ts">
export default {
  name: 'AuthorizedArea',
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script setup lang="ts">
import type { MeQuery } from '@/gql/myomr'

defineProps<{
  me?: MeQuery['me']
}>()
</script>
