<template>
  <ConfirmModal @close-dialog="emit('closeDialog')">
    <template #title>
      <h1 data-test-id="headline" class="text-headline-m p-6 pb-5">{{ title }}</h1>
    </template>

    <template #description>
      <div class="flex w-[672px] justify-between px-6 pb-5">
        <p class="text-body-m">
          {{ description }}
        </p>
        <div class="btn-secondary-black-m py-1 px-2 uppercase" data-test-id="load-template-button" @click="loadTemplate">
          <SvgIcon class="mr-1" :path="mdiFile" :size="15" />{{ t('EmailInvitationQuota.manualContactTemplate.useMyTemplate') }}
        </div>
      </div>
    </template>

    <template #content>
      <div class="max-h-[550px] px-6 pt-2 overflow-y-scroll scrollbar-hide pb-11">
        <TextInput
          id="email-subject"
          v-model="formData.subject"
          data-test-id="email-subject"
          :error="getValidationErrors(v$.subject)"
          :label="t('EmailInvitationQuota.emailTemplateModal.emailSubject')"
        />
        <TextInput
          id="message-body"
          v-model="formData.message"
          type="textarea"
          data-test-id="email-body"
          :error="getValidationErrors(v$.message)"
          class="mt-4 min-h-[180px]"
          :label="t('EmailInvitationQuota.emailTemplateModal.emailMessage')"
        />
        <div class="pt-4 pb-4">
          <p class="text-body-m pb-3">{{ t('EmailInvitationQuota.emailTemplateModal.placeholderHint') }}</p>
          <p v-for="chipItem of ChipItems" :key="chipItem" class="message-chip" @click="appendChip(chipItem)">{{ chipItem }}</p>
        </div>
        <TextInput
          id="reply-to"
          v-model="formData.replyTo"
          :error="getValidationErrors(v$.replyTo)"
          data-test-id="reply-to"
          :label="t('EmailInvitationQuota.emailTemplateModal.replyTo')"
          :hint="t('EmailInvitationQuota.emailTemplateModal.replyToHint')"
        />
        <TextInput
          id="cc"
          v-model="formData.cc"
          class="mt-9"
          :error="getValidationErrors(v$.cc)"
          data-test-id="cc"
          :label="t('EmailInvitationQuota.emailTemplateModal.ccEmail')"
          :hint="t('EmailInvitationQuota.emailTemplateModal.ccEmailHint')"
        />
      </div>
    </template>

    <template #actions>
      <div class="flex justify-between mt-8 px-6 py-6 bg-grey-100">
        <button class="btn-text-black-m uppercase" @click="emit('goBack')">
          <SvgIcon class="mr-1" :path="mdiChevronLeft" />{{ t('EmailInvitationQuota.manualContactTemplate.backButton') }}
        </button>
        <button class="btn-primary-error-m ml-4" data-test-id="sendEmail" @click="sendEmail">
          <SvgIcon class="mr-1" :path="mdiSend" :size="15" />{{ t('EmailInvitationQuota.manualContactTemplate.sendInvitationButton') }}
        </button>
      </div>
    </template>
  </ConfirmModal>
</template>
<script lang="ts" setup>
// Composition api
import { reactive } from 'vue'

// Validators
import { required, email } from '@/services/validators'
import useVuelidate from '@vuelidate/core'

// Types
import type { InvitationQuotaQuery, SendIndividualInvitationsInput } from '@/gql/myomr'

// Icons
import { mdiFile, mdiChevronLeft, mdiSend } from '@mdi/js'

import { TextInput } from '@ramp106/omrjs-core-ui'
import { getValidationErrors } from '@/helpers/validationHelper'

// Services
import { showNotification } from '@/helpers/notificationHelper'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  title: string
  description: string
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const emit = defineEmits(['closeDialog', 'goBack', 'sendEmail'])

const { t } = useI18n()

// Form management
const formData = reactive({
  subject: '',
  message: '',
  replyTo: '',
  cc: '',
})

const rules = {
  subject: { required },
  message: { required },
  replyTo: { required, email },
  cc: { email },
}

const v$ = useVuelidate(rules, formData)

// Chips logic
const ChipItems = ['{{title}}', '{{first_name}}', '{{last_name}}', '{{company}}', '{{gender}}']

async function appendChip(chipItem: string) {
  formData.message += chipItem
}

// Load template logic
function loadTemplate() {
  if (!props.invitationQuota.messageBody || !props.invitationQuota.messageSubject) {
    showNotification(t('EmailInvitationQuota.manualContactTemplate.notifications.emptyTemplateError'), 'error')
    return
  }
  formData.message = props.invitationQuota.messageBody
  formData.subject = props.invitationQuota.messageSubject
  formData.cc = props.invitationQuota.cc || ''
  formData.replyTo = props.invitationQuota.replyTo || ''
}

async function sendEmail() {
  const isFormValid = await v$.value.$validate()
  if (!isFormValid) return
  const emailData: Partial<Omit<SendIndividualInvitationsInput, 'email'>> = {
    invitationQuotaId: props.invitationQuota.id,
    messageBody: formData.message,
    messageSubject: formData.subject,
    replyTo: formData.replyTo,
  }

  if (formData.cc) emailData.cc = formData.cc

  emit('sendEmail', emailData)
}
</script>
<style lang="scss" scoped>
.message-chip {
  @apply p-2 text-xs bg-grey-100 border border-grey-700 inline-block mr-2 cursor-pointer;
}
</style>
