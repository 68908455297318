<template>
  <component
    :is="linkComponent"
    class="block pl-9 pr-5 py-3 text-body-s text-white visited:text-white hover:text-white hover:bg-purple-600"
    v-bind="componentProps"
    :class="{ 'router-link-active': routeMatches }"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import { useRoute } from 'vue-router'
const route = useRoute()

const routeMatches = computed(() => {
  if (!props.activeRoutes || !route.name) return false
  return props.activeRoutes.includes(route.name as string)
})

const props = defineProps<{
  to: RouteLocationRaw
  activeRoutes?: Array<string>
}>()

const isExternalLink = computed(() => typeof props.to === 'string' && props.to.startsWith('http'))
const linkComponent = computed(() => (isExternalLink.value ? 'a' : 'RouterLink'))
const componentProps = computed(() => (isExternalLink.value ? { href: props.to, target: '_blank' } : { to: props.to }))
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-purple-600;
}
</style>
