// TODO: Check if this hack is still needed after upgrading to vue 3. There are
// hints suggesting it isn't, see:
// https://github.com/vuejs/apollo/issues/1325#issuecomment-1060153311

import { gql as apolloGql } from '@apollo/client/core'
import { markRaw } from 'vue'

export function gql(literals: string | readonly string[], ...args: any[]) {
  return markRaw(apolloGql(literals, ...args))
}
