<template>
  <div>
    <ConfirmModal
      v-if="user && showModal"
      :title="t('UserManagement.deleteUser.title', { email: user.email })"
      :cancel="t('UserManagement.deleteUser.cancel')"
      :approve="t('UserManagement.deleteUser.confirm')"
      :show-floating-close-button="false"
      @close-dialog="closeDialog"
    />

    <BackLink class="mb-4" :to="{ name: 'userList' }">{{ t('backToOverview') }}</BackLink>

    <UserForm
      v-if="user"
      :header="String(t('UserManagement.editUserHeader'))"
      :submit-label="String(t('UserManagement.EditUser.CTAs.saveChanges'))"
      :user="user"
      @submit="editUserHandler"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { showNotification } from '@/helpers/notificationHelper'
import UserForm from './UserForm.vue'
import { useUserQuery, useUpdateUserRolesMutation, type RoleNameEnum } from '@/gql/myomr'
import BackLink from '@/ui/BackLink.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const { t } = useI18n()

const props = defineProps<{
  loading: boolean
  id: string
}>()

const emit = defineEmits(['reloadUsers'])

const { result } = useUserQuery({ userId: props.id })

const user = computed(() => result.value?.user)

const roleNames = ref<RoleNameEnum[]>([])
const showModal = ref(false)

const { mutate: updateUserRolesMutation } = useUpdateUserRolesMutation()

function editUserHandler({ roleNames: newRoleNames }: { roleNames: RoleNameEnum[] }) {
  roleNames.value = newRoleNames

  if (roleNames.value.length === 0) {
    showModal.value = true
    return
  }

  editUser()
}

function closeDialog(confirm: boolean) {
  if (confirm) editUser()
  showModal.value = false
}

function editUser() {
  if (!user.value?.id) return

  updateUserRolesMutation({
    roleNames: roleNames.value,
    userId: user.value.id,
  }).then((result) => {
    const errors = result?.data?.updateUserRoles?.errors || []
    if (errors.length) {
      showNotification(errors.join(), 'error')
    } else {
      showNotification(
        t('UserManagement.Notifications.successfulEdit', {
          email: result?.data?.updateUserRoles?.user?.email,
        }),
        'success',
      )
      emit('reloadUsers')
      router.push({ name: 'userList' })
    }
  })
}
</script>
