<template>
  <div>
    <AlertBox v-if="v$.$invalid" type="danger" :icon-path="mdiAlertCircle" class="mt-5">
      <template #header>
        <p class="text-headline-xs mt-1">{{ t('EmailInvitationQuota.matching.errorHeadline', { count: '' }) }}</p>
        <p v-for="error in v$.$errors" :key="error.$uid" class="mt-1">
          {{ t('EmailInvitationQuota.matching.errorColumnNotFound', { name: t(error.$property) }) }}
        </p>
      </template>
    </AlertBox>
    <AlertBox v-else type="success" class="mt-5">
      <template #header>
        <p class="text-headline-xs mt-1">{{ t('EmailInvitationQuota.upload.successful', { name: 'CSV' }) }}</p>
        <p class="mt-1">{{ t('EmailInvitationQuota.matching.autoMatching') }}</p>
      </template>
    </AlertBox>
    <div class="rounded-sm border border-grey-300 bg-white px-8 py-4">
      <div class="flex">
        <div v-if="debug" class="w-72">
          <p class="p-0 text-headline-s">Column Mapping</p>
          <InvitationQuotaCsvColumn
            v-for="property in inviteeProperties"
            :key="property"
            :name="property"
            :value="csvMapping[property]"
            :required="property === 'email'"
            :error="property === 'email' && hasError"
            :items-for-select="itemsForSelect(property)"
            @mapping-changed="mappingChanged(property, $event)"
          />
        </div>
        <div class="w-full overflow-x-auto">
          <p class="text-headline-m">{{ t('EmailInvitationQuota.matching.previewHeadline') }}</p>
          <TableLight class="mt-5 min-w-full text-left">
            <thead>
              <tr class="text-body-s">
                <th
                  v-for="key in inviteeProperties"
                  :key="key"
                  class="text-left opacity-70 uppercase font-bold"
                  :class="v$[key]?.$errors.length ? 'text-amaranth-700' : 'text-black'"
                >
                  {{ t(key) }}
                </th>
                <th v-if="hasFields" class="text-left opacity-70 uppercase font-bold text-black">
                  {{ typed_t('ticketForm.permissions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in previewItems"
                :key="`${item.email}-${index}`"
                class="transition duration-300 ease-in-out hover:bg-grey-100"
              >
                <td v-for="key in inviteeProperties" :key="key" class="whitespace-nowrap px-3 py-3">{{ item[key] || '-' }}</td>
                <td v-if="hasFields" class="whitespace-nowrap px-3 py-3">
                  {{ fieldsString }}
                </td>
              </tr>
            </tbody>
          </TableLight>
        </div>
      </div>

      <div class="flex justify-end">
        <RouterLink :to="{ name: 'invitationQuotaCsvUpload' }" class="btn-text-purple-m uppercase mr-6">
          {{ t('cancel') }}
        </RouterLink>
        <button class="btn-primary-purple-m uppercase" :disabled="saving || v$.error" @click.prevent="saveMapping">
          <ProgressBarCircular v-if="saving" size="small" color="victoria" class="ml-5" />
          {{ t('EmailInvitationQuota.matching.ctaLabel') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue'
import camelCase from 'lodash.camelcase'
import { InvitationQuotaTypeEnum, type CsvMappingAttributes, type CsvPreviewData, type InvitationQuota } from '@/gql/myomr'
import InvitationQuotaCsvColumn from './InvitationQuotaCsvColumn.vue'
import { useI18n } from 'vue-i18n'
import { mdiAlertCircle } from '@mdi/js'
import typed_translation from '@/helpers/typed_translation'

import { useVuelidate } from '@vuelidate/core'
import { required, requiredIf } from '@/services/validators'
import TableLight from '@/ui/TableLight.vue'

const props = defineProps<{
  csvPreviewData: CsvPreviewData[]
  invitationQuota: InvitationQuota
  saving: boolean
}>()

const emit = defineEmits<{
  (e: 'saveMapping', value: { hasHeaders: boolean; mapping: CsvMappingAttributes }): void
}>()

const { t } = useI18n()
const typed_t = typed_translation('InvitationQuota', props.invitationQuota.kind)

const autoMatched = ref(false)
const debug = ref(false)

// `email` can be null while editing this form, but not while submitting:
const csvMapping = reactive<Omit<Required<CsvMappingAttributes>, 'email'> & { email: number | null }>({
  cc: null,
  company: null,
  dateOfBirth: null,
  email: null,
  externalId: null,
  firstName: null,
  gender: null,
  lastName: null,
  phone: null,
  position: null,
  title: null,
  partnerName: null,
  partnerRole: null,
})

const hasFields = computed(() => props.invitationQuota.fields.length > 0)
const fieldsString = computed(() => props.invitationQuota.fields.map((field) => field.name).join(', '))

type CsvMappingKey = keyof CsvMappingAttributes
const quotaPropertyKeys: { [key: string]: CsvMappingKey[] } = {
  [InvitationQuotaTypeEnum.CodeQuota]: ['email'],
  [InvitationQuotaTypeEnum.CrewQuota]: [
    'email',
    'firstName',
    'lastName',
    'company',
    'position',
    'dateOfBirth',
    'gender',
    'phone',
    'partnerName',
    'partnerRole',
  ],
  [InvitationQuotaTypeEnum.ExhibitorQuota]: ['email'],
  [InvitationQuotaTypeEnum.GuestQuota]: ['email', 'title', 'firstName', 'lastName', 'company', 'gender', 'cc', 'externalId'],
  [InvitationQuotaTypeEnum.PressQuota]: ['email', 'firstName', 'lastName', 'company', 'position', 'dateOfBirth', 'gender', 'phone'],
}

const inviteeProperties = computed<CsvMappingKey[]>(() => quotaPropertyKeys[props.invitationQuota.kind])

const rules = {
  company: { required },
  email: { required },
  firstName: { required },
  gender: { required },
  lastName: { required },
  phone: { requiredIfCrewQuota: requiredIf(() => props.invitationQuota.kind === InvitationQuotaTypeEnum.CrewQuota) },
}

const v$ = useVuelidate(rules, csvMapping)

const previewData = computed(() => {
  const data: { [id: string]: string[] } = {}
  inviteeProperties.value.forEach((property) => {
    if (csvMapping[property] == null) return

    let startIndex = 1
    const propertyMapping = csvMapping[property]
    if (propertyMapping != null) data[property] = props.csvPreviewData[propertyMapping].sample.slice(startIndex)
  })

  return data
})

type PreviewItem = {
  [K in CsvMappingKey]?: string
}
const previewItems = computed(() => {
  let sampleLength = 10
  return Array.from(Array(sampleLength).keys()).map((i) => {
    return inviteeProperties.value.reduce<PreviewItem>((obj, key) => {
      if (previewData.value[key] && previewData.value[key][i]) {
        obj[key] = previewData.value[key][i]
      }

      return obj
    }, {})
  })
})

const columnNames = computed(() => props.csvPreviewData.map((column) => column.sample[0]))

const columnNameMapping: { columnName: CsvMappingKey; regexp: RegExp }[] = [
  { columnName: 'company', regexp: /company|firma|medium|media/i },
  { columnName: 'dateOfBirth', regexp: /dateOfBirth|geburtstag|geburtsdatum/i },
  { columnName: 'email', regexp: /email/i },
  { columnName: 'externalId', regexp: /^id$/i },
  { columnName: 'firstName', regexp: /vorname/i },
  { columnName: 'gender', regexp: /anrede|geschlecht|salutation/i },
  { columnName: 'lastName', regexp: /nachname/i },
  { columnName: 'phone', regexp: /^tel/i },
  { columnName: 'title', regexp: /^titel/i },
]
function autoMatch(columnName: string): CsvMappingKey | undefined {
  const normalizedColumnName = camelCase(columnName)
  if (csvMapping.hasOwnProperty(normalizedColumnName)) return normalizedColumnName as CsvMappingKey
  return columnNameMapping.find((mapping) => mapping.regexp.test(normalizedColumnName))?.columnName
}

onMounted(() => {
  // Try to match CSV columns
  columnNames.value.forEach((columnName, index) => {
    const match = autoMatch(columnName)
    if (match) {
      autoMatched.value = true
      csvMapping[match] = index
    }
  })
  v$.value.$validate()
})

function itemsForSelect(currentProperty: string) {
  return columnNames.value.map((name, index) => {
    let disabled = inviteeProperties.value.some((property) => {
      return csvMapping[property] === index && currentProperty !== property
    })

    return {
      text: name,
      value: index,
      disabled: disabled,
    }
  })
}

function mappingChanged(property: CsvMappingKey, index: number | null) {
  if (property === 'email' && index != null) hasError.value = false
  csvMapping[property] = index
}

const hasError = ref(false)

async function saveMapping() {
  const isValid = await v$.value.$validate()
  if (!isValid) {
    hasError.value = true
    return
  }

  if (csvMapping.email != null) {
    // If email is set, we can safely cast csvMapping to CsvMappingAttributes:
    emit('saveMapping', {
      hasHeaders: true,
      mapping: csvMapping as CsvMappingAttributes,
    })
  }
}
</script>
