import i18n from '@/plugins/i18n'
import { showNotification } from './notificationHelper'

export function handleMutationResponse(
  t: typeof i18n.global.t,
  response: { success?: boolean; errors?: string[] | null } | undefined | null,
  successMessage?: string,
) {
  if (response?.errors?.length) {
    const errorMessage = response?.errors?.join(' ') || t('errors.serverIssue')
    showNotification(errorMessage, 'error')
  } else {
    showNotification(successMessage || 'Success', 'success')
  }
}
