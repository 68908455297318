<template>
  <div class="text-center w-full mt-4 mb-4">
    <div v-if="props.loading">LOADING</div>
    <div v-else>{{ t('EventsLeadsCount.noLeadsFound') }}</div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const props = defineProps<{
  loading: boolean
}>()
</script>
