<template>
  <ConfirmModal persist hide-actions wrapper-class="max-w-4xl" @close-dialog="closeDialog">
    <template #title>
      <h1 class="text-headline-m p-6">{{ t('TicketDetails.headline') }}</h1>
      <div class="flex px-6 pb-6 flex-wrap justify-between">
        <div class="flex justify-start">
          <button class="btn-primary-purple-m uppercase" :disabled="props.ticket.swapped" @click="goToEditForm">
            <SvgIcon :path="mdiPencilOutline" :size="18" class="mr-2" />
            {{ t('TicketDetails.CtaLabel.edit') }}
          </button>
          <MyOmrLink
            v-if="quotaConfig.showDownloadButton"
            :href="`/user_tickets/${toPlainId(props.ticket.id)}.pdf`"
            class="btn-secondary-purple-m uppercase ml-4"
          >
            <SvgIcon :path="mdiDownload" :size="18" class="mr-2" />
            {{ t('TicketDetails.CtaLabel.download') }}
          </MyOmrLink>
          <button class="btn-secondary-purple-m uppercase ml-4" @click="showResendTicketModal = true">
            <SvgIcon :path="mdiSend" :size="18" class="mr-2" />
            {{ t('TicketDetails.CtaLabel.send') }}
          </button>
        </div>
        <div class="flex justify-end">
          <span v-if="quotaConfig.showDeactivationFeature">
            <button v-if="!ticket.deactivatedAt" class="btn-text-black-m uppercase" @click="showDeactivateTicketsModal = true">
              <SvgIcon :path="mdiCancel" :size="18" class="mr-2" />
              {{ t('TicketDetails.CtaLabel.deactivate') }}
            </button>
            <button
              v-else
              :disabled="props.isLimitReached || isDeadlineReached"
              class="btn-text-black-m uppercase"
              @click="showActivateTicketsModal = true"
            >
              <SvgIcon :path="mdiCancel" :size="18" class="mr-2" />
              {{ t('InvitationQuota.CrewQuota.activateTicketsModal.activateButton') }}
            </button>
          </span>
          <button class="btn-text-black-m uppercase" :disabled="props.ticket.swapped" @click="showDeleteTicketsModal = true">
            <SvgIcon :path="mdiDelete" :size="18" class="mr-2" />
            {{ t('TicketDetails.CtaLabel.delete') }}
          </button>
        </div>
      </div>
    </template>
    <template #content>
      <div v-if="!props.loading && props.ticket" class="max-h-[550px] px-6 pb-8 overflow-y-scroll scrollbar-hide">
        <AlertBox v-if="ticket.aasmState === 'unlocked'" :icon-path="mdiAlertOctagon" class="mb-6" type="danger">
          <template #header>{{ t('InvitationQuota.CrewQuota.invalidTickets.singleTicket.title') }}</template>
          {{ t('InvitationQuota.CrewQuota.invalidTickets.singleTicket.description') }}
        </AlertBox>
        <div class="flex justify-between justify-items-start items-start">
          <TableLight class="max-w-md w-full mr-12 table-auto">
            <tbody>
              <tr class="border-b border-grey-300">
                <td class="py-2 pr-12 min-w-min">
                  <strong>
                    {{ typed_t('ticketForm.salutation') }}
                  </strong>
                </td>
                <td class="py-2">{{ salutation || '' }}</td>
              </tr>

              <tr v-for="attribute in displayedTicketAttributes" :key="`ticket-attribute-${attribute}`" class="border-b border-grey-300">
                <td class="py-2 pr-12 min-w-min">
                  <strong>
                    {{ typed_t(`ticketForm.${attribute}`) }}
                  </strong>
                </td>
                <td class="py-2">{{ attribute === 'companyType' ? CompanyTypeTicketAttribute : props.ticket[attribute] }}</td>
              </tr>
              <template v-if="quotaConfig.hasJobRelatedAttributes">
                <tr
                  v-for="(value, attribute) in jobRelatedAttributes"
                  :key="`ticket-attribute-${attribute}`"
                  class="border-b border-grey-300"
                >
                  <td class="py-2 pr-12 min-w-min">
                    <strong>
                      {{ typed_t(`ticketForm.${attribute}`) }}
                    </strong>
                  </td>
                  <td class="py-2">{{ value }}</td>
                </tr>
              </template>
            </tbody>
          </TableLight>
          <div>
            <QrcodeVue :value="props.ticket.identifier" :size="250" />
            <div class="mt-3 text-center">{{ ticket.identifier }}</div>
          </div>
        </div>
        <div v-if="props.ticket.fields?.length" class="mt-6">
          <h2 class="text-headline-s mb-4">Permissions</h2>
          <ul class="max-w-lg w-full list-disc ml-8">
            <li v-for="field in props.ticket.fields" :key="`ticket-field-${field.key}`">
              <template v-if="!shouldHidePermission(field.key)">
                <strong>
                  {{ field.name }}
                </strong>
                <MarkdownContent :unstyled="true" class="text-body-s enable-lists" :md="getLocalizedFieldDescription(field)" />
              </template>
            </li>
          </ul>
        </div>
      </div>

      <!-- Modals -->
      <DeactivateInternalTicketsModal v-if="showDeactivateTicketsModal" :ticket-ids="[ticket.id]" @close-dialog="handleDeactivateDialog" />
      <ActivateInternalTicketsModal v-if="showActivateTicketsModal" :ticket-ids="[ticket.id]" @close-dialog="handleActivateDialog" />
      <DeleteInternalTicketsModal v-if="showDeleteTicketsModal" :ticket-ids="[ticket.id]" @close-dialog="handleDeleteDialog" />
      <ResentTicketEmailModal v-if="showResendTicketModal" :ticket-id="ticket.id" @close-dialog="handleResendModal" />
    </template>
  </ConfirmModal>
</template>

<script lang="ts" setup>
import { computed, ref, reactive, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { MarkdownContent } from '@ramp106/omrjs-core-ui'
import {
  useUserPropertiesPreferencesQuery,
  useCompanyFieldsByIndustryQuery,
  type TicketQuery,
  type InvitationQuotaQuery,
  type Field,
} from '@/gql/myomr'
import { mdiPencilOutline, mdiAlertOctagon, mdiDownload, mdiSend, mdiDelete, mdiCancel } from '@mdi/js'
import { toPlainId } from '@/helpers/globalIdHelper'
import {
  jobLevelsMapper,
  companyDepartmentsMapper,
  companySizesMapper,
  companyTypesMapper,
  companyIndustriesMapper,
  companyFieldsMapper,
  countriesMapper,
  salutationsMapper,
} from './formOptions'
import type { TabEnum } from './activeTab'
import { isTicketRequest } from './ticketHelpers'
import typed_translation from '@/helpers/typed_translation'
import useQuotaConfig, { type InternalQuotaTypeEnum } from './quotaConfig'

import QrcodeVue from 'qrcode.vue'

const props = defineProps<{
  currentActiveTab: TabEnum
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  isLimitReached: boolean
  loading: boolean
  ticket: Ticket
}>()

const { t, locale } = useI18n()
const typed_t = typed_translation('InvitationQuota', props.invitationQuota.kind)

const showDeactivateTicketsModal = ref(false)
const showActivateTicketsModal = ref(false)
const showDeleteTicketsModal = ref(false)
const showResendTicketModal = ref(false)

function redirectBack() {
  router.push({ name: 'invitationQuota', query: route.query })
}

function goToEditForm() {
  router.push({ name: 'invitationQuotaEditTicket', query: route.query })
}

function shouldHidePermission(key: string | null | undefined): boolean {
  if (!key) return true
  return key.includes('OMR_MC') || key.includes('OMR_GT') || key.includes('festival_pass') || key == 'ffwd'
}

function handleDeactivateDialog(hasDeactivated: boolean) {
  showDeactivateTicketsModal.value = false
  if (hasDeactivated) redirectBack()
}

function handleActivateDialog(hasActivated: boolean) {
  showActivateTicketsModal.value = false
  if (hasActivated) redirectBack()
}

function handleDeleteDialog() {
  showDeleteTicketsModal.value = false
  redirectBack()
}

function handleResendModal() {
  showResendTicketModal.value = false
}

type Ticket = NonNullable<TicketQuery['ticket']>
type TicketAttribute = keyof Ticket

const route = useRoute()
const router = useRouter()

const quotaConfig = useQuotaConfig(props.invitationQuota.kind as InternalQuotaTypeEnum)

const isDeadlineReached = computed(() => {
  if (!props.invitationQuota.expirationDate) return false
  return new Date(props.invitationQuota.expirationDate).getTime() < new Date().getTime()
})

const getLocalizedFieldDescription = function (field: Field) {
  return locale.value === 'en' ? field.descriptionEn || '' : field.descriptionDe || ''
}

const { result, onResult } = useUserPropertiesPreferencesQuery()
const queryVariables = computed(() => ({ id: String(props.ticket.companyIndustryId) }))
const { result: companyFieldsQueryResult, onResult: onCompanyFieldsQueryResult } = useCompanyFieldsByIndustryQuery(queryVariables, {
  enabled: !!queryVariables.value.id,
})

const jobLevels = jobLevelsMapper(result)
const companyDepartments = companyDepartmentsMapper(result)
const companySizes = companySizesMapper(result)
const companyTypes = companyTypesMapper(result)
const companyIndustries = companyIndustriesMapper(result)
const companyFields = companyFieldsMapper(companyFieldsQueryResult)
const countries = countriesMapper(result)
const salutations = salutationsMapper(result)
const salutation = ref('')

const displayedTicketAttributes = computed<TicketAttribute[]>(() => {
  const commonAttributes: TicketAttribute[] = ['firstName', 'lastName', 'email', 'companyName', 'companyType', 'position']
  return commonAttributes.concat(quotaConfig.value.additonalTicketAttributes)
})

const CompanyTypeTicketAttribute = computed(
  () => companyTypes.value.find((option) => option.value === props.ticket.companyType)?.text || '',
)

const jobRelatedAttributes = reactive({
  jobLevel: '',
  companyDepartment: '',
  companySize: '',
  companyIndustry: '',
  companyField: '',
  country: '',
})
onResult(() => {
  jobRelatedAttributes['jobLevel'] = jobLevels.value.find((option) => option.value === props.ticket.jobLevel)?.text || ''
  jobRelatedAttributes['companyDepartment'] =
    companyDepartments.value.find((option) => option.value === props.ticket.companyDepartment)?.text || ''
  jobRelatedAttributes['companySize'] = companySizes.value.find((option) => option.value === props.ticket.companySize)?.text || ''
  jobRelatedAttributes['companyIndustry'] =
    companyIndustries.value.find((option) => option.value === props.ticket.companyIndustryId)?.text || ''
  jobRelatedAttributes['country'] = countries.value.find((option) => option.value === props.ticket.country)?.text || ''
  salutation.value = salutations.value.find((option) => option.value === props.ticket.salutation)?.text || ''
})
onCompanyFieldsQueryResult(() => {
  jobRelatedAttributes['companyField'] = companyFields.value.find((option) => option.value === props.ticket.companyFieldId)?.text || ''
})

const closeDialog = () => redirectBack()

onMounted(() => isTicketRequest(props.ticket) && redirectBack())
</script>
