<template>
  <div>
    <ContentCard>
      <AlertBox v-if="props.invitationQuota.event?.ticketCreationDeadlineHint" class="mt-5" type="info">
        <template #header> {{ t('EmailInvitationQuota.deadline.title') }} </template>
        {{
          t('EmailInvitationQuota.deadline.description', {
            expiry_date: formattedExpiryDate,
          })
        }}
      </AlertBox>
      <template #header>
        <div class="my-6">
          <h2 class="inline">{{ title }}</h2>
          <span class="ml-2 text-subline">{{ t('InvitationQuota.discount') }} {{ invitationQuota.discount }}%</span>

          <div class="mt-4 flex flex-row justify-between items-center">
            <QuotaStats :invitation-quota="invitationQuota" />

            <div class="flex flex-row gap-2 grow-0">
              <DropDownSelect v-if="showAddGuestsButton()">
                <template #activator="{ open }">
                  <button class="btn-primary-mint-m" :disabled="false" @click.prevent="open">
                    <SvgIcon :path="mdiPlusCircleOutline" :size="16" class="mr-2" />
                    {{ t('InvitationQuota.addGuestsCTA') }}
                  </button>
                </template>
                <template #default>
                  <ul class="list-none p-0 m-0 w-[175px]">
                    <li v-if="$can('uploadCsv', { for: invitationQuota })" class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100">
                      <RouterLink :to="{ name: 'invitationQuotaCsvUpload' }" class="text-grey-900">
                        {{ t('InvitationQuota.CSVUpload') }}
                      </RouterLink>
                    </li>
                    <li
                      v-if="$can('sendSingleInvite', { for: invitationQuota })"
                      class="text-left py-2 px-2 cursor-pointer hover:bg-grey-100"
                      @click="showManualContactModal = true"
                    >
                      {{ t('InvitationQuota.addManually') }}
                    </li>
                  </ul>
                </template>
              </DropDownSelect>

              <button v-if="showQuotaActionButtons()" class="btn-secondary-mint-m" @click="showEmailTemplateModal = true">
                <SvgIcon :path="mdiPencil" :size="16" class="mr-2" />
                {{ t('EmailInvitationQuota.editEmailTemplate') }}
              </button>

              <DownloadInvitees :invitation-quota="invitationQuota" />
            </div>
          </div>
        </div>
      </template>

      <h3 class="mt-6">{{ t('EmailInvitationQuota.tableTitle') }}</h3>
      <div class="mt-4 flex flex-row gap-2 items-center">
        <DropDownSelect>
          <template #activator="{ open }">
            <button class="flex p-2 border border-solid rounded border-grey-700 items-center" @click.prevent="open">
              <SvgIcon :path="mdiFilterVariant" :size="24" class="text-grey-700 mr-2" />
              {{ statusFilterLabel }}
            </button>
          </template>

          <template #default="{ close }">
            <ul class="list-none p-0 m-0">
              <li v-for="option in dataFilterOptions" :key="`filter_${option.value}`" class="separate-rows">
                <label class="block cursor-pointer">
                  <input v-model="status" type="radio" :value="option.value" class="peer absolute opacity-0 pointer-events-none" />
                  <div class="hover:bg-victoria-100 peer-checked:bg-victoria-100 py-2 px-3" @click="close">{{ option.text }}</div>
                </label>
              </li>
            </ul>
          </template>
        </DropDownSelect>

        <div class="grow"></div>

        <span v-if="selectedInviteeEmails.length" class="text-body-s-medium" data-test-id="selected-invitees-info">
          {{ t('EmailInvitationQuota.inviteesSelected', { count: selectedInviteeEmails.length }) }}
        </span>
        <div v-if="showQuotaActionButtons()" class="flex flex-row gap-2 grow-0">
          <button
            v-if="$can('sendInvitations', { for: invitationQuota })"
            :disabled="!totalSelectedDraftInviteees"
            data-test-id="send-invitations-button"
            class="btn-primary-purple-m"
            @click="showSendDraftInviteesModal = true"
          >
            <SvgIcon :path="mdiSend" :size="16" class="mr-2" />
            {{ t('InvitationQuota.sendUnsentInvitations', { unsentCount: totalSelectedDraftInviteees }) }}
          </button>

          <button
            v-if="$can('sendInvitations', { for: invitationQuota })"
            :disabled="!selectedResendInvitees.length"
            data-test-id="send-invitations-button"
            class="btn-primary-purple-m"
            @click="showResendInviteesModal = true"
          >
            <div class="w-[20px] h-[15px] relative">
              <SvgIcon :path="mdiSend" :size="13" class="absolute left-0 top-0" />
              <SvgIcon :path="mdiSend" :size="13" class="absolute left-1 top-1" />
            </div>
            <span class="pl-1">{{ t('EmailInvitationQuota.resendInviteesModal.title', selectedResendInvitees.length) }}</span>
          </button>

          <button
            v-if="$can('deleteUnsentInvitees', { for: invitationQuota })"
            :disabled="selectedInviteeEmails.length == 0"
            class="btn-secondary-purple-m px-3"
            @click="showDeleteInviteesModal = true"
          >
            <SvgIcon class="text-victoria" :class="{ 'opacity-40': !selectedInviteeEmails.length }" :path="mdiDelete" :size="16" />
          </button>
        </div>
      </div>

      <InviteeTable
        v-if="props.invitees"
        v-model="selectedInviteeEmails"
        :invitees="props.invitees.nodes"
        :can-send-invitees="canSendInvitees"
        @refetch-data="refetchAndReloadQuota"
      />

      <div v-if="hasInvitees" class="py-4 w-full flex justify-center items-center gap-2">
        <PaginationControl
          class="my-4"
          :show-items-per-page="true"
          :per-page="props.perPage"
          :total="props.invitees?.pageInfo.totalCount"
          show-pagination="always"
          :show-total="true"
          :page="props.page"
          @update:page="emit('update:page', $event)"
          @update:per-page="emit('update:perPage', $event)"
        />
      </div>
    </ContentCard>

    <!-- Modals -->
    <ManualContactWrapper
      v-if="showManualContactModal"
      :invitation-quota="invitationQuota"
      @close-dialog="closeManualContactModalAndRefetch"
    />

    <EmailTemplateModal
      v-if="showEmailTemplateModal"
      :title="t('EmailInvitationQuota.emailTemplateModal.title')"
      :description="t('EmailInvitationQuota.emailTemplateModal.description')"
      :invitation-quota="invitationQuota"
      @close-dialog="closeEditModalAndRefetch"
    />

    <DeleteInviteesModal
      v-if="showDeleteInviteesModal"
      :invitation-quota="invitationQuota"
      :invitees-to-delete="selectedInviteeEmails"
      @close-dialog="closeDeleteInviteesModalAndRefetch"
    />

    <SendDraftInviteesModal
      v-if="showSendDraftInviteesModal"
      :invitation-quota="invitationQuota"
      :invitees-to-send="selectedDraftInvitees"
      @close-dialog="closeSendDraftInviteesModalAndRefetch"
      @open-incomplete-template-modal="handleOpenIncompleteTemplateModal"
    />

    <SendDraftInviteesModal
      v-if="showSendDraftInviteesModal"
      :invitation-quota="invitationQuota"
      :invitees-to-send="selectedDraftInvitees"
      @close-dialog="closeSendDraftInviteesModalAndRefetch"
      @open-incomplete-template-modal="handleOpenIncompleteTemplateModal"
    />

    <ResendDraftInviteesModal
      v-if="showResendInviteesModal"
      :invitation-quota="invitationQuota"
      :invitees-to-send="selectedResendInvitees"
      @close-dialog="closeResendInviteesModalAndRefetch"
      @open-incomplete-template-modal="handleOpenIncompleteTemplateModal"
    />

    <IncompleteTemplateModal
      v-if="showIncompleteTemplateModal"
      :number-of-selected-invitees="totalSelectedDraftInviteees"
      @close-dialog="showIncompleteTemplateModal = false"
      @open-edit-modal="handleOpenEditModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import useTableOptions from './useTableOptions'
import { useI18n } from 'vue-i18n'
import { mdiPlusCircleOutline, mdiDelete, mdiSend, mdiFilterVariant, mdiPencil } from '@mdi/js'
import { $can } from '@/services/roles'
import { reloadQuotaKey } from '@/symbols/reloadQuotaKey'

// Helpers
import { injectStrict } from '@/helpers/injectStrict'

// GQL
import { type StatusEnum } from './GuestQuotaDataProvider.vue'
import type { InviteePaginatedCollection, InvitationQuotaQuery } from '@/gql/myomr'
import { RouterLink } from 'vue-router'

const { t } = useI18n()

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  invitees: InviteePaginatedCollection
  page: number
  perPage: number
  status: StatusEnum
}>()

const emit = defineEmits<{
  (e: 'refetch', value: boolean): void
  (e: 'update:page', value: number): void
  (e: 'update:perPage', value: number): void
  (e: 'update:status', value: StatusEnum): void
}>()

const status = computed({
  get: () => props.status,
  set: (newValue) => emit('update:status', newValue),
})

const reloadQuota = injectStrict(reloadQuotaKey)
const refetchAndReloadQuota = (showSuccessModal = false) => {
  emit('refetch', showSuccessModal)
  reloadQuota()
}

const selectedInviteeEmails = ref<string[]>([])

const selectedDraftInvitees = computed(() => {
  return props.invitees.nodes
    .filter((invitee) => {
      return invitee.status === 'draft' && selectedInviteeEmails.value.includes(invitee.email)
    })
    .map((invitee) => invitee.email)
})

const selectedResendInvitees = computed(() => {
  return (
    props.invitees.nodes
      .filter((invitee) => {
        return invitee.status !== 'purchased' && selectedInviteeEmails.value.includes(invitee.email)
      })
      .map((invitee) => invitee.email) || []
  )
})

const totalSelectedDraftInviteees = computed(() => {
  return selectedDraftInvitees.value?.length || 0
})

const showManualContactModal = ref(false)
const showEmailTemplateModal = ref(false)
const showDeleteInviteesModal = ref(false)
const showSendDraftInviteesModal = ref(false)
const showIncompleteTemplateModal = ref(false)
const showResendInviteesModal = ref(false)

const { dataFilterOptions } = useTableOptions()
const statusFilterLabel = computed(
  () => dataFilterOptions.find((option) => option.value == status.value)?.text || t('EmailInvitationQuota.filterByStatus'),
)

const hasInvitationsLeft = computed(
  () => props.invitationQuota.maxUsable - (props.invitationQuota.inviteeTracking?.purchasedCount || 0) > 0,
)
const invitationQuotaIsExpired = computed(() => {
  const expirationDate = props.invitationQuota.expirationDate
  if (expirationDate) {
    return new Date(expirationDate) < new Date()
  } else {
    return false
  }
})

const canSendInvitees = computed(() => hasInvitationsLeft.value && !invitationQuotaIsExpired.value)

const formattedExpiryDate = computed(
  () => new Date(props.invitationQuota.event?.ticketCreationDeadlineHint as string).toLocaleDateString('de-DE') || null,
)

watch(
  () => props.invitees,
  () => {
    selectedInviteeEmails.value = []
  },
)

const title = computed(() => {
  return props.invitationQuota.title || t('EmailInvitationQuota.defaultTitle')
})

const hasInvitees = computed(() => props.invitees?.nodes?.length)

function showAddGuestsButton() {
  return $can('uploadCsv', { for: props.invitationQuota }) || $can('sendSingleInvite', { for: props.invitationQuota })
}

function showQuotaActionButtons() {
  return (
    $can('editTemplate', { for: props.invitationQuota }) ||
    $can('sendTestEmail', { for: props.invitationQuota }) ||
    $can('sendInvitations', { for: props.invitationQuota }) ||
    $can('deleteUnsentInvitees', { for: props.invitationQuota })
  )
}

async function closeEditModalAndRefetch() {
  showEmailTemplateModal.value = false
  refetchAndReloadQuota()
}

async function closeManualContactModalAndRefetch() {
  showManualContactModal.value = false
  refetchAndReloadQuota()
}

async function closeDeleteInviteesModalAndRefetch() {
  showDeleteInviteesModal.value = false
  refetchAndReloadQuota()
}

async function closeSendDraftInviteesModalAndRefetch() {
  showSendDraftInviteesModal.value = false
  await refetchAndReloadQuota()
}

async function closeResendInviteesModalAndRefetch() {
  showResendInviteesModal.value = false
  await refetchAndReloadQuota()
}

function handleOpenEditModal() {
  showIncompleteTemplateModal.value = false
  showEmailTemplateModal.value = true
}

function handleOpenIncompleteTemplateModal() {
  showSendDraftInviteesModal.value = false
  showIncompleteTemplateModal.value = true
}
</script>
