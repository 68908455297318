<template>
  <tr>
    <td class="text-center">
      <div :title="title">
        <CheckBox :model-value="props.promoCode.manuallyMarkedAsSentAt != null" @update:model-value="updateStatus" />
      </div>
    </td>
    <td>
      <span :class="{ 'line-through': isSent }">{{ props.promoCode.code }}</span>
    </td>
    <td>
      {{ props.promoCode.userName }}
    </td>
    <td>
      {{ props.promoCode.companyName }}
    </td>
    <td class="text-center">
      {{ props.discount }}
    </td>
    <td class="text-center">
      <SvgIcon v-if="props.promoCode.ticketCount === 0" :path="mdiMinus" :size="18" class="mr-2 text-tailwind-800" />
      <SvgIcon v-if="props.promoCode.ticketCount > 0" :path="mdiCheck" :size="18" class="mr-2 text-tailwind-800" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { PromoCode } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
const { t, d } = useI18n()
import { mdiMinus, mdiCheck } from '@mdi/js'
import { CheckBox } from '@ramp106/omrjs-core-ui'
import { useManuallyMarkAsSentMutation, useManuallyMarkAsUnsentMutation } from '@/gql/myomr'

const props = defineProps<{
  promoCode: PromoCode
  discount: number
}>()

const { mutate: manuallyMarkAsSent } = useManuallyMarkAsSentMutation()
const { mutate: manuallyMarkAsUnsent } = useManuallyMarkAsUnsentMutation()

const isSent = computed(() => props.promoCode.manuallyMarkedAsSentAt != null)

const title = computed(() => {
  if (props.promoCode.manuallyMarkedAsSentAt != null) {
    return t('InvitationQuota.table.sent_at', { date: d(props.promoCode.manuallyMarkedAsSentAt, 'dateTimeShort') })
  }
  return t('InvitationQuota.table.not_sent')
})

function updateStatus(value: boolean) {
  if (value) {
    return manuallyMarkAsSent({ promoCodeId: props.promoCode.id })
  } else {
    return manuallyMarkAsUnsent({ promoCodeId: props.promoCode.id })
  }
}
</script>
