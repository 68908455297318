<template>
  <div v-if="showPaginationRef" class="flex justify-between items-center">
    <div class="text-grey-800">
      <span v-if="props.showRange && props.page">{{ paginationRange }}&nbsp;</span>
      <span v-if="props.showTotal">{{ paginationTotalInfo }}</span>
    </div>
    <nav v-if="pageCount > 1">
      <ul class="flex list-style-none space-x-8">
        <li class="arrow">
          <button :disabled="onFirstPage" @click.stop="decrement">
            <SvgIcon :path="mdiChevronLeft" :size="20" />
          </button>
        </li>
        <template v-if="props.page">
          <li :class="{ 'text-purple font-bold': props.page == 1 }">
            <button @click.stop="setPage(1)">1</button>
          </li>
          <li v-if="pageCount >= 3" :class="{ 'text-purple font-bold': props.page == 2 }">
            <button @click.stop="setPage(2)">2</button>
          </li>
          <li v-if="pageCount >= 4" :class="{ 'text-purple font-bold': props.page == 3 }">
            <button @click.stop="setPage(3)">3</button>
          </li>
          <li v-if="pageCount > 3 && props.page > 4">
            <button disabled>…</button>
          </li>
          <li v-if="props.page > 3 && props.page != pageCount" class="text-purple">
            <button>{{ props.page }}</button>
          </li>
          <li v-if="pageCount >= 5 && props.page <= pageCount - 2">
            <button disabled>…</button>
          </li>
          <li :class="{ 'text-purple': props.page == pageCount }">
            <button @click.stop="setPage(pageCount)">
              {{ pageCount }}
            </button>
          </li>
        </template>
        <li class="arrow">
          <button :disabled="onlastPage" @click.stop="increment">
            <SvgIcon :path="mdiChevronRight" :size="20" />
          </button>
        </li>
      </ul>
    </nav>

    <div>
      <span v-if="showItemsPerPage" class="text-grey-800 mr-4">{{ t('Pagination.itemsPerPage') }}:</span>
      <DropDownSelect v-if="showItemsPerPage" position="bottom" direction="right">
        <template #activator="{ open }">
          <button class="flex items-center border border-grey-300 rounded-sm p-2" @click.prevent="open">
            <span class="text-grey-800">{{ perPageLabel }}</span>
            <SvgIcon :path="mdiChevronUp" :size="20" class="text-grey-800 ml-3" />
          </button>
        </template>

        <template #default="{ close }">
          <ul class="list-none p-0 m-0">
            <li v-for="option in perPageOptions" :key="`${option.label}-items-per-page`" class="separate-rows">
              <label class="block cursor-pointer">
                <input v-model="perPage" type="radio" :value="option.value" class="peer absolute opacity-0 pointer-events-none" />
                <span class="block text-center min-w-20 hover:bg-purple-100 peer-checked:bg-purple-100 py-2 px-3" @click="close">
                  {{ option.label }}
                </span>
              </label>
            </li>
          </ul>
        </template>
      </DropDownSelect>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'PaginationControl' }
export const defaultPerPage = 10

export type PaginationQueryVariables = {
  first: number | undefined
  after: string | undefined
  last: number | undefined
  before: string | undefined
}
</script>

<script setup lang="ts">
import { computed, type WritableComputedRef } from 'vue'
import { mdiChevronLeft, mdiChevronRight, mdiChevronUp } from '@mdi/js'
import { useI18n } from 'vue-i18n'
import type { PageInfo } from '@/gql/myomr'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    page?: number
    pageInfo?: PageInfo
    perPage?: number
    showItemsPerPage?: boolean
    showPagination?: 'auto' | 'always'
    showRange?: boolean
    showTotal?: boolean
    total: number
  }>(),
  {
    page: undefined,
    pageInfo: undefined,
    perPage: 10,
    showItemsPerPage: true,
    showPagination: 'auto',
    showRange: true,
    showTotal: true,
    size: 8,
  },
)

const perPage: WritableComputedRef<number> = computed({
  get() {
    return props.perPage
  },
  set(newValue: number) {
    emit('update:perPage', newValue)
  },
})

const basePerPageOptions = [
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
]
const perPageOptions = computed(() => {
  if (props.total <= 1000) {
    return [...basePerPageOptions, { label: t('Pagination.showAll'), value: props.total }]
  } else {
    return basePerPageOptions
  }
})

const onFirstPage = computed(() => {
  if (props.page) {
    return props.page == 1
  } else {
    return !props.pageInfo?.hasPreviousPage
  }
})
const onlastPage = computed(() => {
  if (props.page) {
    return props.page == pageCount.value
  } else {
    return !props.pageInfo?.hasNextPage
  }
})

const perPageLabel = computed(() => perPageOptions.value.find((option) => option.value == perPage.value)?.label || defaultPerPage)

const pageCount = computed(() => {
  return Math.ceil(props.total / props.perPage)
})

const showPaginationRef = computed(() => {
  if (props.showPagination == 'always') return true
  return props.perPage ? props.total > props.perPage : props.total > defaultPerPage
})

const paginationTotalInfo = computed(() => {
  if (props.showRange && props.page) {
    return t('Pagination.total', { count: props.total })
  } else {
    return t('Pagination.totalOnly', props.total)
  }
})
const paginationRange = computed(() => {
  if (!props.page) return ''
  const from = (props.page - 1) * props.perPage + 1
  const to = Math.min(props.page * props.perPage, props.total)
  return `${from} - ${to}`
})

const emit = defineEmits<{
  (e: 'update:page', value: number): void
  (e: 'update:paginationQueryVariables', value: PaginationQueryVariables): void
  (e: 'update:perPage', value: number): void
}>()

function increment() {
  if (props.page) {
    emit('update:page', props.page + 1)
  } else if (props.pageInfo?.hasNextPage && props.pageInfo?.endCursor) {
    emit('update:paginationQueryVariables', {
      after: props.pageInfo.endCursor,
      before: undefined,
      first: perPage.value,
      last: undefined,
    })
  }
}
function decrement() {
  if (props.page) {
    emit('update:page', props.page + -1)
  } else if (props.pageInfo?.hasPreviousPage && props.pageInfo?.startCursor) {
    emit('update:paginationQueryVariables', {
      after: undefined,
      before: props.pageInfo.startCursor,
      first: undefined,
      last: perPage.value,
    })
  }
}
function setPage(page: number) {
  emit('update:page', page)
}
</script>

<style lang="scss" scoped>
button:disabled {
  @apply text-grey-700;
}
</style>
