<template>
  <label class="inline-flex items-center gap-2">
    <input
      class="peer absolute opacity-0 pointer-events-none"
      :checked="props.modelValue"
      :disabled="props.disabled"
      type="checkbox"
      @change="handleCheckboxChange"
    />
    <div class="checkbox" :class="props.size" />
    <span v-if="hasLabel" :disabled="props.disabled" class="label"><slot></slot></span>
  </label>
</template>

<script lang="ts">
export type CheckboxSizeEnum = 'small' | 'medium' | 'large'
</script>

<script lang="ts" setup>
import { computed, useSlots } from 'vue'

export interface Props {
  modelValue?: boolean
  disabled?: boolean
  size?: CheckboxSizeEnum
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  disabled: false,
  size: 'medium',
})

const slots = useSlots()

const hasLabel = computed(() => {
  return !!slots['default']
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

function handleCheckboxChange() {
  emit('update:modelValue', !props.modelValue)
}
</script>
<style lang="scss" scoped>
.checkbox {
  // Prevent checkbox from shrinking
  @apply shrink-0;
  // Cursor
  @apply cursor-pointer;
  // Border
  @apply border-inherit border-2 rounded-sm relative;
  // Checkbox mark
  @apply before:block before:absolute before:top-0
          before:border-l-2 before:border-b-2 before:border-inherit before:rotate-[314deg] before:z-10;
  // Corner
  @apply after:block after:w-[6px] after:h-[12px] after:absolute after:bg-white after:right-[-2px] after:top-[-5px];

  // Visibity
  @apply before:opacity-0 after:opacity-0 peer-checked:before:opacity-100 peer-checked:after:opacity-100;

  // Disabled state
  @apply peer-disabled:opacity-40 peer-disabled:cursor-default peer-disabled:pointer-events-none text-grey-800 peer-checked:text-black;
}

.label {
  // Disabled state
  @apply cursor-pointer peer-disabled:opacity-50 peer-disabled:cursor-default peer-disabled:pointer-events-none text-grey-800 peer-checked:text-black;
}

.small {
  // Border
  @apply w-[14px] h-[14px];
  // Checkbox mark
  @apply before:w-[10px] before:h-[6px] before:left-[2px];
}

.medium {
  // Border
  @apply w-[18px] h-[18px];
  // Checkbox mark
  @apply before:w-[12px] before:h-[7.5px] before:left-[2.5px];
}

.large {
  // Border
  @apply w-[20px] h-[20px];
  // Checkbox mark
  @apply before:w-[14px] before:h-[8px] before:left-[3px];
}
</style>
