<template>
  <div>
    <AlertBox v-if="!canViewSpeakers" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <RouterView
      v-if="canViewSpeakers && result?.speakers"
      :speakers="result?.speakers.nodes"
      :me="props.me"
      :loading="loading"
      @reload-speakers="refetch"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSpeakersQuery, type MeQuery } from '@/gql/myomr'
import { $can } from '@/services/roles'
import { useRoleRedirect } from '@/services/roleRedirect'

useRoleRedirect('viewSpeakers')

const { t } = useI18n()

const canViewSpeakers = computed(() => {
  return $can('viewSpeakers', { for: 'User' })
})

const props = defineProps<{
  me: MeQuery['me']
}>()

const { loading, refetch, result } = useSpeakersQuery()
</script>
