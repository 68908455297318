<template>
  <TooltipWrapper>
    <template #trigger>
      <ChipBadge :color="colorByState" rounded>
        {{ t(`Reviews.product.view.table.statusLabel.${state}`, t('Reviews.product.view.table.statusLabel.unknown')) }}
      </ChipBadge>
    </template>
    <template v-if="tooltipContentByState" #content>
      <div class="max-w-xs">{{ tooltipContentByState }}</div>
    </template>
  </TooltipWrapper>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ChipBadge, TooltipWrapper } from '@ramp106/omrjs-core-ui'
import { SurveyResponseStateEnum } from '@/gql/reviews'

const props = defineProps<{
  state: string
  rejectedReason?: string
}>()

const { t } = useI18n()

const colorByState = computed(() => {
  switch (props.state) {
    case SurveyResponseStateEnum.Published:
      return 'mint'
    case SurveyResponseStateEnum.Drafted:
      return 'purple'
    case SurveyResponseStateEnum.Rejected:
      return 'error'
    case SurveyResponseStateEnum.Proposed:
      return 'yellow'
    case SurveyResponseStateEnum.Stale:
      return 'default'
    default:
      return 'default'
  }
})

const tooltipContentByState = computed(() => {
  switch (props.state) {
    case SurveyResponseStateEnum.Rejected:
      return t(`Reviews.product.review.rejected.${props.rejectedReason}`, t('Reviews.product.view.table.statusLabel.unknown'))
    case SurveyResponseStateEnum.Proposed:
      return t('Reviews.product.review.proposed.tooltip')
    case SurveyResponseStateEnum.Stale:
      return t('Reviews.product.review.stale.tooltip')
    default:
      return undefined
  }
})
</script>
