<template>
  <div>
    <ErrorNotFound v-if="!loading && !product" />
    <div class="h-1">
      <ProgressBar v-if="loading" />
    </div>
    <div v-if="product">
      <h1 class="text-headline-l">{{ t('Reviews.product.view.headline', { productName: product.title }) }}</h1>
      <BackLink class="mt-4 mb-6" :to="{ name: 'reviewsProducts' }">{{ t('backToOverview') }}</BackLink>
      <RouterView :product="product" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, provide } from 'vue'
import { useRoleRedirect } from '@/services/roleRedirect'
import { useReviewsProductByIdQuery } from '@/gql/reviews'
import ErrorNotFound from '@/views/ErrorNotFound.vue'
import ProgressBar from '@/ui/ProgressBar.vue'
import BackLink from '@/ui/BackLink.vue'
import { useI18n } from 'vue-i18n'
import { reloadReviewsProductKey } from '@/symbols/reloadReviewsProductKey'
import type { ProductData } from '@/gql/reviews'
import { mapTranslationsArrToObj } from '@/helpers/translationsHelper'

const props = defineProps<{
  productId: string
}>()

useRoleRedirect('manageReviews')

const { t } = useI18n()
const variables = computed(() => ({ id: props.productId }))

const { loading, result, refetch } = useReviewsProductByIdQuery(variables, { clientId: 'reviews' })

const product = computed<ProductData | undefined>(() => {
  if (!result.value?.manage?.product) {
    return undefined
  }
  return {
    ...result.value?.manage?.product,
    descriptionTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.descriptionTranslations),
    shortDescriptionTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.shortDescriptionTranslations),
    linkTextTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.linkTextTranslations),
    urlTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.urlTranslations),
    disclaimerTextTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.disclaimerTextTranslations),
    disclaimerUrlTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.disclaimerUrlTranslations),
    noPricingPlansHintTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.noPricingPlansHintTranslations),
    ctaSecondaryLabelTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.ctaSecondaryLabelTranslations),
    ctaSecondaryUrlTranslationsMap: mapTranslationsArrToObj(result.value?.manage?.product?.ctaSecondaryUrlTranslations),
  }
})

provide(reloadReviewsProductKey, refetch)
</script>
