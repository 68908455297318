import { computed } from 'vue'
import type { TicketQuery } from '@/gql/myomr'
export const internalQuotaTypes = ['CrewQuota', 'ExhibitorQuota', 'PressQuota', 'GuestQuota']
export type InternalQuotaTypeEnum = (typeof internalQuotaTypes)[number]
type QuotaConfig = {
  additonalTicketAttributes: TicketAttribute[]
  hasCsvUpload: boolean
  hasJobRelatedAttributes: boolean
  hasPartnerAttributes: boolean
  needsCountry: boolean
  optionalTicketAttributes: TicketAttribute[]
  requiredTicketAttributes: TicketAttribute[]
  respectDeadline: boolean
  showDeadlineExpirationHint: boolean
  showDownloadButton: boolean
  showExportButton: boolean
  showDeactivationFeature: boolean
}
type TicketAttribute = keyof NonNullable<TicketQuery['ticket']>

type QuotaAttributesConfig = { [key in InternalQuotaTypeEnum]: { name: TicketAttribute; required: boolean }[] }
const additonalTicketAttributes: QuotaAttributesConfig = {
  CrewQuota: [
    { name: 'phone', required: false },
    { name: 'birthdate', required: false },
    { name: 'partnerName', required: false },
    { name: 'partnerRole', required: false },
  ],
  ExhibitorQuota: [
    { name: 'title', required: false },
    { name: 'phone', required: true },
  ],
  PressQuota: [],
  GuestQuota: [
    { name: 'title', required: false },
    { name: 'phone', required: true },
  ],
}

function getAllAttributes(type: InternalQuotaTypeEnum) {
  return additonalTicketAttributes[type].map(({ name }) => name)
}
function getOptionalAttributes(type: InternalQuotaTypeEnum) {
  return additonalTicketAttributes[type].filter(({ required }) => !required).map(({ name }) => name)
}
function getRequiredAttributes(type: InternalQuotaTypeEnum) {
  return additonalTicketAttributes[type].filter(({ required }) => required).map(({ name }) => name)
}

const quotaConfigData: { [key in InternalQuotaTypeEnum]: QuotaConfig } = {
  CrewQuota: {
    additonalTicketAttributes: getAllAttributes('CrewQuota'),
    hasCsvUpload: true,
    hasJobRelatedAttributes: false,
    hasPartnerAttributes: true,
    needsCountry: false,
    optionalTicketAttributes: getOptionalAttributes('CrewQuota'),
    requiredTicketAttributes: getRequiredAttributes('CrewQuota'),
    respectDeadline: false,
    showDeadlineExpirationHint: false,
    showDownloadButton: true,
    showExportButton: true,
    showDeactivationFeature: true,
  },
  ExhibitorQuota: {
    additonalTicketAttributes: getAllAttributes('ExhibitorQuota'),
    hasCsvUpload: false,
    hasJobRelatedAttributes: true,
    hasPartnerAttributes: false,
    needsCountry: true,
    optionalTicketAttributes: getOptionalAttributes('ExhibitorQuota'),
    requiredTicketAttributes: getRequiredAttributes('ExhibitorQuota'),
    respectDeadline: true,
    showDeadlineExpirationHint: true,
    showDownloadButton: true,
    showExportButton: true,
    showDeactivationFeature: false,
  },
  GuestQuota: {
    additonalTicketAttributes: getAllAttributes('GuestQuota'),
    hasCsvUpload: false,
    hasJobRelatedAttributes: true,
    hasPartnerAttributes: false,
    needsCountry: true,
    optionalTicketAttributes: getOptionalAttributes('GuestQuota'),
    requiredTicketAttributes: getRequiredAttributes('GuestQuota'),
    respectDeadline: true,
    showDeadlineExpirationHint: true,
    showDownloadButton: true,
    showExportButton: true,
    showDeactivationFeature: false,
  },
  PressQuota: {
    additonalTicketAttributes: getAllAttributes('PressQuota'),
    hasCsvUpload: true,
    hasJobRelatedAttributes: false,
    hasPartnerAttributes: false,
    needsCountry: false,
    optionalTicketAttributes: getOptionalAttributes('PressQuota'),
    requiredTicketAttributes: getRequiredAttributes('PressQuota'),
    respectDeadline: true,
    showDeadlineExpirationHint: false,
    showDownloadButton: true,
    showExportButton: true,
    showDeactivationFeature: true,
  },
}

const useQuotaConfig = (type: InternalQuotaTypeEnum) => computed<QuotaConfig>(() => quotaConfigData[type])
export default useQuotaConfig
