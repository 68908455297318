<template>
  <div class="flex flex-row gap-4 justify-start"><slot></slot></div>
</template>

<script lang="ts">
export default {
  name: 'TabBar',
  inheritAttrs: false,
  customOptions: {},
}
</script>

<script setup lang="ts"></script>
