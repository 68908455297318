<template>
  <div v-if="roles.length" class="separate-rows">
    <div class="p-6 flex justify-between items-center text-center" align="center">
      <!-- Details -->
      <div class="text-left">
        <h3>{{ user.firstName }} {{ user.lastName }}</h3>
        <p class="mb-0">{{ user.email }}</p>
      </div>
      <!-- Actions -->
      <div class="text-right">
        <RouterLink class="btn-text-black-m" :to="{ name: 'editUser', params: { id: idParam } }">
          {{ t('UserManagement.UserItem.editUser') }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import type { User } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  user: User
  forPartnerCompanyId: string
}>()

const idParam = computed(() => toTypeAndId(props.user.id)['id'])
const roles = computed(() => {
  return props.user.roles.filter(
    (role) =>
      role.resourceId === props.forPartnerCompanyId &&
      role.name.includes('manage__') &&
      role.name !== 'lead_scanner' &&
      role.name !== 'manage__lead_scanner',
  )
})
</script>
