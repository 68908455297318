<template>
  <div class="my-6 flex flex-row justify-between items-end">
    <div>
      <h3 class="text-xl font-semibold line-clamp-1" :title="props.title">{{ props.title }}</h3>
      <div class="flex flex-row">
        <div class="flex items-center grow-0 shrink-0">
          <SvgIcon :path="mdiCalendar" :size="24" class="grow-0 mr-2" />
          <div>
            <span>{{ getFormattedDateRange(props.startsAt, props.endsAt) }}</span>
          </div>
        </div>
        <div class="flex items-center grow-0 shrink-0 p-2">
          <SvgIcon :path="mdiAccountMultipleOutline" :size="24" class="grow-0 mr-2" />
          {{ props.leadCount }}
        </div>
      </div>
    </div>
    <div>
      <a :href="downloadUrl" class="btn-secondary-purple-m uppercase p-2 flex items-center grow-0 shrink-0">
        <SvgIcon :path="mdiTrayArrowDown" :size="16" class="grow-0 mr-2" />
        {{ t('EventHeader.downloadLeadsHeadline').toString().replace(' ', '&nbsp;') }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiCalendar, mdiAccountMultipleOutline, mdiTrayArrowDown } from '@mdi/js'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  title: string
  startsAt?: string | null | undefined
  endsAt?: string | null | undefined
  leadCount: number
  downloadUrl: string
}>()

const { t } = useI18n()

import { formatDateSpan, formatDate } from '@/helpers/formatDateSpan'
function getFormattedDateRange(beginningDate: string | null | undefined, endDate: string | null | undefined): string {
  if (beginningDate && endDate) {
    return formatDateSpan({ beginning: new Date(beginningDate), end: new Date(endDate), monthNames: false })
  } else if (beginningDate) {
    return formatDate({ date: new Date(beginningDate), monthNames: false })
  } else {
    return ''
  }
}
</script>
