import { onError } from '@apollo/client/link/error'
import router from '@/router'
import type { ServerError } from '@apollo/client/core'
import errorHandler from '@/helpers/errorHandlerUtility'
import { redirectToLogin } from '@/helpers/loginHelper'
import { useUiStore } from '@/stores'

const link = onError(({ graphQLErrors, networkError }) => {
  const uiStore = useUiStore()

  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'User not authorized') {
        redirectToLogin(uiStore.locale)
        return
      }
      errorHandler.report(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError && (networkError as ServerError).statusCode) {
    const code = (networkError as ServerError).statusCode
    errorHandler.report(networkError.message)

    if (code === 401) {
      redirectToLogin(uiStore.locale)
    } else if (code === 404) {
      router.push({ name: '404' })
    } else if (router.currentRoute.value.name !== 'network-issue') {
      router.push({ name: 'network-issue' })
    }
  }
})

export default link
