<template>
  <div>
    <LandingPageLayout :layout="pageLayout" :components="{}" />
  </div>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import { LandingPageLayout } from '@ramp106/omrjs-hygraph-ui'
import { Locale, useLandingPageBySlugLazyQuery, type LandingPageBySlugQuery } from '@/gql/hygraph/hygraph.generated'
import { useContentPages } from '@/composables/useContentPages'
import type { MeQuery } from '@/gql/myomr'
import { useUiStore } from '@/stores'

const props = defineProps<{
  me: MeQuery['me']
}>()

const route = useRoute()
const { getContentSlug } = useContentPages()

const page = ref<LandingPageBySlugQuery>()

const uiStore = useUiStore()
const requestLocale = uiStore.locale

const contentSlug = computed(() => {
  return getContentSlug.value(route.params.slug as string, !!props.me)
})

const pageLayout = computed(() => {
  return page.value?.landingPage?.pageLayout ?? []
})

const { load, onResult } = useLandingPageBySlugLazyQuery(
  { slug: '', locales: [requestLocale == 'en' ? Locale.En : Locale.De] },
  { clientId: 'hygraph' },
)

onResult((pageData) => {
  page.value = pageData?.data
})

watch(
  () => contentSlug.value,
  (newSlug) => {
    switch (newSlug) {
      case '404': {
        break
      }
      case 'NA': {
        break
      }
      default: {
        load(null, { slug: newSlug, locales: [requestLocale == 'en' ? Locale.En : Locale.De] })
      }
    }
  },
  { immediate: true },
)
</script>
