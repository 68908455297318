import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const defaultPerPage = 10
const defaultPage = 1

export const useRouterQueryPagination = () => {
  const route = useRoute()
  const router = useRouter()

  const page = computed<number>(() => (route.query.page ? Number(route.query.page) : defaultPage))
  const perPage = computed<number>(() => (route.query.perPage ? Number(route.query.perPage) : defaultPerPage))

  function updatePerPage(newPerPage: number) {
    router.push({
      query: {
        ...route.query,
        page: undefined,
        perPage: newPerPage != defaultPerPage ? String(newPerPage) : undefined,
      },
    })
  }

  function updatePage(newPage: number) {
    if (newPage == page.value) return
    router.push({
      query: {
        ...route.query,
        page: newPage != defaultPage ? String(newPage) : undefined,
      },
    })
  }

  return {
    page,
    perPage,
    updatePage,
    updatePerPage,
  }
}
