import { ApplicationPolicy } from '@/policies/ApplicationPolicy'

export abstract class ReviewsPolicy extends ApplicationPolicy {
  public static manageReviews(): boolean {
    return this.hasReviewsManagementAccess()
  }
}

export enum ReviewsAbilities {
  manageReviews = 'manageReviews',
}
