<template>
  <slot :prospect-lists-loading="loading" :prospect-lists-error="error" :prospect-lists-refetch="refetch" :prospect-lists-result="result" />
</template>

<script setup lang="ts">
import type { ApolloError } from '@apollo/client/core'

import { useProspectListsQuery, type ProspectListsQuery, type EventPeriodEnum } from '@/gql/myomr'

const props = defineProps<{
  partnerCompanyId: string
  period: EventPeriodEnum
}>()

const emit = defineEmits<{
  (e: 'onError', error: ApolloError): void
  (e: 'onResultUpdate', result: ProspectListsQuery): void
}>()

const { onResult, onError, loading, error, result, refetch } = useProspectListsQuery(() => ({
  partnerCompanyId: props.partnerCompanyId,
  period: props.period,
}))

onResult(({ data }) => emit('onResultUpdate', data))
onError((errorResponse) => emit('onError', errorResponse))
</script>
