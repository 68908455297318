<template>
  <ContentCard class="mt-5">
    <template #header>
      <slot name="navHeader"></slot>
    </template>

    <div v-if="formData">
      <h2 class="mt-6 mb-8">
        {{ t('Speakers.basicInformation') }}
      </h2>
      <div class="flex">
        <MediaUpload
          accept="image/png, image/jpeg, image/bmp"
          aspect-ratio="1:1"
          class="mt-6 mr-4 w-48"
          max-width="160px"
          :preview-asset="
            props.speaker.photoUrl
              ? {
                  url: props.speaker.photoUrl,
                  contentType: 'image/png',
                }
              : undefined
          "
          :short-hint="'*' + t('Speakers.form.uploadPhoto')"
          :error="v$.photo.$error ? v$.photo.$errors.map((error) => t(error.$message.toString())).join(' ') : undefined"
          @upload="handlePhotoUpload"
        />
        <div class="w-full">
          <div class="grid grid-cols-2 gap-x-4 gap-y-0">
            <TextInput
              v-model="formData.firstname"
              :label="t('Speakers.form.firstName') + '*'"
              :error="getValidationErrors(v$.firstname)"
              @update:model-value="v$.firstname.$validate"
            />
            <TextInput
              v-model="formData.lastname"
              :label="t('Speakers.form.lastName') + '*'"
              :error="getValidationErrors(v$.lastname)"
              @update:model-value="v$.lastname.$validate"
            />
            <TextInput
              v-model="formData.partnerCompanyName"
              :label="t('Speakers.form.company') + '*'"
              :error="getValidationErrors(v$.partnerCompanyName)"
              @update:model-value="v$.partnerCompanyName.$validate"
            />
            <TextInput
              v-model="formData.title"
              :label="t('Speakers.form.jobPosition') + '*'"
              :error="getValidationErrors(v$.title)"
              @update:model-value="v$.title.$validate"
            />
            <TextInput
              v-model="formData.email"
              type="email"
              :label="t('Speakers.form.email') + '*'"
              :error="getValidationErrors(v$.email)"
              @update:model-value="v$.email.$validate"
            />
          </div>
          <TextInput
            id="formData.descriptionEn"
            v-model="formData.descriptionEn"
            type="textarea"
            :counter="MAX_DESCRIPTION_LENGTH"
            :label="t('Speakers.form.shortBioEn') + '*'"
            :error="getValidationErrors(v$.descriptionEn)"
            @update:model-value="v$.descriptionEn.$validate"
          />
          <TextInput
            id="formData.descriptionDe"
            v-model="formData.descriptionDe"
            type="textarea"
            :counter="MAX_DESCRIPTION_LENGTH"
            :label="t('Speakers.form.shortBioDe') + '*'"
            :error="getValidationErrors(v$.descriptionDe)"
            @update:model-value="v$.descriptionDe.$validate"
          />
        </div>
      </div>
      <div>
        <h2 class="mb-4">
          {{ t('Speaker.tags.headline') }}
        </h2>
        <p class="mb-6">
          {{ t('Speaker.tags.hint') }}
        </p>
        <AlertBox v-if="showSelectableInterestLimitAlert" type="warning" class="mt-5">
          {{ t('PartnerCompany.interestsLimitWarning') }}
        </AlertBox>
        <div v-if="interests?.nodes?.length" class="mt-2 chips-list" data-test-id="selectable-interest-tags">
          <SelectableInterest
            v-for="interest in interests.nodes"
            :key="interest.id"
            :interest="interest"
            :selected="isInterestSelected(interest)"
            @toggle-selection="toggleSelection"
          />
        </div>
      </div>
      <div class="mt-10">
        <h2 class="pb-3">
          {{ t('Speakers.form.socialMediaLinks') }}
        </h2>
        <div class="columns-2 gap-x-8 gap-y-0 w-full pb-10">
          <div v-for="{ name, label, iconPath } in socialMediaLinks" :key="name" class="flex items-center">
            <TextInput
              v-model="formData[name]"
              type="url"
              :label="label"
              :error="getValidationErrors(v$[name])"
              :append-icon="iconPath"
              @update:model-value="v$[name].$validate"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="text-right">
        <RouterLink class="btn-text-purple-m mr-4" :to="{ name: 'speakerList' }">
          {{ t('cancel') }}
        </RouterLink>

        <button class="btn-primary-purple-m" @click.prevent="submit">
          <span v-if="!isUploading && uploadProgress == 0">
            {{ t('saveButtonLabel') }}
          </span>
          <span v-if="isUploading && 100 > uploadProgress && uploadProgress > 0">
            {{ uploadProgress }}% {{ t('EventForm.uploaded') }}
          </span>
          <span v-if="isUploading && uploadProgress == 100">
            {{ t('EventForm.processing') }}
          </span>
        </button>
      </div>
    </template>
  </ContentCard>
</template>

<script setup lang="ts">
import { computed, ref, reactive, onMounted } from 'vue'
import { useInterestsQuery } from '@/gql/myomr'
import type { InterestsQuery, Speaker, SpeakerAttributes } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { SelectableInterest } from '@/components'
import MediaUpload from '@/ui/MediaUpload.vue'
import { TextInput } from '@ramp106/omrjs-core-ui'
import { getValidationErrors } from '@/helpers/validationHelper'

import { mdiTwitter, mdiYoutube, mdiWeb, mdiFacebook, mdiInstagram, mdiLinkedin } from '@mdi/js'

import { useI18n } from 'vue-i18n'

import useVuelidate from '@vuelidate/core'
import { email, required, requiredUnless, url, maxLength } from '@/services/validators'
import { imageSquareRule, imageMinSizeRule, imageMaxSizeRule } from '@/helpers/validationHelper'

type Interest = InterestsQuery['interests']['nodes'][number]

const i18n = useI18n()
const t = i18n.t

const props = defineProps<{
  speaker: Speaker
  uploadProgress: number
  isUploading: boolean
}>()
const emit = defineEmits<{
  (e: 'submitForm', value: SpeakerAttributes): void
}>()

const { result: interestsResult } = useInterestsQuery()

type FormDataType = Omit<Required<SpeakerAttributes>, 'description' | 'position' | 'seoDescription' | 'seoTitle' | 'interests'> & {
  interests: string[]
}
const formData = reactive<FormDataType>({
  descriptionDe: '',
  descriptionEn: '',
  email: '',
  facebookUrl: '',
  firstname: '',
  instagramUrl: '',
  interests: [],
  lastname: '',
  linkedinUrl: '',
  name: '',
  partnerCompanyName: '',
  photo: null,
  title: '',
  twitterUrl: '',
  websiteUrl: '',
  youtubeUrl: '',
})

const MAX_INTERESTS = 4
const NOTIFICATION_MILLISECONDS = 5000
const MAX_DESCRIPTION_LENGTH = 750
const MIN_PROFILE_PHOTO_HEIGHT = 400
const MIN_PROFILE_PHOTO_WIDTH = 400
const MAX_PROFILE_PHOTO_HEIGHT = 1200
const MAX_PROFILE_PHOTO_WIDTH = 1200

const speakerHasPhoto = computed(() => !!props.speaker.photoUrl)

const rules = {
  descriptionDe: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  descriptionEn: { required, maxLength: maxLength(MAX_DESCRIPTION_LENGTH) },
  email: { required, email },
  facebookUrl: { url },
  firstname: { required },
  instagramUrl: { url },
  lastname: { required },
  linkedinUrl: { url },
  partnerCompanyName: { required },
  photo: {
    requiredIfPhotoUrlNotPresent: requiredUnless(speakerHasPhoto),
    imageSquareRule,
    imageMinSize: imageMinSizeRule(MIN_PROFILE_PHOTO_WIDTH, MIN_PROFILE_PHOTO_HEIGHT),
    imageMaxSize: imageMaxSizeRule(MAX_PROFILE_PHOTO_WIDTH, MAX_PROFILE_PHOTO_HEIGHT),
  },
  title: { required },
  twitterUrl: { url },
  websiteUrl: { url },
  youtubeUrl: { url },
}
const v$ = useVuelidate(rules, formData)

const socialMediaLinks = [
  { name: 'twitterUrl', label: 'Twitter link', iconPath: mdiTwitter },
  { name: 'youtubeUrl', label: 'Youtube link', iconPath: mdiYoutube },
  { name: 'websiteUrl', label: 'Website link', iconPath: mdiWeb },
  { name: 'facebookUrl', label: 'Facebook link', iconPath: mdiFacebook },
  { name: 'instagramUrl', label: 'Instagram link', iconPath: mdiInstagram },
  { name: 'linkedinUrl', label: 'LinkedIn link', iconPath: mdiLinkedin },
] as const

const interests = computed(() => interestsResult.value?.interests)

const showSelectableInterestLimitAlert = ref(false)

function handlePhotoUpload(file: File) {
  v$.value.photo.$model = file
}

function toggleSelection({ interest, selected }: { interest: Interest; selected: boolean }) {
  if (selected) {
    if (formData.interests.length < MAX_INTERESTS) {
      formData.interests.push(interest.id)
    } else {
      showSelectableInterestLimitAlert.value = true
      setTimeout(() => (showSelectableInterestLimitAlert.value = false), NOTIFICATION_MILLISECONDS)
    }
  } else {
    // remove
    let index = formData.interests.indexOf(interest.id)
    if (index > -1) {
      formData.interests.splice(index, 1)
    }
  }
}

function isInterestSelected(interest: Interest) {
  return (formData.interests || []).includes(interest.id)
}

async function submit() {
  if (await v$.value.$validate()) {
    // Remove null values before submitting:
    emit('submitForm', Object.fromEntries(Object.entries(formData).filter(([_, v]) => v != null)))
  } else {
    window.scrollTo(0, 0)
    showNotification(
      t('BoothExtras.validationError')
        .toString()
        .replace(/<\/?[^>]+>/gi, ' '),
      'error',
    )
  }
}

function initializeFormData() {
  if (props.speaker) {
    const formDataAttributes = (({
      name,
      firstname,
      lastname,
      descriptionEn,
      descriptionDe,
      title,
      email,
      partnerCompanyName,
      twitterUrl,
      facebookUrl,
      instagramUrl,
      linkedinUrl,
      youtubeUrl,
      websiteUrl,
    }) => ({
      name,
      firstname,
      lastname,
      descriptionEn,
      descriptionDe,
      title,
      email,
      partnerCompanyName,
      twitterUrl,
      facebookUrl,
      instagramUrl,
      linkedinUrl,
      youtubeUrl,
      websiteUrl,
    }))(props.speaker)

    Object.assign(formData, formDataAttributes)

    if (props.speaker.interests) {
      formData.interests = props.speaker.interests.map((interest) => interest.id)
    }
  }
}
onMounted(() => initializeFormData())
</script>
