<template>
  <table class="table-light">
    <slot />
  </table>
</template>

<style lang="css">
.table-light,
.table-light th,
.table-light td {
  @apply border-t-0 border-l-0 border-r-0 border-b border-b-grey-300;
}

.table-light thead th {
  @apply p-3 py-4 text-[12px] leading-[1.5] font-bold text-grey-900;
}
</style>
