import { ApplicationPolicy } from '@/policies/ApplicationPolicy'

export abstract class QuotaPolicy extends ApplicationPolicy {
  public static sendInvitations(): boolean {
    return this.hasEventsInviteesAccess()
  }

  public static editTemplate(): boolean {
    return this.sendInvitations()
  }

  public static sendTestEmail(): boolean {
    return this.sendInvitations()
  }

  public static uploadCsv(): boolean {
    return this.sendInvitations()
  }

  public static deleteUnsentInvitees(): boolean {
    return this.sendInvitations()
  }

  public static downloadInvitees(): boolean {
    return this.sendInvitations()
  }

  public static sendSingleInvite(): boolean {
    return this.sendInvitations()
  }
}

export enum QuotaAbilities {
  editTemplate = 'editTemplate',
  sendTestEmail = 'sendTestEmail',
}
