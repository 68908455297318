<template>
  <div class="mt-4">
    <ConfirmModal v-if="popupToggle" wrapper-class="max-w-2xl" :title="title" :show-approve="false" @close-dialog="popupToggle = false">
      <template #content>
        <div class="px-6 pb-4" v-html="bodyHtml"></div>
      </template>
    </ConfirmModal>

    <div class="card p-4 mb-4 flex">
      <div v-if="props.newsItem.imageUrl">
        <img :src="props.newsItem.imageUrl" class="max-w-[137px] mr-7" />
      </div>
      <div class="flex flex-col justify-between">
        <div>
          <p class="text-headline-s font-bold pb-3">{{ title }}</p>
          <div class="newsBody" v-html="bodyHtml"></div>
        </div>
        <div>
          <div class="btn-ghost-mint-m" @click.stop="popupToggle = true">
            {{ i18n.t('readMore') }}
          </div>
          <a v-if="props.newsItem.ctaButtonLink && ctaLabel" class="btn-ghost-black-m" :href="props.newsItem.ctaButtonLink" target="_blank">
            {{ ctaLabel }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { News } from '@/gql/myomr'
import { getLocalizedAttribute } from '@/helpers/localizedAttribute'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

const props = defineProps<{
  newsItem: News
}>()

const popupToggle = ref(false)

const title = computed(() =>
  getLocalizedAttribute({
    de: props.newsItem.titleDe,
    en: props.newsItem.titleEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)

const bodyHtml = computed(() =>
  getLocalizedAttribute({
    de: props.newsItem.bodyHtmlDe,
    en: props.newsItem.bodyHtmlEn,
    fallback: '',
    locale: i18n.locale.value,
  }),
)

const ctaLabel = computed(() => {
  if (props.newsItem.ctaButtonLink) {
    return getLocalizedAttribute({
      de: props.newsItem.ctaButtonTextDe,
      en: props.newsItem.ctaButtonTextEn,
      fallback: '',
      locale: i18n.locale.value,
    })
  } else {
    return null
  }
})
</script>

<style lang="scss" scoped>
.newsBody {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
