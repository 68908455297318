<template>
  <div>
    <ContentCard>
      <template #header>
        <h3 class="my-6">Discount {{ props.invitationQuota.discount }}%</h3>
      </template>

      <InvitationQuotaSubHeader :invitation-quota="props.invitationQuota" />
      <InvitationQuota :invitation-quota="props.invitationQuota" />
    </ContentCard>
  </div>
</template>

<script setup lang="ts">
import type { InvitationQuota as InvitationQuotaType } from '@/gql/myomr'
import InvitationQuota from './InvitationQuota.vue'
import InvitationQuotaSubHeader from './InvitationQuotaSubHeader.vue'

const props = defineProps<{
  invitationQuota: InvitationQuotaType
  loading: boolean
}>()
</script>
