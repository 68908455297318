<template>
  <div
    :key="props.interest.id"
    class="chip cursor-pointer"
    label
    :class="selected ? 'bg-black text-white' : 'bg-white text-black outline'"
    :data-test-id="`interest_${interest.id}`"
    :data-test-selected="props.selected || null"
    @click="toggleSelection"
  >
    {{ interest.name }}
  </div>
</template>

<script setup lang="ts">
import type { InterestsQuery } from '@/gql/myomr'
type Interest = InterestsQuery['interests']['nodes'][number]

const props = defineProps<{
  interest: Interest
  selected: boolean
}>()

const emit = defineEmits<{
  (e: 'toggleSelection', value: { interest: Interest; selected: boolean }): void
}>()

function toggleSelection() {
  emit('toggleSelection', { interest: props.interest, selected: !props.selected })
}
</script>
