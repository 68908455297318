<template>
  <div>
    <h1>Oops!</h1>
    <h3>{{ t('ErrorViews.ErrorUnauthorized.message') }}</h3>
    <a :href="loginUrl(locale)">
      {{ t('ErrorViews.linkToRootLabel') }}
    </a>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { loginUrl } from '@/config/env'

const { locale, t } = useI18n()

onMounted(async () => {})
</script>
