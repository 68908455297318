import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

import { reviewsGraphqlUrl } from '@/config/env'
import { uploadFetch } from './uploadFetch'

export const reviewsHttpLink = createUploadLink({
  credentials: 'include',
  uri: reviewsGraphqlUrl,
  fetch: uploadFetch,
})
