export type TabEnum = 'activated' | 'requests' | 'deactivated'
import { computed } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export const getCurrentActiveTab = function (route: RouteLocationNormalizedLoaded) {
  return computed<TabEnum>(() => {
    if (route.query.tab == 'requests') return 'requests'
    if (route.query.tab == 'deactivated') return 'deactivated'
    return 'activated'
  })
}
