<template>
  <div>
    <h1 class="pb-7">{{ t('ErrorViews.NetworkIssue.title') }}</h1>
    <h3>{{ t('ErrorViews.NetworkIssue.message') }}</h3>
    <RouterLink :to="{ name: 'root' }">
      {{ t('ErrorViews.linkToRootLabel') }}
    </RouterLink>
  </div>
</template>

<script lang="ts">
export default { name: 'NetworkIssue' }
</script>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
