<template>
  <div>
    <ManualContactModal
      v-if="showManualContactModal"
      :title="t('EmailInvitationQuota.manualContactUpload.title')"
      :description="t('EmailInvitationQuota.manualContactUpload.description')"
      :single-invites="singleInvites"
      @close-dialog="emit('closeDialog')"
      @save-guests="saveGuests"
      @next-step="openTemplateModal"
    />
    <ManualContactTemplateModal
      v-else
      :title="t('EmailInvitationQuota.manualContactTemplate.title')"
      :description="t('EmailInvitationQuota.manualContactTemplate.description', { numberOfGuests: singleInvites.length })"
      :invitation-quota="invitationQuota"
      @go-back="openContactModal"
      @send-email="sendEmails"
    />
  </div>
</template>

<script lang="ts" setup>
// Composition API
import { ref, type Ref } from 'vue'

import {
  useCreateIndividualInviteesMutation,
  useSendIndividualInvitationsMutation,
  type InvitationQuota,
  type InviteeInput,
  type SendIndividualInvitationsInput,
} from '@/gql/myomr'

// Helpers
import { handleMutationResponse } from '@/helpers/handleMutationResponseHelper'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { mutate: createIndividualInviteesMutation } = useCreateIndividualInviteesMutation()
const { mutate: sendIndividualInvitationsMutation } = useSendIndividualInvitationsMutation()

const singleInvites: Ref<Array<InviteeInput & { key?: number }>> = ref([])
const showManualContactModal = ref(true)

const emit = defineEmits(['closeDialog'])

const props = defineProps<{
  invitationQuota: InvitationQuota
}>()

function openTemplateModal(payload: Array<InviteeInput & { key?: number }>) {
  singleInvites.value = payload
  showManualContactModal.value = false
}

function openContactModal() {
  showManualContactModal.value = true
}

async function saveGuests(contacts: Array<InviteeInput & { key?: number }>, showMessage = true): Promise<boolean> {
  contacts.forEach((contact) => {
    delete contact.key
  })
  let success = true
  await createIndividualInviteesMutation({
    invitationQuotaId: props.invitationQuota.id,
    invitees: contacts,
  })
    .then((result) => {
      if (showMessage)
        handleMutationResponse(
          t,
          { errors: result?.data?.createIndividualInvitees?.errors || [] },
          t('EmailInvitationQuota.manualContactTemplate.notifications.savedGuests').toString(),
        )
    })
    .catch((_e) => {
      success = false
      handleMutationResponse(t, { errors: [t('EmailInvitationQuota.manualContactTemplate.notifications.savedGuestsError').toString()] })
    })
  if (showMessage) emit('closeDialog')
  return success
}

async function sendEmails(emailInput: SendIndividualInvitationsInput) {
  const guestsSaved = await saveGuests(singleInvites.value, false)
  if (!guestsSaved) {
    emit('closeDialog')
    return
  }

  const individualEmails = singleInvites.value.map((item) => item.email)
  let individualInvitationsInput: SendIndividualInvitationsInput = {
    emails: individualEmails,
    invitationQuotaId: props.invitationQuota.id,
    messageSubject: emailInput.messageSubject,
    messageBody: emailInput.messageBody,
    replyTo: emailInput.replyTo,
  }

  if (emailInput.cc) individualInvitationsInput.cc = emailInput.cc

  await sendIndividualInvitationsMutation(individualInvitationsInput).then((result) => {
    handleMutationResponse(
      t,
      result?.data?.sendIndividualInvitations,
      t('EmailInvitationQuota.manualContactTemplate.notifications.sentInvitations').toString(),
    )
  })
  emit('closeDialog')
}
</script>
