<template>
  <div class="my-6 flex flex-row justify-between items-center min-h-10">
    <h2 class="text-headline-m">
      {{ t('Reviews.product.view.reviewsTitle', { productName: product.title }) }}
      <span class="text-body-m ml-1">{{ t('Reviews.product.view.reviewsTitleHint', { count }) }}</span>
    </h2>
  </div>
</template>
<script setup lang="ts">
import { type ProductData, useReviewsProductSurveyResponsesCountQuery } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps<{
  product: Pick<ProductData, 'id' | 'slug' | 'title'>
}>()

const { t } = useI18n()

const variables = computed(() => ({ slug: props.product.slug }))
const { result } = useReviewsProductSurveyResponsesCountQuery(variables, { clientId: 'reviews' })
const count = computed(() => result.value?.manage?.surveyResponses?.pagination.totalCount)
</script>
