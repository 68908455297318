<template>
  <div class="flex bg-white p-2 rounded-[4px] border border-grey-300">
    <img v-if="props.speaker.photoUrl" :src="props.speaker.photoUrl" class="w-[104px]" />
    <div class="ml-4 flex flex-col justify-between">
      <div>
        <p class="text-headline-s mb-1">{{ props.speaker.name }}</p>
        <p class="text-body-s text-grey-800">
          {{ props.speaker.title ? `${props.speaker.title} ·` : '' }} {{ props.speaker.partnerCompanyName || '' }}
        </p>
        <p v-if="showNoEventsNotification" class="text-amaranth-900 text-body-s-medium inline-flex items-center">
          <SvgIcon :path="mdiAlertCircle" class="mr-2" :size="22" />{{ t('Speakers.form.errors.noEventsAssigned') }}
        </p>
      </div>
      <div>
        <RouterLink
          v-if="isUpdatable"
          class="text-victoria text-button-s font-bold mr-4 inline-flex items-center"
          :to="{
            name: 'editSpeaker',
            params: { speakerId },
          }"
        >
          <SvgIcon class="mr-1" :path="mdiPencil" :size="16" />
          {{ t('Speakers.editSpeaker') }}
        </RouterLink>
        <template v-if="getSpeakerConfig['enableEventAssigning']">
          <RouterLink
            v-if="isUpdatable"
            class="text-victoria text-button-s font-bold mr-4 inline-flex items-center"
            :to="{
              name: 'editSpeakerEvents',
              params: { speakerId },
            }"
          >
            <SvgIcon class="mr-1" :path="mdiLink" :size="16" />
            {{ t('Speakers.assignEvent') }}
          </RouterLink>
          <RouterLink
            v-if="!isUpdatable"
            class="text-victoria text-button-s font-bold mr-4 inline-flex items-center"
            :to="{
              name: 'viewSpeaker',
              params: { speakerId },
            }"
          >
            <SvgIcon class="mr-1" :path="mdiLink" :size="16" />
            {{ t('Speakers.assignEvent') }}
          </RouterLink>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { mdiPencil, mdiLink, mdiAlertCircle } from '@mdi/js'
import type { Speaker } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { useMeStore } from '@/stores'
import getSpeakerConfig from './getSpeakerConfig'

const { t } = useI18n()
const meStore = useMeStore()

const props = defineProps<{
  speaker: Speaker
}>()

const MIN_NEW_ACCOUNT_TIMESTAMP = new Date().getTime() - 60 * 24 * 60 * 60 * 1000
//                                                    day hour  min  sec  msec

const isSpeakerNew = computed(() => {
  return new Date(props.speaker.createdAt).getTime() > MIN_NEW_ACCOUNT_TIMESTAMP
})

const showNoEventsNotification = computed(() => {
  return isUpdatable.value && !props.speaker.eventSpeakers?.length && isSpeakerNew.value
})

const speakerId = computed(() => toTypeAndId(props.speaker.id)['id'])

const isUpdatable = computed(() => {
  if (!meStore.currentPartnerCompanyId || props.speaker.editorialFlag) return false

  const currentPartnerCompanyId = meStore.currentPartnerCompanyId.toString()
  const speakerPartnerCompany = props.speaker.partnerCompany

  if (speakerPartnerCompany) {
    return speakerPartnerCompany.id.endsWith(currentPartnerCompanyId)
  } else {
    return false
  }
})
</script>
