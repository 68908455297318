import { setContext } from '@apollo/client/link/context'
import { useUiStore } from '@/stores'

const authLink = setContext((_, { headers }) => {
  const uiStore = useUiStore()
  const requestLocale = uiStore.locale

  return {
    headers: {
      ...headers,
      'request-locale': requestLocale,
      'omr-service-name': 'eventmanager',
    },
  }
})

export default authLink
