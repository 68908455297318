<template>
  <div class="flex justify-between justify-items-center py-8">
    <span>
      <h4>Tickets ({{ promoCodesCount }})</h4>
      {{ promoCodesAvailableCount }}/{{ promoCodesCount }}
      {{ t('InvitationQuotaSubHeader.available') }}
    </span>
    <a :download="csvFileName" :href="csvDownload" target="_blank" class="btn-secondary-black-m">
      <SvgIcon :path="mdiDownloadMultiple" class="mr-2" />
      {{ t('InvitationQuotaSubHeader.downloadTable') }}
    </a>
  </div>
</template>

<script lang="ts">
export default { name: 'InvitationQuotaSubHeader' }
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { formatAsCSV } from '@/helpers/CsvHelper'
import type { InvitationQuota } from '@/gql/myomr'
import { mdiDownloadMultiple } from '@mdi/js'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  invitationQuota: InvitationQuota
}>()

const promoCodesCount = computed(() => props.invitationQuota.promoCodes?.length)
const promoCodesAvailable = computed(() => props.invitationQuota.promoCodes?.filter((code) => code.usable))
const promoCodesAvailableCount = computed(() => promoCodesAvailable.value?.length)
const csv = computed(() => formatAsCSV(props.invitationQuota))
const csvDownload = computed(() => `data:application/octet-stream,${csv.value}`)
const csvFileName = computed(() => `Ticket Codes ${event.value?.slug || ''}.csv`)
const event = computed(() => props.invitationQuota.event)
</script>
