<template>
  <component
    :is="getQuotaComponent(props.invitationQuota.kind)"
    :invitation-quota="props.invitationQuota"
    :loading="props.loading"
    :is-limit-reached="isLimitReached"
  >
    <RouterView :invitation-quota="props.invitationQuota" :loading="props.loading" :is-limit-reached="isLimitReached" />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { InvitationQuotaTypeEnum, type InvitationQuotaQuery } from '@/gql/myomr'
import { CodeQuota, InternalQuotaDataProvider, GuestQuotaDataProvider } from '.'

const LEGACY_GUEST_QUOTA_DATE = new Date('2024-05-12')

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
  loading: boolean
}>()

const quotaComponents = {
  [InvitationQuotaTypeEnum.CodeQuota]: CodeQuota,
  [InvitationQuotaTypeEnum.CrewQuota]: InternalQuotaDataProvider,
  [InvitationQuotaTypeEnum.ExhibitorQuota]: InternalQuotaDataProvider,
  [InvitationQuotaTypeEnum.GuestQuota]: GuestQuotaDataProvider,
  [InvitationQuotaTypeEnum.PressQuota]: InternalQuotaDataProvider,
}

function getQuotaComponent(quotaType: InvitationQuotaTypeEnum) {
  if (quotaType == InvitationQuotaTypeEnum.GuestQuota) {
    return new Date(props.invitationQuota.createdAt) > LEGACY_GUEST_QUOTA_DATE ? InternalQuotaDataProvider : GuestQuotaDataProvider
  }

  return quotaComponents[quotaType]
}

const isLimitReached = computed(() => (props.invitationQuota.usableTicketsCount || 0) >= (props.invitationQuota.maxUsable || 0))
</script>
