import { computed, reactive } from 'vue'
import { useTicketCountsQuery } from '@/gql/myomr'
import { aasmStateByTab, sourceByTab, activatedStateByTab } from './dynamicQueryVars'

export const ticketCounts = function (invitationQuotaId: string) {
  const { result: activatedCountResult, refetch: refetchActivated } = useTicketCountsQuery({
    aasmState: aasmStateByTab.activated,
    activated: activatedStateByTab.activated,
    invitationQuotaId: invitationQuotaId,
    source: sourceByTab.activated,
  })
  const { result: deactivatedCountResult, refetch: refetchDeactivated } = useTicketCountsQuery({
    aasmState: aasmStateByTab.deactivated,
    activated: activatedStateByTab.deactivated,
    invitationQuotaId: invitationQuotaId,
    source: sourceByTab.deactivated,
  })
  const { result: requestedCountResult, refetch: refetchRequested } = useTicketCountsQuery({
    aasmState: aasmStateByTab.requests,
    invitationQuotaId: invitationQuotaId,
    source: sourceByTab.requests,
  })
  const { result: swappedCountResult, refetch: refetchSwapped } = useTicketCountsQuery({
    aasmState: [],
    invitationQuotaId: invitationQuotaId,
    source: [],
    swapped: true,
  })

  async function refetchCounts() {
    refetchActivated()
    refetchDeactivated()
    refetchRequested()
    refetchSwapped()
  }

  const activatedCount = computed(() => activatedCountResult.value?.tickets.totalCount || 0)
  const deactivatedCount = computed(() => deactivatedCountResult.value?.tickets.totalCount || 0)
  const requestedCount = computed(() => requestedCountResult.value?.tickets.totalCount || 0)
  const swappedCount = computed(() => swappedCountResult.value?.tickets.totalCount || 0)

  return {
    refetch: refetchCounts,
    countData: reactive({
      activated: activatedCount,
      deactivated: deactivatedCount,
      requested: requestedCount,
      swapped: swappedCount,
    }),
  }
}

export type TicketCounts = ReturnType<typeof ticketCounts>
