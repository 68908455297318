<template>
  <div>
    <BackLink class="mt-4 mb-6" :to="{ name: 'EventAttendees' }">{{ t('back') }}</BackLink>
    <div class="bg-white p-5">
      <p class="text-headline-m">
        {{ t('Applicants.manualApplicantUpload.title') }}
      </p>
      <p class="my-3">
        {{ t('Applicants.manualApplicantUpload.description') }}
      </p>
      <div class="mt-7">
        <a :href="downloadUrl" target="_blank" class="btn-primary-purple-m uppercase mr-6">
          <SvgIcon :path="mdiDownload" :size="18" class="mr-2" />
          {{ t('Applicants.manualApplicantUpload.downloadCTA') }}
        </a>
        <input ref="uploadButton" type="file" accept="text/csv" class="hidden" @change="updateAttendees" />
        <button class="btn-primary-purple-m uppercase" @click.prevent="uploadButton?.click()">
          <SvgIcon :path="mdiUpload" :size="18" class="mr-2" />
          {{ t('Applicants.manualApplicantUpload.uploadCTA') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { mdiDownload, mdiUpload } from '@mdi/js'
import { useI18n } from 'vue-i18n'
const { locale, t } = useI18n()
import { eventApplicantsDownloadUrl } from '@/services/ApiService'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import type { EventQuery } from '@/gql/myomr'
import { useRouter } from 'vue-router'
import { useUpdateAttendeesStateFromCsvMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'

const router = useRouter()

const uploadProgress = ref(0)
const file = ref<File>()
const uploadButton = ref<HTMLInputElement | null>()

export interface UpdateCsvAttendeeValue {
  approved: number
  rejected: number
  failed: number
}

const { mutate, onDone, onError } = useUpdateAttendeesStateFromCsvMutation({
  context: {
    fetchOptions: {
      useUpload: true,
      onProgress: (progress: ProgressEvent) => {
        let loadedProgress = Math.round((progress.loaded / progress.total) * 100)
        uploadProgress.value = loadedProgress
      },
    },
  },
})

const emit = defineEmits<{
  (e: 'updateCsvAttendeeNotification', value: UpdateCsvAttendeeValue): void
}>()

const props = defineProps<{
  event: EventQuery['eventById']
}>()

const downloadUrl = computed(() => {
  const id = props.event && toTypeAndId(props.event.id)['id']
  if (id != null) {
    return eventApplicantsDownloadUrl(id, locale.value)
  } else {
    return ''
  }
})

function updateAttendees(e: Event) {
  if (!(e.target instanceof HTMLInputElement)) return
  const target = e.target as HTMLInputElement
  if (!target.files) return
  file.value = target.files[0]

  if (!file.value) return

  mutate({
    eventId: props.event.id,
    csvFile: file.value,
  })
  return
}

onDone((result) => {
  uploadProgress.value = 0
  const resultData = result?.data?.updateAttendeesStateFromCsv
  if (!resultData) return
  emit('updateCsvAttendeeNotification', {
    approved: resultData.accepted || 0,
    rejected: resultData.denied || 0,
    failed: resultData.failed || 0,
  })
  router.push({ name: 'EventAttendees' })
})

onError((error) => {
  console.log(error)
  uploadProgress.value = 0
  if (error) showNotification(error.message, 'error')
})
</script>
