<template>
  <div>
    <div class="text-label-m text-grey-600 -mt-4">
      {{
        t('Reviews.product.edit.documents.uploadHint', {
          maxNumberOfFiles: MAX_NUMBER_OF_DOCUMENTS,
        })
      }}
    </div>
    <div class="text-label-m flex items-center text-grey-900 my-3">
      <MdiSvg :path="mdiInformationOutline" :size="24" class="text-purple-600 mr-2" />
      {{ t('Reviews.product.edit.documents.sortHint') }}
    </div>
    <div class="grid grid-cols-4 gap-6">
      <div
        v-for="(doc, index) in editableDocs"
        :key="index"
        class="relative"
        :draggable="doc.deleted ? 'false' : 'true'"
        @dragstart="onDragStart($event, index)"
        @dragover.prevent
        @drop="onDrop(index)"
      >
        <DocumentCard
          class="relative mb-6"
          :class="{ 'opacity-20': doc.deleted }"
          :size="doc.size"
          :name="doc.name[locale] || doc.name[ALLOWED_LOCALES[0]]"
          :content-type="doc.contentType"
        />
        <TextInput
          v-for="lang in ALLOWED_LOCALES"
          :key="lang"
          v-model="doc.name[lang]"
          :class="{ 'opacity-20': doc.deleted }"
          :disabled="doc.deleted"
          :max-length="NAME_MAX_LENGTH"
          @change="doc.updated = true"
        >
          <template #label>
            <div class="flex justify-between items-end">
              <span>Name</span>
              <ChipBadge class="uppercase" rounded>{{ lang }}</ChipBadge>
            </div>
          </template>
        </TextInput>
        <button
          class="btn-text-black-m"
          :disabled="doc.deleted && nonDeletedDocs.length >= MAX_NUMBER_OF_DOCUMENTS"
          @click="toggleDelete(doc)"
        >
          <MdiSvg class="mr-2" :path="doc.deleted ? mdiArrowULeftTop : mdiDelete" />
          {{ doc.deleted ? t('Reviews.product.edit.screenshots.restore') : t('Reviews.product.edit.screenshots.delete') }}
        </button>
      </div>
      <MediaUpload
        v-if="nonDeletedDocs.length < MAX_NUMBER_OF_DOCUMENTS"
        ref="fileInput"
        class="w-full col-start-1"
        :accept="docMimeTypes.join(', ')"
        aspect-ratio="1:1"
        :error="getValidationErrors(v$.document)"
        :hide-preview="true"
        :short-hint="uploaderHints"
        @upload="handleDocsUpload"
      />
    </div>
    <div class="grid grid-cols-4"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, watch, nextTick } from 'vue'
import { MdiSvg, TextInput, ChipBadge } from '@ramp106/omrjs-core-ui'
import { DocumentCard } from '@ramp106/omrjs-reviews-ui'
import MediaUpload from '@/ui/MediaUpload.vue'
import { mdiArrowULeftTop, mdiDelete, mdiInformationOutline } from '@mdi/js'
import type { ProductById } from '@/gql/reviews'
import type { ReviewsProductDocumentEditData } from '@/components/ReviewsManagement/types'
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { fileMaxSizeRule, fileMimeTypeRule, getValidationErrors } from '@/helpers/validationHelper'
import { ALLOWED_LOCALES, mapTranslationsArrToObj } from '@/helpers/translationsHelper'

const props = defineProps<{
  documents: NonNullable<ProductById['documents']>
}>()

const emit = defineEmits<{
  (e: 'change', value: ReviewsProductDocumentEditData[]): void
}>()

const NAME_MAX_LENGTH = 80
const MAX_NUMBER_OF_DOCUMENTS = 10
const MAX_FILE_MB_SIZE = 10

const { t, locale } = useI18n()
const newDoc = ref<File | null>(null)
const fileInput = ref()

const docMimeTypes = ['application/pdf']
const rules = computed(() => ({
  document: {
    fileSize: fileMaxSizeRule(t, { megaBytes: MAX_FILE_MB_SIZE }),
    fileType: fileMimeTypeRule(t, { mimeTypes: docMimeTypes }),
  },
}))

const uploaderHints = computed(() => {
  const acceptedMimeTypes = docMimeTypes.map((type) => `${type.split('/')[1]}`).join(', ')
  return [acceptedMimeTypes, `Max. ${MAX_FILE_MB_SIZE} MB`]
})

const v$ = useVuelidate(rules, { document: newDoc })
const editableDocs = ref<ReviewsProductDocumentEditData[]>([])
const draggableDoc = ref<ReviewsProductDocumentEditData | null>(null)
const nonDeletedDocs = computed(() => editableDocs.value.filter((s) => !s.deleted))

async function handleDocsUpload(file: File) {
  if (!file) {
    return
  }
  newDoc.value = file

  await nextTick()
  if (v$.value.document.$invalid) {
    return
  }

  const data: ReviewsProductDocumentEditData = {
    id: null,
    contentType: file.type,
    size: file.size,
    name: ALLOWED_LOCALES.reduce((acc, locale) => ({ ...acc, [locale]: file.name }), {}),
    url: URL.createObjectURL(file),
    file,
    updated: false,
    deleted: false,
  }
  editableDocs.value.splice(editableDocs.value.length + 1, 0, data)
  fileInput.value?.reset()
}

const toggleDelete = (doc: ReviewsProductDocumentEditData) => {
  if (!doc.id) {
    const index = editableDocs.value.findIndex((s) => s === doc)
    URL.revokeObjectURL(doc.url)
    editableDocs.value.splice(index, 1)
    updatePositions()
    return
  }

  doc.deleted = !doc.deleted
}

function onDragStart(event: DragEvent, index: number) {
  if (event.dataTransfer && event.target) {
    event.dataTransfer.effectAllowed = 'move'
  }
  draggableDoc.value = editableDocs.value[index]
}

function onDrop(index: number) {
  if (!draggableDoc.value) {
    return
  } else if (!draggableDoc.value.deleted) {
    moveDoc(draggableDoc.value, index)
  }
  draggableDoc.value = null
}

function moveDoc(doc: ReviewsProductDocumentEditData, index: number) {
  const fromIndex = editableDocs.value.findIndex((s) => s === doc)
  const element = editableDocs.value.splice(fromIndex, 1)[0]
  editableDocs.value.splice(index, 0, element)
  updatePositions()
}

function updatePositions() {
  nonDeletedDocs.value.forEach((doc, index) => {
    doc.position = index + 1
    doc.updated = true
  })
}

onMounted(() => {
  editableDocs.value = props.documents.map((document) => ({
    id: document.id ?? null,
    contentType: document.contentType,
    name: { ...mapTranslationsArrToObj(document.nameTranslations) },
    url: document.url,
    size: document.size,
    updated: false,
    deleted: false,
  }))
})

watch(
  () => editableDocs.value,
  () => {
    emit('change', editableDocs.value)
  },
  { deep: true },
)
</script>
