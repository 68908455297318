<template>
  <DropDownSelect>
    <template #activator="{ open }">
      <button
        class="flex gap-1 p-2 border border-solid rounded border-grey-700 items-center text-grey-700 text-body-s"
        @click.prevent="open"
      >
        {{ label }}
        <span v-if="props.modelValue?.length" class="bg-tailwind p-1 text-white rounded-sm text-button-s">
          {{ props.modelValue.length }}
        </span>
        <SvgIcon :path="mdiChevronDown" :size="24" class="text-grey-800" />
      </button>
    </template>

    <ul class="options-list list-none p-0 m-0 overflow-y-auto">
      <li v-for="option in props.options" :key="`filter_${option.value}`" class="separate-rows p-2">
        <CheckBox
          :model-value="props.modelValue?.includes(option.value)"
          class="text-grey-800 whitespace-nowrap"
          @update:model-value="handleCheckboxChange(option.value, $event)"
        >
          {{ option.text }}
        </CheckBox>
      </li>
    </ul>
  </DropDownSelect>
</template>

<script setup lang="ts">
import { mdiChevronDown } from '@mdi/js'

const props = defineProps<{
  modelValue?: string[]
  options: {
    text: string
    value: string
  }[]
  label: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

const handleCheckboxChange = (optionValue: string, checked: boolean) => {
  if (checked) {
    const newOptions = props.modelValue?.slice() || []
    newOptions.push(optionValue)

    emit('update:modelValue', newOptions)
  } else {
    const newOptions = (props.modelValue?.slice() || []).filter((option) => option !== optionValue)
    emit('update:modelValue', newOptions)
  }
}
</script>

<style lang="scss" scoped>
.options-list {
  max-height: 640px;
}
</style>
