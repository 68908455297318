<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4000_10703)">
      <rect width="56" height="56" rx="28" fill="#D0D5DD" />
      <rect x="13" y="8" width="56" height="12" rx="1" fill="#F9FAFB" />
      <rect x="35" y="11" width="12" height="2" rx="1" fill="#D0D5DD" />
      <rect x="35" y="15" width="24" height="2" rx="1" fill="#D0D5DD" />
      <path
        d="M30.0975 17C28.8804 17 28.0377 16.1183 27.749 15.0572H27V14.2458H27.6086V13.7464H27V12.9272H27.749C28.0845 11.8114 28.9272 11 30.1053 11C31.1508 11 31.9155 11.593 32.251 12.6229L31.2835 13.0286C31.0884 12.4356 30.6905 12.0221 30.0975 12.0221C29.5358 12.0221 29.1066 12.4122 28.8804 12.9272H30.4096V13.7464H28.6697V14.2458H30.4096V15.0572H28.8648C29.0988 15.6034 29.5046 15.9779 30.0975 15.9779C30.6827 15.9779 31.0728 15.6112 31.2835 14.9714L32.251 15.3771C31.9545 16.329 31.1665 17 30.0975 17Z"
        fill="#D0D5DD"
      />
      <circle cx="20" cy="14" r="4" fill="#98A2B3" />
      <path d="M21.5 16L20 14.5L18.5 16L18 15.5L19.5 14L18 12.5L18.5 12L20 13.5L21.5 12L22 12.5L20.5 14L22 15.5L21.5 16Z" fill="#FDFDFD" />
      <rect x="5" y="22" width="56" height="12" rx="1" fill="#F9FAFB" />
      <rect x="27" y="25" width="12" height="2" rx="1" fill="#D0D5DD" />
      <rect x="27" y="29" width="24" height="2" rx="1" fill="#D0D5DD" />
      <path
        d="M22.0975 31C20.8804 31 20.0377 30.1183 19.749 29.0572H19V28.2458H19.6086V27.7464H19V26.9272H19.749C20.0845 25.8114 20.9272 25 22.1053 25C23.1508 25 23.9155 25.593 24.251 26.6229L23.2835 27.0286C23.0884 26.4356 22.6905 26.0221 22.0975 26.0221C21.5358 26.0221 21.1066 26.4122 20.8804 26.9272H22.4096V27.7464H20.6697V28.2458H22.4096V29.0572H20.8648C21.0988 29.6034 21.5046 29.9779 22.0975 29.9779C22.6827 29.9779 23.0728 29.6112 23.2835 28.9714L24.251 29.3771C23.9545 30.329 23.1665 31 22.0975 31Z"
        fill="#D0D5DD"
      />
      <circle cx="12" cy="28" r="4" fill="#98A2B3" />
      <path d="M13.5 30L12 28.5L10.5 30L10 29.5L11.5 28L10 26.5L10.5 26L12 27.5L13.5 26L14 26.5L12.5 28L14 29.5L13.5 30Z" fill="#FDFDFD" />
      <rect x="13" y="36" width="56" height="12" rx="1" fill="#F9FAFB" />
      <rect x="35" y="39" width="12" height="2" rx="1" fill="#D0D5DD" />
      <rect x="35" y="43" width="24" height="2" rx="1" fill="#D0D5DD" />
      <path
        d="M30.0975 45C28.8804 45 28.0377 44.1183 27.749 43.0572H27V42.2458H27.6086V41.7464H27V40.9272H27.749C28.0845 39.8114 28.9272 39 30.1053 39C31.1508 39 31.9155 39.593 32.251 40.6229L31.2835 41.0286C31.0884 40.4356 30.6905 40.0221 30.0975 40.0221C29.5358 40.0221 29.1066 40.4122 28.8804 40.9272H30.4096V41.7464H28.6697V42.2458H30.4096V43.0572H28.8648C29.0988 43.6034 29.5046 43.9779 30.0975 43.9779C30.6827 43.9779 31.0728 43.6112 31.2835 42.9714L32.251 43.3771C31.9545 44.329 31.1665 45 30.0975 45Z"
        fill="#D0D5DD"
      />
      <circle cx="20" cy="42" r="4" fill="#98A2B3" />
      <path d="M21.5 44L20 42.5L18.5 44L18 43.5L19.5 42L18 40.5L18.5 40L20 41.5L21.5 40L22 40.5L20.5 42L22 43.5L21.5 44Z" fill="#FDFDFD" />
    </g>
    <defs>
      <clipPath id="clip0_4000_10703">
        <rect width="56" height="56" rx="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
