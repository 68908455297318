<template>
  <div>
    <AlertBox v-if="!canViewSpeakers" type="warning" class="mt-5">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
    <RouterView v-if="canViewSpeakers && speaker" :speaker="speaker" :speaker-id="speakerId" @refetch="refetch" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { toGlobalId } from '@/helpers/globalIdHelper'
import { $can } from '@/services/roles'
import { useSpeakerQuery } from '@/gql/myomr'
import { useRoleRedirect } from '@/services/roleRedirect'

useRoleRedirect('viewSpeakers')

const { t } = useI18n()

const props = defineProps<{
  loading: boolean
  speakerId: string
}>()

const canViewSpeakers = computed(() => {
  return $can('viewSpeakers', { for: 'User' })
})
const speakerGlobalId = computed(() => toGlobalId('Speaker', props.speakerId))

const { result, refetch } = useSpeakerQuery({ speakerId: speakerGlobalId.value })

const speaker = computed(() => result?.value?.node)
</script>
