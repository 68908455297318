<template>
  <div>
    <ProgressBar v-if="props.loading" />

    <EventItem
      v-for="event in events"
      :key="event.id"
      :event="event"
      :show-chip="true"
      :show-main-button="true"
      :show-view-button="false"
      :show-dropdown="true"
      class="mb-4"
    />

    <button v-if="props.events.pageInfo.hasNextPage" :disabled="props.loading" class="btn-primary-purple-m" @click="emit('fetchMore')">
      {{ t('fetchMore') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { EventsQuery, EventFragment } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
  events: EventsQuery['events']
  loading: boolean
}>()

const events = computed(() => props.events.nodes?.filter((event): event is EventFragment => event != null) || [])

const emit = defineEmits<{
  (e: 'fetchMore'): void
}>()
</script>
