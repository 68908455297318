<template>
  <TableLight>
    <thead>
      <tr>
        <th class="py-4 text-center">{{ t('InvitationQuota.table.manuallyMarkedAsSent') }}</th>
        <th class="py-4 text-left">{{ t('InvitationQuota.table.code') }}</th>
        <th class="py-4 text-left">{{ t('InvitationQuota.table.userName') }}</th>
        <th class="py-4 text-left">{{ t('InvitationQuota.table.companyName') }}</th>
        <th class="py-4 text-center">{{ t('InvitationQuota.table.discount') }}</th>
        <th class="py-4 text-center">{{ t('InvitationQuota.table.usable') }}</th>
      </tr>
    </thead>
    <tbody>
      <PromoCodeTableRow
        v-for="promoCode in props.invitationQuota.promoCodes"
        :key="promoCode.id"
        :promo-code="promoCode"
        :discount="props.invitationQuota.discount"
      />
    </tbody>
  </TableLight>
</template>

<script setup lang="ts">
import type { InvitationQuota } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import PromoCodeTableRow from './PromoCodeTableRow.vue'

const props = defineProps<{
  invitationQuota: InvitationQuota
}>()
</script>
