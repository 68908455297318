<template>
  <div class="my-6 flex flex-row justify-between items-center min-h-10">
    <h2 class="text-headline-m">{{ t('Reviews.product.view.globalFeaturesTitle', { productName: product.title }) }}</h2>
    <RouterLink
      class="btn-primary-purple-m btn-disabled text-white"
      :to="{ name: RouteName.ReviewsProductEditGlobalFeatures, params: { productId: product.id } }"
    >
      {{ t('Reviews.product.action.edit') }}
    </RouterLink>
  </div>
</template>
<script setup lang="ts">
import type { ProductById } from '@/gql/reviews'
import { useI18n } from 'vue-i18n'
import { RouteName } from '@/router/types'

defineProps<{
  product: Pick<ProductById, 'id' | 'title'>
}>()

const { t } = useI18n()
</script>
