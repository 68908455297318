<template>
  <div :style="{ background: '#EFF1F3' }">
    <template v-if="me">
      <SideBar :me="me" />

      <div class="block flex-auto flex-grow flex-shrink laptop:pl-64">
        <div class="mt-24 laptop:mt-8 mb-16 mx-auto px-10 max-w-6xl">
          <UiNotifications />

          <AccountSwitchModal v-if="me && showAccountSwitcher" :me="me" />

          <RouterView :me="me" />
        </div>
      </div>
    </template>
    <template v-else>
      <RouterView />
    </template>
  </div>
</template>

<script setup lang="ts">
import { UiNotifications, SideBar } from '@/components'
import { storeToRefs } from 'pinia'
import { useUiStore } from '@/stores'
import { useMeStore } from '@/stores'
import { useMeQuery } from '@/gql/myomr'
import initializePendo from '@/helpers/pendo'

const uiStore = useUiStore()
const { showAccountSwitcher } = storeToRefs(uiStore)

const { me } = storeToRefs(useMeStore())
const { setupCurrentUser } = useMeStore()
const { onResult } = useMeQuery()

onResult((queryResult) => {
  if (queryResult.loading) return

  if (queryResult?.data) {
    const data = queryResult.data
    setupCurrentUser(data)
    initializePendo(data?.me)
  }
})
</script>

<style lang="scss">
@import 'src/styles/variables';
@import 'src/styles/tailwind';

html,
body {
  overflow-x: auto !important;
  min-width: 960px !important;
}
</style>
