import type { ProspectsQuery } from '@/gql/myomr'

function multiSelectOptions(facets: { id: string; name: string }[] | null | undefined) {
  if (!facets) return []

  return facets.map((filter) => {
    return {
      text: filter.name,
      value: filter.id,
    }
  })
}

export type ProspectFacetItems = {
  companyDepartmentItems: { text: string; value: string }[]
  companyFieldItems: { text: string; value: string }[]
  companyIndustryItems: { text: string; value: string }[]
  companyJobLevelItems: { text: string; value: string }[]
  companySizeItems: { text: string; value: string }[]
  companyCountryItems: { text: string; value: string }[]
  visitedEventsItems: { text: string; value: string }[]
}

export default function prospectFacetItems(prospects: ProspectsQuery['prospects']): ProspectFacetItems {
  return {
    companyDepartmentItems: multiSelectOptions(prospects?.companyDepartmentFacets),
    companyFieldItems: multiSelectOptions(prospects?.companyFieldFacets),
    companyIndustryItems: multiSelectOptions(prospects?.companyIndustryFacets),
    companyJobLevelItems: multiSelectOptions(prospects?.companyJobLevelFacets),
    companySizeItems: multiSelectOptions(prospects?.companySizeFacets),
    companyCountryItems: multiSelectOptions(prospects?.companyCountryFacets),
    visitedEventsItems: multiSelectOptions(prospects?.visitedEventsFilter),
  }
}
