<template>
  <tr class="cursor-pointer h-8 hover:text-tailwind" @click.stop="showDetails">
    <td
      :class="{ 'opacity-50': (!props.prospect.available && !props.prospectListId) || disabled }"
      class="higlight"
      v-html="companyName"
    ></td>
    <td :class="{ 'opacity-50': (!props.prospect.available && !props.prospectListId) || disabled }" class="higlight" v-html="position"></td>
    <td>
      <div :class="{ 'opacity-50': (!props.prospect.available && !props.prospectListId) || disabled }" class="flex gap-1">
        <div v-for="event in prospect.visitedEvents" :key="event.slug" class="chip bg-grey-300 text-black">
          {{ event.name }}
        </div>
      </div>
    </td>
    <td class="text-black">
      <slot></slot>
    </td>
    <ProspectRowPopup
      v-if="detailsDialog"
      :facet-items="props.facetItems"
      :prospect-list-id="props.prospectListId"
      :prospect-lists="props.prospectLists"
      :prospect="props.prospect"
      :disable-add="!!disabled"
      @create-prospect-item="emit('createProspectItem', $event)"
      @delete-prospect-item="emit('deleteProspectItem', $event)"
      @hide-details="hideDetails"
    />
  </tr>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import ProspectRowPopup from './ProspectRowPopup.vue'
import type { ProspectFacetItems } from '@/components/Prospects/prospectFacetItems'
import type { ProspectItem, ProspectList } from './types'

const props = defineProps<{
  facetItems: ProspectFacetItems
  prospect: ProspectItem
  prospectListId?: string
  prospectLists: ProspectList[]
  disabled?: boolean
}>()

const emit = defineEmits<{
  (e: 'createProspectItem', value: { prospectId: string; prospectListId: string }): void
  (e: 'deleteProspectItem', value: { prospectId: string; prospectListId: string }): void
}>()

const detailsDialog = ref(false)
function showDetails() {
  detailsDialog.value = true
}
function hideDetails() {
  detailsDialog.value = false
}

const companyName = computed(() => {
  if (props.prospect.highlight?.companyName) {
    return props.prospect.highlight.companyName
  }

  let names = [...new Set(props.prospect.companies.map((company) => company?.name).filter((name) => name))]

  return names
    .slice(0, 3)
    .map((name, index) => {
      return index == 2 ? 'more ...' : name
    })
    .join(', ')
})

const position = computed(() => {
  if (props.prospect.highlight?.companyPosition) {
    return props.prospect.highlight.companyPosition
  }

  return props.prospect.companies.map((company) => company?.position).filter((position) => position)[0]
})
</script>

<style lang="scss">
.higlight {
  em {
    font-weight: bold;
  }
}
</style>
