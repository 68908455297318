<template>
  <div class="my-6 flex flex-row justify-between items-center min-h-10">
    <h2 class="text-headline-m flex items-center">
      <span class="pr-2">
        {{ t('Reviews.product.view.reviewDetailTitle', { authorName: authorName || t('Reviews.product.review.history.unknownAuthor') }) }}
      </span>
      <ReviewStatusBadge v-if="state" class="flex" :state="state" :rejected-reason="rejectedReason" />
      <ReviewRemainderStatus v-if="remindersCount && remindersCount > 0" class="ml-2" :reminders-count="remindersCount" />
    </h2>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ReviewStatusBadge from './ReviewStatusBadge.vue'
import ReviewRemainderStatus from '@/components/ReviewsManagement/Reviews/ReviewRemainderStatus.vue'

defineProps<{
  state?: string
  authorName?: string
  rejectedReason?: string
  remindersCount?: number
}>()

const { t } = useI18n()
</script>
