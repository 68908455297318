import { QuotaPolicy, QuotaAbilities } from '@/policies/QuotaPolicy'
import { EventPolicy, EventAbilities } from '@/policies/EventPolicy'
import { UserPolicy, UserAbilities } from '@/policies/UserPolicy'
import type { ThingInterface } from '@/types'
import type { PartnerCompany, Event, Booth, InvitationQuota, User } from '@/gql/myomr'
import { PartnerCompanyAbilities, PartnerCompanyPolicy } from '@/policies/PartnerCompanyPolicy'
import { BoothAbilities, BoothPolicy } from '@/policies/BoothPolicy'
import type { ReviewsAbilities } from '@/policies/ReviewsPolicy'
import { ReviewsPolicy } from '@/policies/ReviewsPolicy'

export const $can = (ability: string, thing: ThingInterface) => {
  if ((thing.for as InvitationQuota) && (ability as QuotaAbilities)) {
    const method = ability as QuotaAbilities

    if (QuotaPolicy[method]) {
      return QuotaPolicy[method]()
    }
  }

  if ((thing.for as PartnerCompany) && (ability as PartnerCompanyAbilities)) {
    const method = ability as PartnerCompanyAbilities

    if (PartnerCompanyPolicy[method]) {
      return PartnerCompanyPolicy[method]()
    }
  }

  if ((thing.for as Event) && (ability as EventAbilities)) {
    const method = ability as EventAbilities

    if (EventPolicy[method]) {
      return EventPolicy[method]()
    }
  }

  if (((thing.for as Booth) || thing.for == 'Booth') && (ability as BoothAbilities)) {
    const method = ability as BoothAbilities

    if (BoothPolicy[method]) {
      return BoothPolicy[method]()
    }
  }

  if (((thing.for as User) || thing.for === 'User') && (ability as UserAbilities)) {
    const method = ability as UserAbilities

    if (UserPolicy[method]) {
      return UserPolicy[method]()
    }
  }

  if (((thing.for as User) || thing.for === 'User') && (ability as ReviewsAbilities)) {
    const method = ability as ReviewsAbilities

    if (ReviewsPolicy[method]) {
      return ReviewsPolicy[method]()
    }
  }

  throw `Please implement ${ability} for ${thing.for}`
}

export const $cannot = (ability: string, thing: ThingInterface) => {
  return !$can(ability, thing)
}
