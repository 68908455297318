<template>
  <label class="inline-flex items-center gap-2" @click="handleCheckboxChange">
    <div class="checkbox" :class="[props.size, { checked: checked, partial: useMidState }]" />
    <span v-if="hasLabel" :disabled="disabled" class="label"><slot></slot></span>
  </label>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots, watch } from 'vue'
export type CheckboxSizeEnum = 'small' | 'medium' | 'large'

export interface Props {
  checked?: boolean
  disabled?: boolean
  size?: CheckboxSizeEnum
  useMidState?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  checked: false,
  disabled: false,
  size: 'medium',
  useMidState: false,
})

const checkedValue = ref(props.checked)

watch(
  () => props.checked,
  (newVal) => {
    checkedValue.value = newVal
  },
)

const slots = useSlots()

const hasLabel = computed(() => {
  return !!slots['default']
})

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

function handleCheckboxChange() {
  if (props.useMidState) return emit('update:modelValue', true)
  emit('update:modelValue', !props.checked)
}
</script>
<style lang="scss" scoped>
.checkbox {
  // Prevent checkbox from shrinking
  @apply shrink-0;
  // Cursor
  @apply cursor-pointer;

  // Border
  @apply border-inherit border-2 rounded-sm relative;

  &.checked {
    // Checkbox mark
    @apply before:block before:absolute before:top-0
      before:border-l-2 before:border-b-2 before:border-inherit before:rotate-[314deg] before:z-10;
    // Corner
    @apply after:block after:w-[6px] after:h-[12px] after:absolute after:bg-white after:right-[-2px] after:top-[-5px];
  }

  &.partial {
    @apply before:absolute before:bg-grey-800 before:bottom-[5.5px];

    &.small {
      // Border
      @apply w-[14px] h-[14px];
      // Checkbox mark
      @apply before:w-[8px] before:top-[4px]  before:h-[2px] before:left-[1.2px];
    }

    &.medium {
      // Border
      @apply w-[18px] h-[18px];
      // Checkbox mark
      @apply before:w-[11px] before:h-[2.5px] before:left-[2px];
    }

    &.large {
      // Border
      @apply w-[20px] h-[20px];
      // Checkbox mark
      @apply before:w-[13px] before:h-[2.5px] before:left-[2px] before:top-[7px];
    }
  }

  &.small {
    // Border
    @apply w-[14px] h-[14px];
    // Checkbox mark
    @apply before:w-[10px] before:h-[6px] before:left-[2px];
  }

  &.medium {
    // Border
    @apply w-[18px] h-[18px];
    // Checkbox mark
    @apply before:w-[12px] before:h-[7.5px] before:left-[2.5px];
  }

  &.large {
    // Border
    @apply w-[20px] h-[20px];
    // Checkbox mark
    @apply before:w-[14px] before:h-[8px] before:left-[3px];
  }
}

.label {
  // Disabled state
  @apply cursor-pointer peer-disabled:opacity-50 peer-disabled:cursor-default peer-disabled:pointer-events-none text-grey-800 peer-checked:text-black;
}
</style>
