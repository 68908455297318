import type { Translation } from '@/gql/reviews'

export const ALLOWED_LOCALES = ['de', 'en']

/**
 * Mapping the translation table to an object with properties named after locales.
 * This way, we can access translations like this: product.descriptionTranslationsMap.de
 *
 * @param translations - The array of translation objects or undefined.
 * @returns An object with locale codes as keys and translation strings as values.
 */
export const mapTranslationsArrToObj = (translations: Translation[] | undefined) => {
  return ALLOWED_LOCALES.reduce(
    (acc, locale) => {
      if (translations) {
        const translation = translations.find((t) => t.locale === locale)
        acc[locale] = translation?.value ?? ''
      }
      return acc
    },
    {} as Record<string, string>,
  )
}

export const mapPerksTranslations = (translations: Translation[] | undefined) => {
  const translationsObj = ALLOWED_LOCALES.reduce(
    (acc, locale) => {
      if (translations) {
        const translation = translations.find((t) => t.locale === locale)
        acc[locale] = translation?.value?.split('|') ?? []
      }
      return acc
    },
    {} as Record<string, string[]>,
  )

  const length = translationsObj[ALLOWED_LOCALES[0]].length
  const perks: Record<string, { name: string }>[] = Array.from({ length }, (_, index) => {
    const perk: Record<string, { name: string }> = {}
    ALLOWED_LOCALES.forEach((locale) => {
      perk[locale] = { name: translationsObj[locale][index] }
    })
    return perk
  })

  return perks
}

export const mapPerksFormDataToPerksTranslations = (perks: Record<string, { name: string }>[]) => {
  const fallbackLocale = ALLOWED_LOCALES[0]
  const translations: { locale: string; value: string }[] = ALLOWED_LOCALES.map((locale) => {
    const values = perks.map((perk) => perk[locale].name || perk[fallbackLocale].name)
    return { locale, value: values.join('|') }
  })

  return translations
}
