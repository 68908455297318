<template>
  <CheckBox
    :model-value="props.modelValue.includes(props.value)"
    :disabled="props.disabled"
    :size="props.size"
    @update:model-value="handleCheckboxChange"
  >
    <slot></slot>
  </CheckBox>
</template>

<script lang="ts" setup>
import type { CheckboxSizeEnum } from './CheckBox.vue'

const props = defineProps<{
  modelValue: string[]
  value: string
  disabled?: boolean
  size?: CheckboxSizeEnum
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

function handleCheckboxChange(newValue: boolean) {
  if (newValue) {
    const newCheckedCollection = [...props.modelValue, props.value]
    emit('update:modelValue', newCheckedCollection)
  } else {
    const newCheckedCollection = props.modelValue.filter((item) => item !== props.value)
    emit('update:modelValue', newCheckedCollection)
  }
}
</script>
