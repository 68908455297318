<template>
  <TableLight class="mt-6 w-full">
    <thead>
      <tr>
        <th class="text-start">
          <div class="flex items-center">
            <CheckBox
              :disabled="!props.invitees?.length || !props.canSendInvitees"
              :model-value="allSelected"
              class="text-grey-800"
              data-test-id="select-all-checkbox"
              @update:model-value="toggleAllSelected"
            />
          </div>
        </th>
        <th class="text-start">{{ t('email') }}</th>
        <th class="text-start">{{ t('title') }}</th>
        <th class="text-start">{{ t('firstName') }}</th>
        <th class="text-start">{{ t('lastName') }}</th>
        <th class="text-start">{{ t('company') }}</th>
        <th class="text-start">{{ t('gender') }}</th>
        <th class="text-start">
          <span class="flex align-middle"
            >{{ t('status') }}<SvgIcon class="ml-1 cursor-pointer" :path="mdiRefresh" :size="20" @click="emit('refetchData', true)"
          /></span>
        </th>
        <th class="text-start">{{ t('createdAt') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!props.invitees?.length">
        <td colspan="9" class="text-center text-grey-700 py-6">{{ t('EmailInvitationQuota.noDataText') }}</td>
      </tr>
      <template v-for="invitee in props.invitees" :key="`invitee_${invitee?.token}`">
        <tr v-if="invitee">
          <td class="pr-2">
            <div class="flex items-center">
              <CheckBox
                :disabled="isPurchased(invitee) || !props.canSendInvitees"
                :model-value="!!props.modelValue?.includes(invitee.email)"
                class="text-grey-800"
                data-test-id="toggle-invitee-checkbox"
                @update:model-value="() => toggleInviteeChecked(invitee.email)"
              />
            </div>
          </td>
          <td class="py-3 pr-2">{{ invitee.email }}</td>
          <td class="py-3 pr-2">{{ invitee.title }}</td>
          <td class="py-3 pr-2">{{ invitee.firstName }}</td>
          <td class="py-3 pr-2">{{ invitee.lastName }}</td>
          <td class="py-3 pr-2">{{ invitee.company }}</td>
          <td class="py-3 pr-2">{{ invitee.gender }}</td>
          <td class="py-3">
            <div
              v-tooltip="t(`EmailInvitationQuota.hintModal.${getInviteeStatus(invitee)}.description`)"
              :class="getStatusColor(getInviteeStatus(invitee))"
            >
              {{ t(`InvitationQuota.${getInviteeStatus(invitee)}`) }}
            </div>
          </td>
          <td class="py-3 pl-2">{{ formatDate(invitee.createdAt) }}</td>
        </tr>
      </template>
    </tbody>
  </TableLight>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { type InviteeConnection, type Invitee, InviteeStatusEnum } from '@/gql/myomr'
import { useI18n } from 'vue-i18n'
import { mdiRefresh } from '@mdi/js'
import TableLight from '@/ui/TableLight.vue'
import { ActivatedStatusEnum } from './GuestQuotaDataProvider.vue'

const { d, t } = useI18n()

const props = defineProps<{
  invitees?: InviteeConnection['nodes']
  modelValue: string[]
  canSendInvitees: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
  (e: 'openHintModal'): void
  (e: 'refetchData', value: boolean): void
}>()

const selectableInvitees = computed(() => {
  if (!props.invitees?.length) return []
  return props.invitees.filter((invitee) => {
    return invitee?.status !== 'purchased'
  })
})

function getInviteeStatus(invitee: Invitee) {
  return invitee.activated ? ActivatedStatusEnum.Activated : invitee.status
}

const allSelected = computed(() => !!(props.modelValue?.length && props.modelValue.length === selectableInvitees.value.length))

function toggleInviteeChecked(email: string) {
  let newSelection: string[]
  if (props.modelValue?.includes(email)) {
    newSelection = props.modelValue.filter((e) => e !== email)
  } else {
    newSelection = props.modelValue.concat(email)
  }

  emit('update:modelValue', newSelection)
}

function isPurchased(invitee: Invitee) {
  return invitee.status === 'purchased'
}

function toggleAllSelected() {
  if (!props.invitees?.length) return

  const newSelection =
    props.modelValue.length === selectableInvitees.value.length
      ? []
      : selectableInvitees.value.map((invitee) => {
          return (invitee as Invitee).email
        })
  emit('update:modelValue', newSelection)
}

function formatDate(date: string): string {
  return d(date, 'dateShort')
}

function getStatusColor(status: string) {
  switch (status) {
    case InviteeStatusEnum.Draft:
      return 'chip-grey'
    case InviteeStatusEnum.Sending:
      return 'chip-grey-outlined'
    case InviteeStatusEnum.Sent:
      return 'chip-victoria-outlined'
    case InviteeStatusEnum.Opened:
      return 'chip-ripe-lemon-outlined'
    case InviteeStatusEnum.Purchased:
      return 'chip-tailwind'
    case ActivatedStatusEnum.Activated:
      return 'chip-tailwind'
    case InviteeStatusEnum.Declined:
      return 'chip-amaranth-outlined'
    case InviteeStatusEnum.Bounced:
      return 'chip-amaranth'
    default:
      return 'chip-grey'
  }
}
</script>
