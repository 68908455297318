import { useMeStore } from '@/stores'
import type { Role } from '@/gql/myomr'

export abstract class ApplicationPolicy {
  public static roles() {
    const meStore = useMeStore()
    return meStore.currentRoles as Role[]
  }

  public static currentPartnerCompanyId() {
    const meStore = useMeStore()
    return meStore.currentPartnerCompanyId
  }

  public static isUser() {
    return !!this.currentPartnerCompanyId() && this.roles() && this.roles().length > 0
  }

  // helper methods
  public static hasDashboardAccess() {
    const dashboardAccessRole = this.roles().find((role) => {
      return (
        role.name === 'manage__dashboard' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!dashboardAccessRole
  }

  public static hasUserManagementAccess() {
    const userManagementRole = this.roles().find((role) => {
      return (
        role.name === 'manage__user_management' &&
        role.resourceType === 'PartnerCompany' &&
        role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!userManagementRole
  }

  public static hasReviewsManagementAccess() {
    const userManagementRole = this.roles().find((role) => {
      return (
        role.name === 'manage__reviews_management' &&
        role.resourceType === 'PartnerCompany' &&
        role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!userManagementRole
  }

  public static hasSpeakersAccess() {
    const speakersRole = this.roles().find((role) => {
      return (
        role.name === 'manage__speakers' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!speakersRole
  }

  public static hasEventsInviteesAccess() {
    const eventsInviteesRole = this.roles().find((role) => {
      return (
        role.name === 'manage__events_invitees' &&
        role.resourceType === 'PartnerCompany' &&
        role.resourceId === this.currentPartnerCompanyId()
      )
    })

    const quotaUserRole = this.roles().find((role) => {
      return role.name === 'quota_user'
    })

    return !!eventsInviteesRole || !!quotaUserRole
  }

  public static hasEventsAccess() {
    const eventsRole = this.roles().find((role) => {
      return role.name === 'manage__events' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
    })

    return !!eventsRole
  }

  public static hasTicketsAccess() {
    const ticketsRole = this.roles().find((role) => {
      return role.name === 'manage__tickets' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
    })

    const quotaUserRole = this.roles().find((role) => role.name === 'quota_user')

    return ticketsRole || quotaUserRole
  }

  public static hasCompanyProfileAccess() {
    const companyProfileRole = this.roles().find((role) => {
      return (
        role.name === 'manage__company_profile' &&
        role.resourceType === 'PartnerCompany' &&
        role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!companyProfileRole
  }

  public static hasBoothsAccess() {
    const boothsRole = this.roles().find((role) => {
      return role.name === 'manage__booths' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
    })

    return !!boothsRole
  }

  public static hasMyLeadsAccess() {
    const myLeadsRole = this.roles().find((role) => {
      return (
        role.name === 'manage__my_leads' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!myLeadsRole
  }

  public static hasLeadGenerationRole() {
    const leadGenerationRole = this.roles().find((role) => {
      return (
        role.name === 'manage__lead_generation' &&
        role.resourceType === 'PartnerCompany' &&
        role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!leadGenerationRole
  }

  public static hasLeadScannerRole() {
    const hasLeadScannerRole = this.roles().find((role) => {
      return (
        role.name === 'manage__lead_scanner' && role.resourceType === 'PartnerCompany' && role.resourceId === this.currentPartnerCompanyId()
      )
    })

    return !!hasLeadScannerRole
  }
}
