<template>
  <div>
    <template v-if="hasCompany">
      <ProspectListsDataProvider
        v-slot="{ prospectListsResult, prospectListsError, prospectListsLoading, prospectListsRefetch }"
        :partner-company-id="partnerCompanyId"
        :period="period"
      >
        <RouterView
          v-if="prospectListsResult"
          :prospect-lists-result="prospectListsResult"
          :prospect-lists-loading="prospectListsLoading"
          :prospect-lists-error="prospectListsError"
          :prospect-lists-refetch="prospectListsRefetch"
          :period="period"
          :me="me"
        />
      </ProspectListsDataProvider>
    </template>
    <AlertBox v-else type="warning" class="mt-6">
      {{ t('PartnerCompany.noPartnerCompany') }}
    </AlertBox>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoleRedirect } from '@/services/roleRedirect'
import { useI18n } from 'vue-i18n'
import { type MeQuery, EventPeriodEnum } from '@/gql/myomr'
import ProspectListsDataProvider from '@/components/Prospects/ProspectListsDataProvider.vue'

useRoleRedirect('viewProspectCatalog')

const { t } = useI18n()

const props = defineProps<{
  me: NonNullable<MeQuery['me']>
  selector?: string
}>()

const hasCompany = computed(() => !!props.me?.partnerCompany)
const partnerCompanyId = computed<string>(() => props.me.partnerCompany?.id as string)
const period = computed(() => (props.selector === 'archive' ? EventPeriodEnum.Past : EventPeriodEnum.Upcoming))
</script>
