<template>
  <ConfirmModal
    persist
    wrapper-class="w-full max-w-xl"
    :approve="t('ProspectsCatalog.sendProspectListInvitations.approveButton')"
    :cancel="t('ProspectsCatalog.sendProspectListInvitations.cancelButton')"
    @close-dialog="submitForm"
  >
    <template #title>
      <h1 class="text-headline-m p-6 pb-5">{{ t('ProspectsCatalog.sendProspectListInvitations.title') }}</h1>
    </template>
    <template #content>
      <div class="px-6">
        <p>
          {{ t('ProspectsCatalog.sendProspectListInvitations.description') }}
        </p>
      </div>
    </template>
  </ConfirmModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { useSendProspectListInvitationsMutation } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import type { ProspectList } from './types'

const { mutate } = useSendProspectListInvitationsMutation()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const props = defineProps<{
  activeList: ProspectList
  onRefetchProspectLists: () => void
}>()

async function closeModal() {
  router.push({ name: 'prospectList', params: { listId: route.params.listId } })
}

async function submitForm(shouldSubmit: boolean) {
  if (!shouldSubmit) return closeModal()

  try {
    const result = await mutate({ prospectListId: props.activeList?.id })

    if (result?.data?.sendProspectListInvitations?.success) {
      showNotification(t('ProspectsCatalog.sendProspectListInvitations.successNotification'), 'success', 3000)
    } else {
      showNotification(t('ProspectsCatalog.sendProspectListInvitations.errorNotification'), 'error', 3000)
    }
  } catch (_e) {
    showNotification(t('ProspectsCatalog.sendProspectListInvitations.errorNotification'), 'error', 3000)
  } finally {
    props.onRefetchProspectLists()
    closeModal()
  }
}
</script>
