<template>
  <RouterLink
    class="block px-5 py-3 text-body-m-bold text-white visited:text-white hover:text-white hover:bg-purple-600"
    :to="props.to"
    :class="{ 'router-link-active': routeMatches }"
  >
    <slot />
  </RouterLink>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { useRoute } from 'vue-router'
const route = useRoute()

const routeMatches = computed(() => {
  if (!props.activeRoutes || !route.name) return false
  return props.activeRoutes.includes(route.name as string)
})

const props = defineProps<{
  to: RouteLocationRaw
  activeRoutes?: Array<string>
}>()
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-purple-600;
}
</style>
