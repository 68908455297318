export const toGlobalId = (type: string, id: string) => {
  return `gid://myomr/${type}/${id}`
}

export const toTypeAndId = (globalId: string) => {
  const matchArray = globalId.match(/gid:\/\/myomr\/(\w+)\/(\d+)/)

  if (matchArray && matchArray.length === 3) {
    return { type: matchArray[1], id: matchArray[2] }
  } else {
    return { type: undefined, id: undefined }
  }
}

export const toPlainId = (globalId: string) => toTypeAndId(globalId).id
