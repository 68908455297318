import { ApplicationPolicy } from '@/policies/ApplicationPolicy'

export abstract class BoothPolicy extends ApplicationPolicy {
  public static manage(): boolean {
    return this.hasBoothsAccess()
  }
}

export enum BoothAbilities {
  manage = 'manage',
}
