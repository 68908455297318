<template>
  <div v-omr-click-outside="hideDropDown" class="inline-flex flex-col">
    <slot name="activator" :open="handleActivatorClick"></slot>

    <div class="relative">
      <div
        class="absolute bg-white rounded overflow-hidden shadow z-40 bottom-0 left-[30px]"
        :class="{
          'hidden pointer-events-none z-0': !showDropDown,
        }"
      >
        <slot :close="hideDropDown"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default { name: 'DropDownSelect' }
</script>

<script setup lang="ts">
import ClickOutside from '@/directives/clickOutside'
import { ref } from 'vue'

const showDropDown = ref(false)

const vOmrClickOutside = ClickOutside

const handleActivatorClick = () => {
  showDropDown.value = !showDropDown.value
}

const hideDropDown = () => {
  showDropDown.value = false
}
</script>
