<template>
  <GuestQuota
    v-if="invitees?.nodes"
    :page="page"
    :invitation-quota="props.invitationQuota"
    :invitees="invitees"
    :per-page="perPage"
    :status="status"
    @refetch="refetchData"
    @update:page="updatePage($event)"
    @update:per-page="updatePerPage($event)"
    @update:status="updateStatus($event)"
  />
</template>

<script lang="ts">
export default { name: 'GuestQuotaDataProvider' }
enum EmptyStatusEnum {
  Empty = '',
}
export enum ActivatedStatusEnum {
  Activated = 'activated',
}
export type StatusEnum = InviteeStatusEnum | EmptyStatusEnum | ActivatedStatusEnum
</script>

<script setup lang="ts">
import { computed } from 'vue'

import { type InvitationQuota, InviteeStatusEnum, useInviteesQuery } from '@/gql/myomr'
import { showNotification } from '@/helpers/notificationHelper'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'

const props = defineProps<{
  invitationQuota: InvitationQuota
  loading: boolean
}>()

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const defaultPerPage = 10
const defaultPage = 1

const page = computed<number>(() => (route.query.page ? Number(route.query.page) : defaultPage))
const perPage = computed<number>(() => (route.query.perPage ? Number(route.query.perPage) : defaultPerPage))
const status = computed<StatusEnum>(() => (route.query.status ? (String(route.query.status) as StatusEnum) : EmptyStatusEnum.Empty))

const statusForQuery = computed(() => {
  if (status.value === ActivatedStatusEnum.Activated) return null
  return status.value ? status.value : null
})

const showActiveOnly = computed(() => (status.value === 'activated' ? true : null)) // null means no filter for this attribute

const offset = computed(() => (page.value - 1) * perPage.value)

const queryVars = computed(() => ({
  invitationQuotaId: props.invitationQuota.id,
  limit: perPage.value,
  offset: offset.value,
  status: statusForQuery.value,
  activated: showActiveOnly.value,
}))
const { result, refetch } = useInviteesQuery(queryVars, { fetchPolicy: 'no-cache' })
const invitees = computed(() => result.value?.invitees)

function updatePerPage(newPerPage: number) {
  router.push({
    query: {
      ...route.query,
      page: undefined,
      perPage: newPerPage != defaultPerPage ? String(newPerPage) : undefined,
    },
  })
}
function updatePage(newPage: number) {
  if (newPage == page.value) return
  router.push({
    query: {
      ...route.query,
      page: newPage != defaultPage ? String(newPage) : undefined,
    },
  })
}
function updateStatus(newStatus: StatusEnum) {
  router.push({
    query: {
      ...route.query,
      page: undefined,
      status: newStatus,
    },
  })
}

const refetchData = async (showSuccessModal: boolean) => {
  try {
    await refetch()
    if (showSuccessModal) showNotification(t('EmailInvitationQuota.refetchDataMessage'), 'success')
  } catch (e) {
    if (showSuccessModal) showNotification(t('EmailInvitationQuota.refetchDataErrorMessage'), 'success')
  }
}
</script>
