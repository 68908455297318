<template>
  <a v-if="$can('downloadInvitees', { for: invitationQuota })" :href="href" class="btn-seconday-mint-m">
    <SvgIcon :path="mdiDownload" :size="16" class="mr-2" />
    {{ t('InvitationQuota.downloadInviteesCSV') }}
  </a>
</template>

<script lang="ts">
export default { name: 'DownloadInvitees' }
</script>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import type { InvitationQuotaQuery } from '@/gql/myomr'
import { invitationQuotaInviteesDownloadUrl } from '@/services/ApiService'
import { toTypeAndId } from '@/helpers/globalIdHelper'
import { mdiDownload } from '@mdi/js'
import { $can } from '@/services/roles'

const props = defineProps<{
  invitationQuota: InvitationQuotaQuery['invitationQuota']
}>()

const { locale, t } = useI18n()

const invitationQuotaNumericID = computed(() => {
  return (props.invitationQuota && toTypeAndId(props.invitationQuota.id)['id']) || ''
})

const href = computed(() => invitationQuotaInviteesDownloadUrl(invitationQuotaNumericID.value, locale.value))
</script>
